import { useState, useCallback } from 'react';
import { getApiUrl } from '../config/api';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async (requestConfig, applyData, catchRequest) => {
      setIsLoading(true);
      setError(null);
      let body;
      if (requestConfig.body) {
        if (requestConfig.noJSON) body = requestConfig.body;
        else body = JSON.stringify(requestConfig.body);
      } else {
        body = null;
      }
      try {
        const response = await fetch(getApiUrl(requestConfig.url), {
          method: requestConfig.method ? requestConfig.method : 'GET',
          headers: requestConfig.headers ? requestConfig.headers : {},
          body,
        });

        let data;

        if (requestConfig.returnBlob) {
          data = await response.blob();
        } else {
          data = await response.json();
          if (!response.ok) {
            if (catchRequest) catchRequest(response);
            throw new Error(data.message);
          }
        }
        applyData(data);
      } catch (err) {
        setError(err.message || 'Etwas ist schiefgelaufen!');
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
