import { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';
import PageHeading from '../UI/PageHeading';

// IMPORT MUI COMPONENTS
import SwipeableViews from 'react-swipeable-views';
import { Tab } from '@mui/material/';

// IMPORT DATA
import { globals } from '../../data/global-vars';

// IMPORT HOOKS AND CONTEXTS
import AuthContext from '../../store/auth-context';
import { useBrandFind } from '../../hooks/useFinders';
import { useTheme } from '@emotion/react';
import PopupContext from '../../store/popup-context';
import { StyledTabs, TabPanel } from '../UI/TabController';
import useHttp from '../../hooks/use-http';
import { InternalNewsTable } from '../About/InternalNews';
import useFetchContent from '../../hooks/useFetchContent';
import ModalContext from '../../store/modal-context';
import { useThisUser } from '../../hooks/users';
import { useInternalNews } from '../../hooks/internalNews';

function Intern() {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { setPopupMessage } = useContext(PopupContext);
  const history = useHistory();
  const { sendRequest } = useHttp();
  const { getSecureFile } = useFetchContent();
  const { closeModal } = useContext(ModalContext);

  const [internalNews, setInternalNews] = useState([]);
  const { data: thisUser } = useThisUser();
  useInternalNews((data) => {
    setInternalNews(data);
  });

  const handleNewsSubmission = (data, existingNewsId) => {
    sendRequest(
      {
        url: existingNewsId
          ? '/api/v1/internalnews/' + existingNewsId
          : '/api/v1/internalnews',
        method: existingNewsId ? 'PUT' : 'POST',
        noJSON: true,
        headers: {
          Authorization: 'Bearer ' + authCtx.token,
        },
        body: data,
      },
      (data) => {
        if (existingNewsId)
          setInternalNews((prev) => {
            return prev.map((news) =>
              news._id === existingNewsId ? data : news
            );
          });
        else setInternalNews((prev) => [data, ...prev]);
        setPopupMessage(
          `Interne Nachricht erfolgreich ${
            existingNewsId ? 'bearbeitet' : 'erstellt'
          }!`,
          'success'
        );
        closeModal();
      }
    );
  };

  const theme = useTheme();
  const [value, setValue] = useState(0);

  function getCorrespondingUrl(index) {
    switch (index) {
      case 0:
        return 'mitteilungen';

      case 1:
        return 'handbuch';

      default:
        return 'mitteilungen';
    }
  }

  const tabKey = params[0].split('/');
  useEffect(() => {
    if (tabKey[0] === getCorrespondingUrl(1)) {
      if (value !== 1) {
        window.scrollTo(0, 0);
        setValue(1);
      }
    } else {
      if (value !== 0) window.scrollTo(0, 0);
      setValue(0);
    }
  }, [value, tabKey]);

  function handleTabChange(event, index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.INTERN.url}/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.INTERN.url}/` + url);
  }

  function openProtectedPDF() {
    const newWindow = window.open();
    getSecureFile('pdf/employee/broschuere.pdf', (d) => {
      newWindow.location = URL.createObjectURL(d);
    });
  }

  if (authCtx.isLoggedIn) {
    return (
      <div className="container">
        <div>
          <PageHeading title="Admin">Interner Bereich</PageHeading>
        </div>
        <StyledTabs
          value={value}
          indicatorColor={brandObject.primeColor}
          onChange={handleTabChange}
          sx={{ marginBottom: '20px' }}
        >
          <Tab label="Mitteilungen" />
          <Tab label="Handbuch" />
        </StyledTabs>
        <SwipeableViews
          axis="x"
          index={value}
          onChangeIndex={handleChangeTabIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            id="mitteilungen"
          >
            <h3 className="sectionHeading !mt-0 pt-0">Interne Nachrichten:</h3>
            <InternalNewsTable
              news={internalNews}
              setInternalNews={setInternalNews}
              handleAddNews={handleNewsSubmission}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <h3 className="sectionHeading !mt-0 pt-0">Dokumente:</h3>
            <div className="relative border-lightgray border-[1px] rounded-lg p-2 text-left">
              <Button maxWidth="300px" onClick={openProtectedPDF}>
                Mitarbeiterbroschüre
              </Button>
            </div>
          </TabPanel>
        </SwipeableViews>
        <div className="w-full px-4 flex flex-row justify-between items-end">
          <div className="flex flex-col text-left adminInfo pr-2 gap-0">
            {`Eingeloggt als ${thisUser.firstName} ${thisUser.lastName}`}
            <br />
            {`(${thisUser.email})`}
          </div>

          <Button onClick={() => authCtx.logout()} color="red" maxWidth="200px">
            Ausloggen
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <Redirect
        to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
      />
    );
  }
}

export default Intern;
