import { useMutation } from '@tanstack/react-query';
import { getApiUrl } from '../config/api';

export const useLogin = (onSuccess) => {
  return useMutation({
    mutationFn: async (credentials) => {
      const response = await fetch(getApiUrl('/api/v1/auth/login'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        const error = await response.text();
        console.log(error);
        throw new Error(error);
      }

      return response.json();
    },
    onSuccess,
  });
};

export const useRegister = (onSuccess) => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await fetch(getApiUrl('/api/v1/user/register'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }

      return response.json();
    },
    onSuccess,
  });
};

export const useResetPassword = (onSuccess) => {
  return useMutation({
    mutationFn: async (email) => {
      const response = await fetch(getApiUrl('/api/v1/auth/reset-password'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Fehler beim Zurücksetzen des Passworts');
      }

      return response.json();
    },
    onSuccess,
  });
};

export const useConfirmResetPassword = (onSuccess) => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await fetch(
        getApiUrl('/api/v1/auth/confirm-reset-password'),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }

      return response.json();
    },
    onSuccess,
  });
};
