import { useEffect, useState, useContext } from 'react';
import classes from './InternalNews.module.css';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';

// IMPORT HOOKS, CONTEXTS AND FUNCTIONS
import AuthContext from '../../store/auth-context';
import useFetchContent from '../../hooks/useFetchContent';
import ModalContext from '../../store/modal-context';
import dateformat from 'dateformat';

// IMPORT MUI AND FONTAWESOME COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { MultiSelect } from 'primereact/multiselect';
import useHttp from '../../hooks/use-http';
import PopupContext from '../../store/popup-context';

function InternalNews(props) {
  const n = props.data;

  let levelColor;
  switch (n.level) {
    case 'danger':
      levelColor = { borderLeft: '2px solid var(--danger-color)' };
      break;

    case 'warning':
      levelColor = { borderLeft: '2px solid var(--warning-color)' };
      break;

    case 'success':
      levelColor = { borderLeft: '2px solid var(--success-color)' };
      break;

    case 'info':
      levelColor = { borderLeft: '2px solid var(--info-color)' };
      break;

    default:
      levelColor = { borderLeft: '2px solid var(--info-color)' };
      break;
  }

  return (
    <Grid
      xs={12}
      className={classes.internalNewsContainer}
      style={{ padding: '10px', margin: '10px 0', ...levelColor }}
    >
      <div>
        <span className={classes.intNewsTitle}>{n.title}</span>
      </div>
      <div className="font-400 font-[12px] mt-4">{n.text}</div>
    </Grid>
  );
}

const ReadReceiptsTable = (props) => {
  const { sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    sendRequest(
      {
        url: '/api/v1/internalnews/' + props.newsId,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        setNews(data);
      }
    );
  }, []);

  useEffect(() => {
    sendRequest(
      {
        url: '/api/v1/user/full',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        setUsers(data);
      }
    );
  }, []);

  const handleMarkAsRead = (pUserId) => {
    sendRequest(
      {
        url: '/api/v1/internalnews/' + props.newsId + '/mark-as-read',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
        body: {
          userId: pUserId,
        },
      },
      (data) => {
        setNews(data);
        props.setNews((prev) => {
          return prev.map((newsItem) =>
            newsItem._id === data._id
              ? {
                  ...data,
                  assignedUsers: data.assignedUsers.map((as) => as._id),
                }
              : newsItem
          );
        });
      }
    );
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filterUnread, setFilterUnread] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const lowercasedSearch = searchTerm.toLowerCase();

    const filtered = users.filter((u) => {
      const usersToRead = news?.assignedUsers?.map((user) => user._id);
      const usersHaveRead =
        news?.readReceipts?.filter((r) => r.userId === u._id) || [];
      const hasToRead = usersToRead?.includes(u._id);
      const readAt = usersHaveRead?.length > 0 && usersHaveRead[0]?.readAt;

      return (
        ((filterUnread && !readAt && hasToRead) || !filterUnread) &&
        (u.firstName.toLowerCase().includes(lowercasedSearch) ||
          u.email.toLowerCase().includes(lowercasedSearch) ||
          u.lastName.toLowerCase().includes(lowercasedSearch))
      );
    });

    const sortedFiltered = [...filtered].sort((a, b) => {
      const aHasToRead = news?.assignedUsers?.some(
        (user) => user._id === a._id
      );
      const bHasToRead = news?.assignedUsers?.some(
        (user) => user._id === b._id
      );

      return bHasToRead - aHasToRead;
    });

    setFilteredUsers(sortedFiltered);
  }, [searchTerm, users, filterUnread, news]);

  return (
    <div className="mt-2 mb-4 disabled">
      <div className="grid grid-cols-12 relative overflow-hidden bg-white border-lightgray border-[1px] rounded-lg">
        <div className="col-span-12 p-2 grid grid-cols-12 gap-2 border-b border-gray-200">
          <div className="col-span-6">
            <form className="flex items-center">
              <label className="sr-only">Suchen</label>
              <div className="relative w-full ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      rillrole="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      cliprole="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2 outline-none"
                  placeholder="Suchen"
                  required=""
                />
              </div>
            </form>
          </div>
          <div className="col-span-6 flex justify-end gap-x-2">
            <button
              className={`col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 border rounded-lg focus:outline-none  hover:text-primary-700 ${
                filterUnread
                  ? 'text-primary border-primary font-bold'
                  : 'bg-white border-gray-200'
              }`}
              onClick={() => setFilterUnread((p) => !p)}
            >
              Ungelesen
            </button>
          </div>
        </div>
        <div className="col-span-12">
          {filteredUsers.map((u) => {
            const usersToRead = news?.assignedUsers?.map((user) => user._id);
            const thisUserHasRead =
              news?.readReceipts?.filter((r) => r.userId === u._id) || [];
            const hasToRead = usersToRead?.includes(u._id);
            const readAt =
              thisUserHasRead?.length > 0 && thisUserHasRead[0]?.readAt;
            return (
              <div key={u._id} className="border-b">
                <div
                  className={`grid grid-cols-12 items-center gap-4 px-4 py-3 ${
                    !hasToRead && 'bg-gray-100'
                  }`}
                >
                  <div
                    className={`col-span-6 flex flex-col items-start font-medium ${
                      hasToRead ? 'text-gray-900' : 'text-gray-400'
                    }`}
                  >
                    <span>{`${u.firstName} ${u.lastName}`}</span>
                    <span className="adminInfo">{`(${u.email})`}</span>
                  </div>
                  <div className="col-span-5 text-right">
                    {hasToRead && !readAt ? (
                      <span className="rounded-md bg-danger/10 py-0.5 px-2.5 border border-transparent text-sm text-danger">
                        ungelesen
                      </span>
                    ) : !!readAt ? (
                      <span className="rounded-md py-0.5 px-2.5 border border-transparent text-left text-sm text-green-700 bg-green-700/10">
                        {dateformat(new Date(readAt), 'd.m.yy') +
                          ' um ' +
                          dateformat(new Date(readAt), 'H:MM') +
                          ' Uhr'}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-span-1 flex justify-end cursor-pointer">
                    {hasToRead && !readAt && (
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        title="Als gelesen markieren"
                        onClick={() => handleMarkAsRead(u._id)}
                      >
                        <g>
                          <path
                            id="Vector"
                            d="M7 12L11.9497 16.9497L22.5572 6.34326M2.0498 12.0503L6.99955 17M17.606 6.39355L12.3027 11.6969"
                            stroke="#000000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-span-12 flex items-center justify-between bg-white px-4 py-3 sm:px-6">
          <div>
            <p className="text-sm text-gray-700">
              <span className="font-medium">{users.length + ' '}</span>
              Accounts
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const InternalNewsForm = ({ onSubmit, existingNews = null }) => {
  const authCtx = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: '',
    text: '',
    level: 'info',
    assignedUsers: [],
    assignedGroups: [],
    requiresReadConfirmation: false,
    files: [],
  });

  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [users, setUsers] = useState([]);

  const { error: fetchError, getAllUsers, getAllGroups } = useFetchContent();

  // Lade Benutzer für MultiSelect
  useEffect(() => {
    getAllUsers((data) => {
      if (!fetchError && Array.isArray(data)) {
        const formattedUsers = data.map((user) => ({
          label: `${user.firstName} ${user.lastName} (${user.email}) `,
          value: user._id,
        }));

        setUsers(formattedUsers);
      }
    });
  }, []);

  useEffect(() => {
    getAllGroups((data) => {
      if (Array.isArray(data)) {
        const formattedGroups = data.map((group) => ({
          label: group.name,
          value: group._id,
        }));
        setGroups(formattedGroups);
        setGroupData(data);
      }
    });
  }, []);

  useEffect(() => {
    if (existingNews) {
      setFormData({
        title: existingNews.title || '',
        text: existingNews.text || '',
        level: existingNews.level || 'info',
        assignedUsers: existingNews.assignedUsers || [],
        assignedGroups: existingNews.assignedGroups || [],
        requiresReadConfirmation:
          existingNews.requiresReadConfirmation || false,
        files: existingNews.files || [],
      });
    }
  }, [existingNews]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileUpload = (e, key) => {
    const files = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      [key]: files,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();

    // Textwerte hinzufügen
    data.append('title', formData.title);
    data.append('text', formData.text);
    data.append('level', formData.level);
    data.append('requiresReadConfirmation', formData.requiresReadConfirmation);

    // Arrays als JSON-String übergeben
    data.append('assignedUsers', JSON.stringify(formData.assignedUsers));
    data.append('assignedGroups', JSON.stringify(formData.assignedGroups));

    // Dateien hinzufügen (falls neue hochgeladen wurden)
    if (formData.files.length > 0 && formData.files[0] instanceof File) {
      formData.files.forEach((file) => data.append('files', file));
    }

    setLoading(true);
    // Falls `existingNews`, wird es aktualisiert
    onSubmit(data, existingNews ? existingNews._id : null);
  };

  return (
    <form onSubmit={handleSubmit} className="pb-4">
      {/* Titel */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Titel</label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
          required
        />
      </div>

      {/* Text */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Text</label>
        <textarea
          name="text"
          value={formData.text}
          onChange={handleChange}
          rows="4"
          className="mt-1 p-2 w-full border rounded-md"
          required
        />
      </div>

      {/* Level Auswahl */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Level</label>
        <select
          name="level"
          value={formData.level}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="info">Info</option>
          <option value="medium">Mittel</option>
          <option value="high">Hoch</option>
        </select>
      </div>

      {/* Gruppen Zuweisung (MultiSelect) */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Zugewiesene Gruppen
        </label>
        <MultiSelect
          value={[...new Set(formData.assignedGroups)].filter(
            (g) => g && groups.some((group) => group.value === g)
          )}
          options={groups}
          onChange={(e) => {
            if (e.value.includes(e.selectedOption.value)) {
              // option wurde hinzugefügt
              setFormData((prev) => {
                const assignedGroups = groupData.filter((g) =>
                  e.value.includes(g._id)
                );

                const groupUserIds = new Set(
                  assignedGroups.flatMap((g) =>
                    g.assignedUsers.map((u) => u._id)
                  )
                );

                const combinedAssignedUsers = new Set([
                  ...prev.assignedUsers,
                  ...groupUserIds,
                ]);
                return {
                  ...prev,
                  assignedGroups: e.value,
                  assignedUsers: Array.from(combinedAssignedUsers),
                };
              });
            } else {
              // option wurde gelöscht
              const removedId = e.selectedOption.value || e.selectedOption;
              setFormData((prev) => {
                const assignedGroups = groupData.filter(
                  (g) => removedId === g._id
                );

                const groupUserIds = assignedGroups.map((g) =>
                  g.assignedUsers.map((u) => u._id)
                )[0];
                return {
                  ...prev,
                  assignedGroups: e.value,
                  assignedUsers: prev.assignedUsers.filter(
                    (u) => !groupUserIds.includes(u)
                  ),
                };
              });
              setFormData((prev) => ({ ...prev, assignedGroups: e.value }));
            }
          }}
          filter
          maxSelectedLabels={3}
          className="w-full"
          display="chip"
          placeholder="Gruppen auswählen"
          emptyFilterMessage="Keine Gruppen gefunden."
        />
      </div>

      {/* Nutzer Zuweisung (MultiSelect) */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Zugewiesene Nutzer
        </label>
        <MultiSelect
          value={[...new Set(formData.assignedUsers)].filter(
            (u) =>
              u &&
              users.some((user) => user.value === u) &&
              u !== authCtx.userId
          )}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, assignedUsers: e.value }))
          }
          options={users.filter((u) => u.value !== authCtx.userId)}
          filter
          maxSelectedLabels={3}
          className="w-full"
          display="chip"
          placeholder="Nutzer auswählen"
          emptyFilterMessage="Keine Nutzer gefunden."
        />
      </div>

      {authCtx.isAdmin && (
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="requiresReadConfirmation"
            name="requiresReadConfirmation"
            checked={formData.requiresReadConfirmation}
            onChange={handleChange}
            className="mr-2 cursor-pointer"
          />
          <label
            className="text-sm font-medium text-gray-700 cursor-pointer select-none"
            htmlFor="requiresReadConfirmation"
          >
            Lesebestätigung erforderlich
          </label>
        </div>
      )}

      {/* Datei Upload */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Bilder & Dokumente hochladen
        </label>
        <input
          type="file"
          multiple
          onChange={(e) => handleFileUpload(e, 'files')}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      {/* Submit Button */}
      <div className="flex w-full justify-end">
        <Button color="green" type="submit" maxWidth="200px">
          {loading ? 'Speichern' : existingNews ? 'Aktualisieren' : 'Erstellen'}
        </Button>
      </div>
    </form>
  );
};

export function InternalNewsTable(props) {
  const news = props.news;
  const authCtx = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const [selectedInternalNews, setSelectedInternalNews] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const { setPopupMessage } = useContext(PopupContext);
  const { getSecureFile } = useFetchContent();
  const { sendRequest } = useHttp();

  const [searchTerm, setSearchTerm] = useState('');
  const [filterUnread, setFilterUnread] = useState(false);
  const [filteredNews, setFilteredNews] = useState(news);

  useEffect(() => {
    const lowercasedSearch = searchTerm.toLowerCase();

    const filtered = news?.filter((n) => {
      const usersHaveRead = n?.readReceipts?.filter(
        (r) => n.assignedUsers.includes(r.userId) && !!r.readAt
      );
      return (
        ((filterUnread && usersHaveRead.length !== n.assignedUsers.length) ||
          !filterUnread) &&
        (n.title.toLowerCase().includes(lowercasedSearch) ||
          n.text.toLowerCase().includes(lowercasedSearch))
      );
    });

    setFilteredNews(filtered);
  }, [searchTerm, news, filterUnread]);

  const toggleExpand = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleSelect = (id) => {
    setSelectedInternalNews((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const showAddForm = () => {
    return showModal(
      'Neue Internal News erstellen',
      null,
      <InternalNewsForm onSubmit={props.handleAddNews} />
    );
  };

  function openProtectedPDF(filename) {
    const newWindow = window.open();
    getSecureFile('internal-news-files/' + filename, (d) => {
      newWindow.location = URL.createObjectURL(d);
    });
  }

  const deleteHandler = async () => {
    const internalNewsIds = Object.entries(selectedInternalNews)
      .map((o) => o[1] === true && o[0])
      .filter((o) => o !== false);

    const confirmed = window.confirm(
      `Möchten Sie ${
        internalNewsIds.length === 1
          ? 'einen Eintrag'
          : internalNewsIds.length + ' Einträge'
      } wirklich löschen?`
    );

    if (!confirmed) return;

    sendRequest(
      {
        url: '/api/v1/internalnews',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
        body: {
          internalNewsIds,
        },
      },
      () => {
        setPopupMessage(`Interne Nachricht(en) gelöscht.`, 'success');
        props.setInternalNews((prev) =>
          prev.filter((o) => !internalNewsIds.includes(o._id))
        );
      }
    );
  };
  const handleMarkAsRead = (newsId) => {
    sendRequest(
      {
        url: '/api/v1/internalnews/' + newsId + '/mark-as-read',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        props.setInternalNews((prev) => {
          return prev.map((newsItem) =>
            newsItem._id === data._id
              ? {
                  ...data,
                  assignedUsers: data.assignedUsers.map((as) => as._id),
                }
              : newsItem
          );
        });
      }
    );
  };

  return (
    <div className="mt-2 mb-4 px-4">
      <div className="grid grid-cols-12 relative overflow-hidden bg-white border-lightgray border-[1px] rounded-lg">
        <div className="col-span-12 px-2 grid grid-cols-12 gap-2 my-2">
          <div className="col-span-12 md:col-span-6">
            <form className="flex items-center">
              <label className="sr-only">Suchen</label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      rillrole="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      cliprole="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2 outline-none"
                  placeholder="Suchen"
                  required=""
                />
              </div>
            </form>
          </div>
          <div className="col-span-12 md:col-span-6 flex justify-end gap-x-2">
            {authCtx.isAdmin &&
              Object.entries(selectedInternalNews).filter((o) => o[1] === true)
                .length >= 1 && (
                <button
                  className="col-span-4 md:col-span-1 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                  onClick={deleteHandler}
                >
                  Löschen
                </button>
              )}
            <button
              className={`col-span-4 md:col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 border rounded-lg focus:outline-none  hover:text-primary-700 ${
                filterUnread
                  ? 'text-primary border-primary font-bold'
                  : 'bg-white border-gray-200'
              }`}
              onClick={() => setFilterUnread((p) => !p)}
            >
              Ungelesen
            </button>
            <button
              className="col-span-4 md:col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              onClick={showAddForm}
            >
              + Hinzufügen
            </button>
          </div>
        </div>
        <div className="col-span-12">
          <div className="hidden lg:grid grid-cols-12 text-left gap-4 px-4 py-2 text-xs font-semibold text-gray-700 uppercase bg-gray-50">
            <div className="col-span-5">Titel</div>
            <div className="col-span-2 text-right">Level</div>
            <div className="col-span-3 text-right">
              {authCtx.isAdmin ? 'Lesebestätigungen' : 'Gelesen'}
            </div>
            <div className="col-span-2 text-right"></div>
          </div>
          <hr className="block lg:hidden col-span-12 border-t-1 border-gray-200" />

          {filteredNews.map((n) => {
            const usersHaveRead = n?.readReceipts?.filter(
              (r) => n.assignedUsers.includes(r.userId) && !!r.readAt
            );
            return (
              <div key={n._id} className="border-b">
                <div
                  className="grid grid-cols-12 items-center gap-4 px-4 py-3 cursor-pointer hover:bg-gray-100"
                  onClick={() => toggleExpand(n._id)}
                >
                  <div className="col-span-10 lg:col-span-5 flex flex-row items-center font-medium text-gray-900 text-left">
                    <span
                      className={`text-gray-600 transition-transform duration-300 ${
                        expandedRows[n._id] ? 'rotate-90' : ''
                      }`}
                    >
                      <svg
                        fill="currentColor"
                        height="16px"
                        width="16px"
                        viewBox="0 0 330.002 330.002"
                      >
                        <path
                          id="XMLID_103_"
                          d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21
	l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001
	c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"
                        />
                      </svg>
                    </span>
                    {authCtx.isAdmin && (
                      <input
                        type="checkbox"
                        value={selectedInternalNews[n._id]}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSelect(n._id);
                        }}
                        className="col-span-1 mx-4 w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary cursor-pointer"
                      />
                    )}
                    <div className="flex flex-col">
                      <span>{n.title}</span>
                      <span className="adminInfo">
                        {' '}
                        {dateformat(new Date(n.createdAt), 'd.m.yy') +
                          ' um ' +
                          dateformat(new Date(n.createdAt), 'H:MM') +
                          ' Uhr'}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-2 text-right">
                    {n.level === 'high' ? (
                      <span className="rounded-md bg-danger/10 py-0.5 px-2.5 border border-transparent text-sm text-danger">
                        hoch
                      </span>
                    ) : n.level === 'danger' ? (
                      <span className="rounded-md bg-warning/10 py-0.5 px-2.5 border border-transparent text-sm text-warning">
                        mittel
                      </span>
                    ) : (
                      <span className="rounded-md bg-info/10 py-0.5 px-2.5 border border-transparent text-sm text-info">
                        info
                      </span>
                    )}
                  </div>
                  <div className="col-span-2 lg:hidden"></div>
                  <div className="col-span-6 lg:col-span-3 flex items-end font-medium text-gray-900 justify-end">
                    {!n.requiresReadConfirmation ? (
                      <span className="py-0.5 text-sm">
                        {authCtx.isAdmin ? 'ohne Lesebestätigung' : ''}
                      </span>
                    ) : authCtx.isAdmin ? (
                      <div
                        className="w-full flex items-center justify-end hover:underline"
                        onClick={(e) => {
                          e.stopPropagation();
                          return showModal(
                            'Lesebestätigungen',
                            null,
                            <ReadReceiptsTable
                              newsId={n._id}
                              setNews={props.setInternalNews}
                            />
                          );
                        }}
                      >
                        {usersHaveRead.length + '/' + n.assignedUsers.length}
                        <div
                          className={`inline-block w-3 h-3 ml-2 ${
                            usersHaveRead.length === n.assignedUsers.length
                              ? 'bg-green-700'
                              : 'bg-danger'
                          }  rounded-full`}
                        ></div>
                      </div>
                    ) : !n.readAt ? (
                      <span className="rounded-md bg-danger/10 py-0.5 px-2.5 border border-transparent text-sm text-danger uppercase">
                        noch nicht gelesen
                      </span>
                    ) : (
                      <span className="rounded-md py-0.5 px-2.5 border border-transparent text-right text-sm text-success">
                        gelesen am <br />
                        {dateformat(new Date(n.readAt), 'd.m.yy') +
                          ' um ' +
                          dateformat(new Date(n.readAt), 'H:MM') +
                          ' Uhr'}
                      </span>
                    )}
                  </div>
                  {!authCtx.isAdmin ? (
                    <div className="col-span-auto lg:col-span-2 text-right">
                      öffnen
                    </div>
                  ) : (
                    <div
                      className="col-span-4 lg:col-span-2 text-right text-blue-600 hover:underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        return showModal(
                          'News bearbeiten',
                          null,
                          <InternalNewsForm
                            onSubmit={props.handleAddNews}
                            existingNews={n}
                          />
                        );
                      }}
                    >
                      Bearbeiten
                    </div>
                  )}
                </div>

                {/* Expandable Row mit animierter Transition */}
                <div
                  className={`overflow-hidden transition-all duration-300 bg-gray-50 ${
                    expandedRows[n._id]
                      ? 'max-h-96 opacity-100 py-3'
                      : 'max-h-0 opacity-0 py-0'
                  }`}
                >
                  <div className="px-4 text-left">
                    <div className="p-4 bg-white border rounded-md shadow-sm whitespace-pre-wrap">
                      {n.text}
                      <ul className="my-2">
                        {n.files?.map((f, i) => {
                          return (
                            <li
                              key={i}
                              className="cursor-pointer"
                              onClick={() => openProtectedPDF(f.filename)}
                            >
                              {f.filename.replace(/^[^_]*_/, '')}
                            </li>
                          );
                        })}
                      </ul>
                      {!authCtx.isAdmin &&
                        !n.read &&
                        n.requiresReadConfirmation && (
                          <div className="w-full flex justify-end">
                            <Button
                              maxWidth="300px"
                              color="green"
                              onClick={() => handleMarkAsRead(n._id)}
                            >
                              Kenntnisnahe bestätigen
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-span-12 flex items-center justify-between bg-white px-4 py-3 sm:px-6">
          <div className="flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                <span className="font-medium">{news.length + ' '}</span>
                Mitteilungen
                {!authCtx.isAdmin && (
                  <>
                    , davon
                    <span className="font-medium">
                      {' ' + news.filter((o) => !o.readAt).length + ' '}
                    </span>
                    ungelesen
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternalNews;
