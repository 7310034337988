export const requests = [
  {
    title: 'Beratung',
    path: 'beratungstermin',
    steps: [
      [
        {
          type: 'select',
          title: 'Anrede',
          options: ['Herr', 'Frau', 'Andere'],
          mailLabel: 'Anrede',
        },
        {
          type: 'text',
          title: 'Nachname*',
          obligatory: true,
          key: 'lastName',
          mailLabel: 'Nachname',
        },
        {
          type: 'text',
          title: 'Vorname*',
          obligatory: true,
          key: 'firstName',
          mailLabel: 'Vorname',
        },
      ],
      [
        {
          type: 'email',
          title: 'E-Mail-Adresse',
          obligatory: true,
          key: 'fromMail',
          mailLabel: 'E-Mail-Adresse',
        },
        {
          type: 'telephone',
          title: 'Telefonnummer',
          key: 'phone',
          mailLabel: 'Telefonnummer',
        },
      ],
      [
        {
          type: 'date_future',
          title: 'Ihr Wunschtermin*',
          obligatory: true,
          mailLabel: 'Gewünschter Termin',
        },
        {
          type: 'date_future',
          title: 'Alternativtermin',
          obligatory: false,
          mailLabel: 'Gewünschter Alternativtermin',
        },
        {
          type: 'select',
          title: 'Marke',
          options: ['VW', 'Skoda', 'Audi'],
          controlled: {
            status: true,
            id: 'make',
          },
          mailLabel: 'Marke',
        },
        {
          type: 'text',
          title: 'Modell',
          controlled: {
            status: true,
            id: 'model',
          },
          mailLabel: 'Modell',
        },
      ],
      [
        {
          type: 'textarea',
          title: 'Um welches Thema (Produkt, Leistung) soll es gehen?',
          mailLabel: 'Gewünschte Leistung',
        },
      ],
      [
        {
          type: 'checkbox',
          title: 'Datenschutzerklärung',
          label: (
            <span>
              Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
              gelesen und akzeptiere diese.
            </span>
          ),
          obligatory: true,
          mailLabel: 'Datenschutzerklärung akzeptiert.',
        },
        // {
        //   type: 'checkbox',
        //   title: 'Einwilligungserklärung',
        //   label:
        //     'Ich bin damit einverstanden, dass meine Daten, die im Rahmen dieser Kontaktaufnahme erhoben werden, durch das Autohaus Rainer Seyfarth zu meiner Beratung, Betreuung und Information über Produkte und Dienstleistungen sowie ähnliche Aktivitäten per Post, Telefon, Fax und E-Mail genutzt werden dürfen. Sofern Sie der Verarbeitung und Nutzung Ihrer Daten später widersprechen wollen, bitten wir um eine kurze Benachrichtigung: Autohaus Rainer Seyfarth GmbH & Co. KG, Seeberger Landstraße 1, 99867 Gotha.',
        //   obligatory: true,
        //   mailLabel: 'Einwilligunserklärung akzeptiert.',
        // },
        {
          type: 'reCaptcha',
          key: 'reCaptcha',
          obligatory: true,
          mailLabel: 'Captcha war erfolgreich.',
        },
        {
          type: 'hidden',
          key: 'type',
          value: 'service',
        },
      ],
    ],
  },
  {
    title: 'Probefahrt',
    path: 'probefahrttermin',
    steps: [
      [
        {
          type: 'select',
          title: 'Anrede',
          options: ['Herr', 'Frau', 'Andere'],
          mailLabel: 'Anrede',
        },
        {
          type: 'text',
          title: 'Nachname*',
          obligatory: true,
          key: 'lastName',
          mailLabel: 'Nachname',
        },
        {
          type: 'text',
          title: 'Vorname*',
          obligatory: true,
          key: 'firstName',
          mailLabel: 'Vorname',
        },
      ],
      [
        {
          type: 'email',
          title: 'E-Mail-Adresse',
          obligatory: true,
          key: 'fromMail',
          mailLabel: 'E-Mail-Adresse',
        },
        {
          type: 'telephone',
          title: 'Telefonnummer',
          key: 'phone',
          mailLabel: 'Telefonnummer',
        },
      ],
      [
        {
          type: 'date_future',
          title: 'Ihr Wunschtermin*',
          obligatory: true,
          mailLabel: 'Gewünschter Termin',
        },
        {
          type: 'date_future',
          title: 'Alternativtermin',
          obligatory: false,
          mailLabel: 'Gewünschter Alternativtermin',
        },
        {
          type: 'select',
          title: 'Marke',
          options: ['VW', 'Skoda', 'Audi'],
          controlled: {
            status: true,
            id: 'make',
          },
          mailLabel: 'Marke',
        },
        {
          type: 'text',
          title: 'Modell',
          controlled: {
            status: true,
            id: 'model',
          },
          mailLabel: 'Modell',
        },
      ],
      [
        {
          type: 'textarea',
          title: 'Ihre Nachricht',
          mailLabel: 'Nachricht',
        },
      ],
      [
        {
          type: 'checkbox',
          title: 'Datenschutzerklärung',
          label: (
            <span>
              Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
              gelesen und akzeptiere diese.
            </span>
          ),
          obligatory: true,
          mailLabel: 'Datenschutzerklärung akzeptiert.',
        },
        // {
        //   type: 'checkbox',
        //   title: 'Einwilligungserklärung',
        //   label:
        //     'Ich bin damit einverstanden, dass meine Daten, die im Rahmen dieser Kontaktaufnahme erhoben werden, durch das Autohaus Rainer Seyfarth zu meiner Beratung, Betreuung und Information über Produkte und Dienstleistungen sowie ähnliche Aktivitäten per Post, Telefon, Fax und E-Mail genutzt werden dürfen. Sofern Sie der Verarbeitung und Nutzung Ihrer Daten später widersprechen wollen, bitten wir um eine kurze Benachrichtigung: Autohaus Rainer Seyfarth GmbH & Co. KG, Seeberger Landstraße 1, 99867 Gotha.',
        //   obligatory: true,
        //   mailLabel: 'Einwilligunserklärung akzeptiert.',
        // },
        {
          type: 'reCaptcha',
          key: 'reCaptcha',
          obligatory: true,
          mailLabel: 'Captcha war erfolgreich.',
        },
        {
          type: 'hidden',
          key: 'type',
          value: 'sales',
        },
      ],
    ],
  },
  {
    title: 'Fahrzeuganfrage',
    path: 'fahrzeuganfrage',
    steps: [
      [
        {
          type: 'select',
          title: 'Anrede',
          options: ['Herr', 'Frau', 'Andere'],
          mailLabel: 'Anrede',
        },
        {
          type: 'text',
          title: 'Nachname*',
          obligatory: true,
          key: 'lastName',
          mailLabel: 'Nachname',
        },
        {
          type: 'text',
          title: 'Vorname*',
          obligatory: true,
          key: 'firstName',
          mailLabel: 'Vorname',
        },
        { type: 'text', title: 'Firma', obligatory: false, mailLabel: 'Firma' },
        {
          type: 'email',
          title: 'E-Mail-Adresse',
          obligatory: true,
          key: 'fromMail',
          mailLabel: 'E-Mail-Adresse',
        },
        {
          type: 'telephone',
          title: 'Telefonnummer',
          key: 'phone',
          mailLabel: 'Telefonnummer',
        },
      ],
      [
        {
          type: 'select',
          title: 'Hersteller / Marke*',
          options: ['VW', 'Skoda', 'Audi'],
          obligatory: true,
          mailLabel: 'Hersteller / Marke',
        },
        {
          type: 'text',
          title: 'Modell*',
          obligatory: true,
          mailLabel: 'Modell',
        },
        {
          type: 'text',
          title: 'Aufbauart',
          obligatory: false,
          mailLabel: 'Aufbauart',
        },
        {
          type: 'text',
          title: 'Preis von',
          obligatory: false,
          mailLabel: 'Preis von',
        },
        {
          type: 'text',
          title: 'Preis bis',
          obligatory: false,
          mailLabel: 'Preis bis',
        },
        {
          type: 'text',
          title: 'Laufleistung von',
          obligatory: false,
          mailLabel: 'Laufleistung von',
        },
        {
          type: 'text',
          title: 'Laufleistung bis',
          obligatory: false,
          mailLabel: 'Laufleistung bis',
        },
        {
          type: 'date_past',
          title: 'Erstzulassung von',
          obligatory: false,
          mailLabel: 'Erstzulassung von',
        },
        {
          type: 'date_past',
          title: 'Erstzulassung bis',
          obligatory: false,
          mailLabel: 'Erstzulassung bis',
        },
        {
          type: 'select',
          title: 'Kraftstoff*',
          options: ['Diesel', 'Benzin', 'Hybrid', 'Elektro'],
          obligatory: false,
          mailLabel: 'Kraftstoff',
        },
        { type: 'text', title: 'Farbe', obligatory: false, mailLabel: 'Farbe' },
        {
          type: 'select',
          title: 'Getriebe',
          options: ['Automatik', 'Schaltgetriebe'],
          obligatory: false,
          mailLabel: 'Getriebe',
        },
      ],
      [
        {
          type: 'textarea',
          title: 'Ihre Nachricht',
          mailLabel: 'Nachricht',
        },
      ],
      [
        {
          type: 'checkbox',
          title: 'Datenschutzerklärung',
          label: (
            <span>
              Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
              gelesen und akzeptiere diese.
            </span>
          ),
          obligatory: true,
          mailLabel: 'Datenschutzerklärung akzeptiert.',
        },
        // {
        //   type: 'checkbox',
        //   title: 'Einwilligungserklärung',
        //   label:
        //     'Ich bin damit einverstanden, dass meine Daten, die im Rahmen dieser Kontaktaufnahme erhoben werden, durch das Autohaus Rainer Seyfarth zu meiner Beratung, Betreuung und Information über Produkte und Dienstleistungen sowie ähnliche Aktivitäten per Post, Telefon, Fax und E-Mail genutzt werden dürfen. Sofern Sie der Verarbeitung und Nutzung Ihrer Daten später widersprechen wollen, bitten wir um eine kurze Benachrichtigung: Autohaus Rainer Seyfarth GmbH & Co. KG, Seeberger Landstraße 1, 99867 Gotha.',
        //   obligatory: true,
        //   mailLabel: 'Einwilligunserklärung akzeptiert.',
        // },
        {
          type: 'reCaptcha',
          key: 'reCaptcha',
          obligatory: true,
          mailLabel: 'Captcha war erfolgreich.',
        },
        {
          type: 'hidden',
          key: 'type',
          value: 'sales',
        },
      ],
    ],
  },
  {
    title: 'Zubehöranfrage',
    path: 'zubehoer',
    steps: [
      [
        {
          type: 'text',
          key: 'accessories',
          title: 'Produkt',
          obligatory: true,
          // controlled: {
          //   status: true,
          //   id: 'product',
          // },
          mailLabel: 'Produkt',
        },
        {
          type: 'select',
          key: 'accessories_service',
          title: 'Leistung',
          options: ['mieten', 'kaufen'],
          obligatory: true,
          controlled: {
            status: true,
            id: 'service',
          },
          mailLabel: 'Leistung',
        },
      ],
      [
        {
          type: 'select',
          title: 'Anrede',
          options: ['Herr', 'Frau', 'Andere'],
          mailLabel: 'Anrede',
        },
        {
          type: 'text',
          title: 'Nachname*',
          obligatory: true,
          key: 'lastName',
          mailLabel: 'Nachname',
        },
        {
          type: 'text',
          title: 'Vorname*',
          obligatory: true,
          key: 'firstName',
          mailLabel: 'Vorname',
        },
      ],
      [
        {
          type: 'email',
          title: 'E-Mail-Adresse',
          obligatory: true,
          key: 'fromMail',
          mailLabel: 'E-Mail-Adresse',
        },
        {
          type: 'telephone',
          title: 'Telefonnummer',
          key: 'phone',
          mailLabel: 'Telefonnummer',
        },
      ],
      [
        {
          type: 'textarea',
          title: 'Ihr Kommentar',
          mailLabel: 'Kommentar',
        },
      ],
      [
        {
          type: 'checkbox',
          title: 'Datenschutzerklärung',
          label: (
            <span>
              Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
              gelesen und akzeptiere diese.
            </span>
          ),
          obligatory: true,
          mailLabel: 'Datenschutzerklärung akzeptiert.',
        },
        {
          type: 'reCaptcha',
          key: 'reCaptcha',
          obligatory: true,
          mailLabel: 'Captcha war erfolgreich.',
        },
        {
          type: 'hidden',
          key: 'type',
          value: 'products',
        },
      ],
    ],
  },
];
