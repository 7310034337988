import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import dayjs from 'dayjs';
import ModalContext from '../../store/modal-context';
import usePatchContent from '../../hooks/usePatchContent';
import dateformat from 'dateformat';
import usePostContent from '../../hooks/usePostContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import PopupContext from '../../store/popup-context';
import { useOpeningHours } from '../../hooks/openingHours';
import { globals } from '../../data/global-vars';

export function ExceptionList(props) {
  const _id = props._id;
  const { deleteExceptionFromOpeningHour } = useDeleteContent();
  const { closeModal } = useContext(ModalContext);
  const { setPopupMessage } = useContext(PopupContext);

  const exceptions =
    props.exceptions.filter((obj) => new Date(obj.date) > new Date()) || [];

  function deleteExceptionHandler(_eId) {
    deleteExceptionFromOpeningHour(_id, _eId, (d) => {
      props.updateOpeningHour(d);
      setPopupMessage('Ausnahme gelöscht.', 'success');
    });
    closeModal();
  }

  return (
    <div className="w-full space-y-4">
      {exceptions.length >= 1 ? (
        exceptions.map((e, i) => (
          <div key={i} className="flex items-start gap-4 border-b pb-2">
            <button
              onClick={() => deleteExceptionHandler(e._id)}
              className="text-red-500 font-bold text-sm hover:underline"
            >
              ✕
            </button>
            <div>
              <p className="font-medium text-gray-800">{e.description}</p>
              <p className="text-sm text-gray-500">
                {dateformat(e.date, 'dd.mm.yyyy')},{' '}
                {e.isOpen ? `${e?.start} - ${e?.end}` : 'geschlossen'}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="text-sm text-gray-500 pb-4">
          Es gibt keine aktuellen Ausnahmen.
        </p>
      )}
    </div>
  );
}

export function ExceptionForm(props) {
  const _id = props._id;
  const brand = props.brand;
  const { showModal } = useContext(ModalContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { error: uploadError, postException } = usePostContent();
  const [formData, setFormData] = useState({
    brand: brand,
    description: '',
    date: dayjs(new Date()).format('YYYY-MM-DD'),
    isOpen: false,
    start: '08:00',
    end: '12:00',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  function showExceptionsHandler() {
    showModal(
      'Alle Ausnahmen',
      null,
      <ExceptionList
        updateOpeningHour={props.updateOpeningHour}
        exceptions={props.exceptions}
        _id={_id}
      />
    );
  }

  function submitExceptionHandler() {
    const exception = {
      description: formData.description,
      isOpen: formData.isOpen,
      date: formData.date,
      start: formData.start,
      end: formData.end,
    };
    postException(_id, exception, (d) => {
      if (!uploadError) {
        setPopupMessage('Ausnahme hinzugefügt.', 'success');
        setFormData({
          brand: brand,
          description: '',
          date: dayjs(new Date()).format('YYYY-MM-DD'),
          isOpen: false,
          start: '08:00',
          end: '12:00',
        });
        props.updateOpeningHour(d);
      }
    });
  }

  return (
    <div className="w-full grid grid-cols-12 gap-4 mb-8">
      <div className="col-span-12 md:col-span-8">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Begründung
        </label>
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded px-3 py-2 text-sm"
        />
      </div>
      <div className="col-span-12 md:col-span-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Datum
        </label>
        <input
          type="date"
          name="date"
          min={dayjs(new Date()).format('YYYY-MM-DD')}
          value={formData.date}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded px-3 py-2 text-sm"
        />
      </div>
      <div className="col-span-12 flex items-center gap-4">
        <label className="flex items-center text-sm text-gray-700">
          <input
            type="checkbox"
            name="isOpen"
            checked={formData.isOpen}
            onChange={handleChangeCheckbox}
            className="mr-2"
          />
          teilweise geöffnet
        </label>
        {formData.isOpen && (
          <div className="flex items-center gap-2">
            <input
              type="time"
              name="start"
              value={formData.start}
              onChange={handleChange}
              className="border border-gray-300 rounded px-2 py-1 text-sm"
            />
            <span>–</span>
            <input
              type="time"
              name="end"
              value={formData.end}
              onChange={handleChange}
              className="border border-gray-300 rounded px-2 py-1 text-sm"
            />
          </div>
        )}
      </div>

      <div className="col-span-12 flex justify-end gap-2">
        <button
          type="button"
          onClick={showExceptionsHandler}
          className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300 text-sm"
        >
          alle Ausnahmen
        </button>

        <button
          className="col-span-4 md:col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
          type="button"
          onClick={submitExceptionHandler}
        >
          Hinzufügen
        </button>
      </div>
    </div>
  );
}

export function NewOpeningHoursForm() {
  const { patchOpeningHour, patchOpeningHourSingleDay } = usePatchContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { setPopupMessage } = useContext(PopupContext);

  const [formData, setFormData] = useState({
    selectedBrand: brandObject.make || 'VW',
    weekdaysAsString: '',
    saturdayAsString: '',
    sundayAsString: '',
    1: { start: '', end: '' },
    2: { start: '', end: '' },
    3: { start: '', end: '' },
    4: { start: '', end: '' },
    5: { start: '', end: '' },
    6: { start: '', end: '' },
    7: { start: '', end: '' },
  });

  const {
    data: queryData,
    isSuccess,
    error,
  } = useOpeningHours(
    useBrandFind(
      formData.selectedBrand === globals.BRANDS.VW.make
        ? globals.BRANDS.VW.url
        : globals.BRANDS.SKODA.url
    )
  );

  const [item, setItem] = useState(queryData);
  useEffect(() => {
    if (isSuccess && queryData) {
      setItem(queryData);
    }
  }, [isSuccess, queryData]);

  useEffect(() => {
    if (item) {
      const updatedForm = {
        selectedBrand: item.brand,
        weekdaysAsString: item.weekdaysAsString,
        saturdayAsString: item.saturdayAsString,
        sundayAsString: item.sundayAsString,
      };
      item.days.forEach((day) => {
        updatedForm[day.weekday] = {
          start: day.start || '',
          end: day.end || '',
        };
      });
      setFormData(updatedForm);
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTimeChange = (e, day, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [day]: { ...prevData[day], [field]: e.target.value },
    }));
  };

  function submitHandler(e) {
    e.preventDefault();
    const openingHour = {
      weekdaysAsString: formData.weekdaysAsString,
      saturdayAsString: formData.saturdayAsString,
      sundayAsString: formData.sundayAsString,
    };
    patchOpeningHour(item._id, openingHour, (d) => {
      if (!error) {
        item.days.map((day) => {
          const newDay = {
            weekday: day.weekday,
            weekdayDesc: day.weekdayDesc,
            start: formData[day.weekday]?.start || '',
            end: formData[day.weekday]?.end || '',
          };
          patchOpeningHourSingleDay(item._id, day._id, newDay, () => {});
        });
      }
      setPopupMessage('Öffnungszeiten aktualisiert!', 'success');
    });
  }

  if (!isSuccess || error) {
    return (
      <div className="text-center py-8 text-gray-500">Lade Öffnungszeiten…</div>
    );
  }

  return (
    <form
      onSubmit={submitHandler}
      className="w-full l grid grid-cols-12 gap-4 px-4"
    >
      <div className="col-span-12 md:col-span-3 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
            Haus
          </label>
          <select
            name="selectedBrand"
            value={formData.selectedBrand}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2 text-sm"
          >
            <option value={globals.BRANDS.VW.make}>
              Autohaus Rainer Seyfarth
            </option>
            <option value={globals.BRANDS.SKODA.make}>
              Automobile Am Mönchhof
            </option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
            Beschreibung wochentags
          </label>
          <input
            type="text"
            name="weekdaysAsString"
            value={formData.weekdaysAsString}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2 text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
            Beschreibung samstags
          </label>
          <input
            type="text"
            name="saturdayAsString"
            value={formData.saturdayAsString}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2 text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
            Beschreibung sonntags
          </label>
          <input
            type="text"
            name="sundayAsString"
            value={formData.sundayAsString}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded px-3 py-2 text-sm"
          />
        </div>

        <button
          className="col-span-4 md:col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
          type="submit"
        >
          Aktualisieren
        </button>
      </div>

      <div className="col-span-12 md:col-span-8">
        <h3 className="text-lg font-semibold mb-4">Standard</h3>
        <div className="space-y-4">
          {item.days.map((day, i) => (
            <div
              key={i}
              className="flex flex-row items-center space-y-1 list-disc"
            >
              <div className="flex items-center space-x-4">
                <div className="text-sm font-medium text-gray-700 w-[100px] text-left">
                  {day.weekdayDesc}
                </div>
                <span className="text-sm font-medium text-gray-700">von</span>
                <input
                  type="time"
                  value={formData[day.weekday]?.start || ''}
                  onChange={(e) => handleTimeChange(e, day.weekday, 'start')}
                  className="border border-gray-300 rounded px-2 py-1 text-sm"
                />
                <span className="text-sm font-medium text-gray-700">bis</span>
                <input
                  type="time"
                  value={formData[day.weekday]?.end || ''}
                  onChange={(e) => handleTimeChange(e, day.weekday, 'end')}
                  className="border border-gray-300 rounded px-2 py-1 text-sm"
                />{' '}
                <span className="text-sm font-medium text-gray-700">Uhr</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-12">
        <h3 className="text-lg font-semibold mt-6">Ausnahmen</h3>
        <ExceptionForm
          exceptions={item.exceptions}
          brand={formData.selectedBrand}
          _id={item._id}
        />
      </div>
    </form>
  );
}
