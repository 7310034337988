import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import AuthContext from '../store/auth-context';
import useHttp from './use-http';

export const useEmployees = (setEmployees) => {
  const authCtx = useContext(AuthContext);
  const { sendRequest } = useHttp();

  const query = useQuery({
    queryKey: ['employees'],
    queryFn: () =>
      new Promise((resolve, reject) => {
        sendRequest(
          {
            url: '/api/v1/person/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            if (data) {
              resolve(data);
            } else {
              reject(new Error('Fehler beim Laden der Mitarbeiter'));
            }
          }
        );
      }),
    enabled: !!authCtx.token,
  });

  useEffect(() => {
    if (query.data) {
      setEmployees(query.data);
    }
  }, [query.data]);

  return query;
};
