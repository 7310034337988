import { useCallback, useEffect } from 'react';
import { globals } from '../data/global-vars';
import useHttp from './use-http';
import { usePrevious } from './usePrevious';

function compareArrays(arr1, arr2) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

function reduceArrayAccordingToOptions(optionArray = [], setValueArray) {
  setValueArray((valueArray) =>
    valueArray.filter((value) => optionArray.includes(value))
  );
}

const cleanFilterObject = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!Array.isArray(value) || value.length > 0) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const useCarsPreflight = ({
  props,
  brands,
  models,
  baseColors,
  fuelTypes,
  transmissions,
  features,
  unfiltered,
  featureSuggestions,
  priceMin,
  priceMax,
  yearMin,
  yearMax,
  mileageMin,
  mileageMax,
  includeOtherBrands,
  setBrands,
  setD,
  setCount,
  setCountLoaded,
  setModels,
  setBaseColors,
  setFuelTypes,
  setTransmissions,
  setPriceMin,
  setPriceMax,
  setYearMin,
  setYearMax,
  setMileageMin,
  setMileageMax,
  setModelOptions,
  setColorOptions,
  setFuelTypeOptions,
  setTransmissionOptions,
  setShowOtherBrands,
}) => {
  const { sendRequest } = useHttp();
  const prevTierTwo = usePrevious({
    brand: brands,
    isNew: props.newCars ? true : false,
    class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
    model: models.length >= 1 ? models : null,
    baseColor: baseColors,
    fuelType: fuelTypes,
    transmissionType: transmissions,
    tags: features.map(
      (k) => featureSuggestions.find((obj) => obj.translation === k)?.key
    ),
  });
  const getCarsFilteredOptions = useCallback(
    async (filter, applyData) => {
      sendRequest(
        {
          url: '/api/v1/car/filter/preflight',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            ...filter,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  useEffect(() => {
    getCarsFilteredOptions(
      { class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'] },
      (d) => {
        setShowOtherBrands(
          d.brands.filter(
            (v) =>
              ![
                globals.BRANDS.VW.carzillaBrand,
                globals.BRANDS.AUDI.carzillaBrand,
                globals.BRANDS.SKODA.carzillaBrand,
              ].includes(v) && v
          ).length >= 1
        );
        if (includeOtherBrands)
          setBrands((b) => [
            ...b,
            ...d.brands.filter(
              (v) =>
                ![
                  globals.BRANDS.VW.carzillaBrand,
                  globals.BRANDS.AUDI.carzillaBrand,
                  globals.BRANDS.SKODA.carzillaBrand,
                ].includes(v) && v
            ),
          ]);
      }
    );
    if (!includeOtherBrands) {
      setBrands((b) =>
        b.filter((bb) =>
          [
            globals.BRANDS.VW.carzillaBrand,
            globals.BRANDS.AUDI.carzillaBrand,
            globals.BRANDS.SKODA.carzillaBrand,
          ].includes(bb)
            ? bb
            : null
        )
      );
    }
  }, [includeOtherBrands]);

  // TIER 1
  useEffect(() => {
    getCarsFilteredOptions(
      {
        brand: brands.length >= 1 ? brands : null,
        isNew: props.newCars ? true : false,
        class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      },
      (d) => {
        setD(d);
        reduceArrayAccordingToOptions(d.models, setModels);
        reduceArrayAccordingToOptions(d.baseColors, setBaseColors);
        reduceArrayAccordingToOptions(d.fuelTypes, setFuelTypes);
        reduceArrayAccordingToOptions(d.transmissionTypes, setTransmissions);

        if (!compareArrays(brands, unfiltered?.brands)) {
          setPriceMin(d.priceMin || 0);
          setPriceMax(d.priceMax || 0);
          setYearMax(new Date(d.firstRegistrationMax).getFullYear() || 0);
          setYearMin(new Date(d.firstRegistrationMin).getFullYear() || 0);
          setMileageMin(d.kmMin || 0);
          setMileageMax(d.kmMax || 0);
        }

        setModelOptions(
          d.models.map((k) => ({ label: k, value: k })).sortArray()
        );
        setColorOptions(
          d.baseColors.map((k) => ({ label: k, value: k })).sortArray()
        );
        setFuelTypeOptions(
          d.fuelTypes.map((k) => ({ label: k, value: k })).sortArray()
        );
        setTransmissionOptions(
          d.transmissionTypes.map((k) => ({ label: k, value: k })).sortArray()
        );
      }
    );
  }, [brands]);

  // TIER 2
  useEffect(() => {
    const genFilter = {
      isNew: props.newCars ? true : false,
      class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      brand: brands,
      model: models.length >= 1 ? models : null,
      baseColor: baseColors,
      fuelType: fuelTypes,
      transmissionType: transmissions,
      tags: features.map(
        (k) => featureSuggestions.find((obj) => obj.translation === k).key
      ),
    };

    getCarsFilteredOptions(cleanFilterObject(genFilter), (d) => {
      setD(d);
      if (
        !compareArrays(models, unfiltered?.models) ||
        !compareArrays(baseColors, unfiltered?.baseColors) ||
        !compareArrays(fuelTypes, unfiltered?.fuelTypes) ||
        !compareArrays(transmissions, unfiltered?.transmissions) ||
        !compareArrays(features, unfiltered?.features)
      ) {
        setPriceMin(d.priceMin || 0);
        setPriceMax(d.priceMax || 0);
        setYearMax(new Date(d.firstRegistrationMax).getFullYear() || 0);
        setYearMin(new Date(d.firstRegistrationMin).getFullYear() || 0);
        setMileageMin(d.kmMin || 0);
        setMileageMax(d.kmMax || 0);
      }
    });
  }, [brands, models, baseColors, fuelTypes, transmissions, features]);

  // TIER 3
  useEffect(() => {
    const currentTierTwo = {
      brand: brands,
      isNew: props.newCars ? true : false,
      class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      model: models.length >= 1 ? models : null,
      baseColor: baseColors,
      fuelType: fuelTypes,
      transmissionType: transmissions,
      tags: features.map(
        (k) => featureSuggestions.find((obj) => obj.translation === k)?.key
      ),
    };
    const isPrevTierTwoChanged =
      JSON.stringify(currentTierTwo) !== JSON.stringify(prevTierTwo);
    const genFilter = isPrevTierTwoChanged
      ? currentTierTwo
      : {
          ...currentTierTwo,
          priceFrom: Number(priceMin),
          priceTo: Number(priceMax),
          firstRegFrom: props.newCars ? null : yearMin + '-01',
          firstRegTo: props.newCars ? null : yearMax + '-12',
          kmFrom: props.newCars ? null : Number(mileageMin),
          kmTo: props.newCars ? null : Number(mileageMax),
        };

    getCarsFilteredOptions(cleanFilterObject(genFilter), (d) => {
      setD(d);
      setCount(d.count);
      setCountLoaded(true);
    });
  }, [
    brands,
    models,
    baseColors,
    fuelTypes,
    transmissions,
    features,
    priceMin,
    priceMax,
    mileageMin,
    mileageMax,
    yearMax,
    yearMin,
    features,
  ]);
};
