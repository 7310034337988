import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import useHttp from './use-http';
import AuthContext from '../store/auth-context';
import { globals } from '../data/global-vars';

/**
 * Custom Hook to fetch all departments for a given brand object.
 *
 * @param {Object} brandObject - The brand object containing a "make" property.
 * @returns {Object} Query result object from useQuery (data, isLoading, error, etc.)
 */

const desiredOrder = [
  'management',
  'newCarSales',
  'usedCarSells',
  'accounting',
  'workshop',
];

export const useDepartments = (brandObject) => {
  const { sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);

  return useQuery({
    queryKey: ['departments', brandObject?.make],
    queryFn: () =>
      new Promise((resolve, reject) => {
        sendRequest(
          {
            url: '/api/v1/department/by/brand/' + brandObject.make,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            if (data && Array.isArray(data)) {
              // 1. Aufteilen
              const preferred = [];
              const remaining = [];

              data.forEach((d) => {
                if (desiredOrder.includes(d.key)) {
                  preferred.push(d);
                } else {
                  remaining.push(d);
                }
              });

              // 2. Sortieren preferred nach desiredOrder
              preferred.sort((a, b) => {
                return (
                  desiredOrder.indexOf(a.key) - desiredOrder.indexOf(b.key)
                );
              });

              // 3. Restliche trennen
              const small = remaining.filter((d) => d.showMemberCount <= 2);
              const large = remaining.filter((d) => d.showMemberCount > 2);

              // 4. Alphabetisch sortieren
              large.sort((a, b) => a.title.localeCompare(b.title));
              small.sort((a, b) => a.title.localeCompare(b.title));

              // 5. Zusammenführen
              const final = [...preferred, ...large, ...small];

              resolve(final);
            } else {
              reject(new Error('Fehler beim Laden der Departments'));
            }
          }
        );
      }),
    enabled: !!brandObject?.make,
    initialData: [],
  });
};

export const useAllDepartmentsGrouped = (setDepartments) => {
  const { sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);

  const query = useQuery({
    queryKey: ['all-departments-grouped'],
    queryFn: () =>
      new Promise((resolve, reject) => {
        const entries = Object.entries(globals.BRANDS);
        const result = [];

        let completed = 0;
        entries.forEach(([key, brand]) => {
          sendRequest(
            {
              url: '/api/v1/department/by/brand/' + brand.make,
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
              },
            },
            (data) => {
              result.push({
                brandKey: brand.make,
                brandTitle: brand.name,
                primeColor: brand.primeColor,
                departments: data || [],
              });
              completed++;

              if (completed === entries.length) {
                resolve(result);
              }
            }
          );
        });
      }),
    enabled: !!authCtx.token,
    initialData: [],
  });

  useEffect(() => {
    if (query.data) {
      setDepartments(query.data);
    }
  }, [query.data]);

  return query;
};
