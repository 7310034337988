import classes from './ImageCarousel.module.css';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useEffect } from 'react';
import { getApiUrl } from '../../config/api';

function ImageCarousel(props) {
  const images = Array.from({ length: props.pictureCount }).map((v, i) => {
    return {
      originalClass: classes.imageClass,
      thumbnailClass: classes.thumbnailClass,
      original: getApiUrl(
        `/images/cars/${props.carzillaId}_${String(i + 1).padStart(2, '0')}.jpg`
      ),
      thumbnail: getApiUrl(
        `/images/cars/${props.carzillaId}_${String(i + 1).padStart(2, '0')}.jpg`
      ),
    };
  });

  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        props.onHide();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 120,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        padding: '0 10px',
        backgroundColor: 'white',
      }}
    >
      <svg
        className="absolute top-[15px] right-[15px] h-6 w-6 text-[var(--text-color)]"
        onClick={props.onHide}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />{' '}
        <line x1="18" y1="6" x2="6" y2="18" />{' '}
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
      <div
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <ReactImageGallery items={images} autoPlay={false} />
      </div>
    </div>
  );
}

export default ImageCarousel;
