import classes from './Wohnmobile.module.css';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import StaffWidgets from '../About/StaffWidgets';
import PageHeading from '../UI/PageHeading';
import CaravanWidgets from '../Cars/CaravanWidget';
import Animation from '../UI/Animation';
import PageLoaderIcon from '../UI/PageLoaderIcon';

// IMPORT MUI COMPONENTS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Paper,
  styled,
  Tab,
  // Tabs,
  ButtonGroup,
  // Stack,
} from '@mui/material/';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useMediaQuery from '@mui/material/useMediaQuery';

// IMPORT DATA
import { checklisten } from '../../data/caravan';

// IMPORT HOOKS
import { useBrandFind } from '../../hooks/useFinders';
import { useInView } from 'react-intersection-observer';

// IMPORT IMAGES
import weinsberg_background from '../../assets/caravans/csm_Kopfbild_Weinsberg_Range_2_14163b49dc.webp';
import knaus_background from '../../assets/caravans/csm_Kopfbild_Knaus_BoxLife_600_MQ_9a9a1c783f.webp';
import SingleText from '../UI/SingleText';
import {
  MultiTextDeleteIcon,
  MultiTextEntry,
  MultiTextHeader,
  MultiTextTitle,
  MultiTextWrapper,
  MultiTextAdminInfo,
  MultiTextSubmitIcon,
} from '../UI/MultiText';
import PopupContext from '../../store/popup-context';
import AuthContext from '../../store/auth-context';
import usePostContent from '../../hooks/usePostContent';
import { StyledTabs, TabPanel } from '../UI/TabController';
import { globals } from '../../data/global-vars';
import ModalContext from '../../store/modal-context';
import Wohnmobilbestand from './Wohnmobilbestand';
import { getApiUrl } from '../../config/api';

const ColorButton = styled(MuiButton)(({ theme }) => ({
  color: '#474747',
  borderColor: '#474747',
  '&:hover': {
    borderColor: 'black',
    backgroundColor: 'lightgray',
  },
  '&.videoChecked': {
    color: 'white',
    backgroundColor: '#474747',
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: 'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  '&:focus': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: 'rgba(255, 255, 255, 0.9)',
  flexDirection: 'row-reverse',
  border: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  border: 'none',
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
}

function Wohnmobile() {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const history = useHistory();
  const { setPopupMessage } = useContext(PopupContext);
  const authCtx = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const [videoLink, setVideoLink] = useState('11e4JdNvx2s');
  const { showModal } = useContext(ModalContext);
  const emptyMultiText = {
    _id: '',
    title: '',
    texts: [],
    editedBy: '',
    editedAt: '',
    createdBy: '',
    createdAt: '',
  };
  const [editMietbedingungen, setEditMietbedingungen] = useState(false);
  const [mietbedingungenn, setMietbedingungen] = useState(emptyMultiText);
  const [mietbedingungenLoaded, setMietbedingungenLoaded] = useState(false);
  const { error, postMultiTextEntry } = usePostContent();
  const [richText, setRichText] = useState(true);
  const [newTitle, setNewTitle] = useState('');
  const [newSubtitle, setNewSubtitle] = useState('');
  const [newEntry, setNewEntry] = useState('');

  function submitNewMietbedingungenHandler() {
    postMultiTextEntry(
      mietbedingungenn._id,
      { title: newTitle, subtitle: newSubtitle, text: newEntry },
      (d) => {
        if (!error) {
          setPopupMessage(
            '"' + newTitle + '" wurde erfolgreich hinzugefügt!',
            'success'
          );
          setNewTitle('');
          setNewSubtitle('');
          setNewEntry('');
          setMietbedingungen(d);
        } else {
          setPopupMessage('Es gab einen Fehler beim Hochladen!', 'error');
        }
      }
    );
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const tabKey = params[0].split('/');
  useEffect(() => {
    if (tabKey[0].indexOf('p=') !== -1) {
      if (value !== 1) {
        setValue(1);
      }
    } else {
      if (value !== 0) window.scrollTo(0, 0);
      setValue(0);
    }
  }, [value, tabKey]);

  const [iFrameHeight, setIFrameHeight] = useState(-1);
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.type === 'message') {
        const height = e.data;
        if (typeof height === 'string' && height !== null) {
          if (height.split(':')[1] !== undefined)
            setIFrameHeight(Number(height.split(':')[1].replace(/\D/g, '')));
        }
      }
    });
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  const [notebookHeadingRef, inView] = useInView({
    threshold: 0.25,
    triggerOnce: true,
    trackVisibility: true,
    initialInView: false,
    fallbackInView: true,
    delay: 101,
  });
  const [notebookInView, setNotebookInView] = useState(false);
  useEffect(() => {
    if (inView) {
      setNotebookInView(true);
    }
  }, [inView]);

  function getCorrespondingUrl(index) {
    switch (index) {
      case 0:
        return 'vermietung';

      case 1:
        return 'p=1';

      default:
        return 'vermietung';
    }
  }

  function handleTabChange(event, index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/` + url);
  }

  const isVerticalOrientation = useMediaQuery('(max-width:560px)');
  const isHeaderSpaceNeeded = useMediaQuery('(max-width:599px)');

  return (
    <>
      <PageHeading title="Wohnmobile mieten und kaufen">
        Wohnmobile {value === 0 ? 'mieten' : 'kaufen'}
      </PageHeading>
      <div className="container">
        <div className="w-full">
          <div className={`w-full ${classes.tabBar}`}>
            <StyledTabs
              value={value}
              indicatorColor={brandObject.primeColor}
              onChange={handleTabChange}
              sx={{ marginBottom: '20px' }}
              variant="fullWidth"
            >
              <Tab label="Vermietung" />
              <Tab label="Verkauf" />
            </StyledTabs>
          </div>
          <SwipeableViews
            axis="x"
            index={value}
            onChangeIndex={handleChangeTabIndex}
            enableMouseEvents={editMietbedingungen ? false : true}
            style={{
              marginBottom: '40px',
              paddingLeft: 'calc(env(safe-area-inset-left))',
              paddingRight: 'calc( env(safe-area-inset-right))',
            }}
            className="noSelect"
          >
            <TabPanel
              value={value}
              index={0}
              dir={theme.direction}
              style={{
                overflow: 'hidden',
              }}
            >
              <div
                className={`container ${classes.section}`}
                style={{ marginTop: 0 }}
                id="termin-buchen"
              >
                <div style={{ position: 'relative' }}>
                  {iFrameHeight <= 0 && (
                    <div
                      style={{
                        backgroundColor: 'white',
                        zIndex: 90,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <PageLoaderIcon isLoading={iFrameHeight <= 0} />
                    </div>
                  )}
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      aspectRatio: iFrameHeight <= 0 && '1205 / 826',
                      minHeight: '' + iFrameHeight + 'px',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                        left: 0,
                      }}
                    >
                      <iframe
                        src="https://wl.be.rentandtravel.de?locale=de-DE&amp;station=4588&amp;vehicleType=motorhome"
                        width="100%"
                        style={{
                          height: '' + iFrameHeight + 'px',
                          borderRadius: '4px',
                          border: '0px',
                        }}
                        data-ll-status="loading"
                        className="entered loading"
                        title="womo-iframe"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`container ${classes.section} p-2.5`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="flex justify-center">
                    <Item>
                      <h2 className={classes.paperHeading}>
                        <SingleText
                          id="63e40a7cfe838d336b0f468a"
                          field="title"
                        />
                      </h2>
                      <div
                        className={`${classes.infoText} ${classes.leftText}`}
                      >
                        <SingleText
                          id="63e40a7cfe838d336b0f468a"
                          field="text"
                        />
                      </div>
                      <h4 className={`${classes.leftText}`}>
                        Sie können wählen zwischen:
                      </h4>
                      <div className={classes.modelWrapper}>
                        <CaravanWidgets />
                      </div>
                      <h4 className={`${classes.leftText}`}>
                        Ihre Ansprechpartner:
                      </h4>
                      <div className={classes.contactWrapper}>
                        <StaffWidgets dep="caravans" noBackground />
                      </div>
                    </Item>
                  </div>
                  <div
                    className="py-5"
                    onClick={() => {
                      if (authCtx.isAdmin) {
                        showModal(
                          'Bearbeiten',
                          null,
                          <div style={{ paddingBottom: '10px' }}>
                            <SingleText
                              id="63f4fc92acdc38c4822692ea"
                              field="title"
                            />
                            <br />
                            <SingleText
                              id="63f4fc92acdc38c4822692ea"
                              field="text"
                            />
                          </div>
                        );
                      }
                    }}
                  >
                    <div className={classes.notebookWrapper}>
                      <div className={classes.notebookPaper}>
                        <div className={classes.notebookLines}>
                          <div
                            className={classes.notebookText}
                            ref={notebookHeadingRef}
                          >
                            <h2 className={`${classes.notebookHeading}`}>
                              <SingleText
                                id="63f4fc92acdc38c4822692ea"
                                field="title"
                                noEdit
                                typewriterOptions={{
                                  autoStart: notebookInView,
                                  loop: false,
                                  delay: 1,
                                  cursor: '',
                                }}
                              />
                            </h2>
                            <div className="mt-5 ml-4">
                              <SingleText
                                id="63f4fc92acdc38c4822692ea"
                                field="text"
                                noEdit
                                typewriterOptions={{
                                  autoStart: notebookInView,
                                  loop: false,
                                  delay: 0.1,
                                  cursor: '',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${classes.holes} ${classes.holeTop}`}
                        ></div>
                        <div
                          className={`${classes.holes} ${classes.holeMiddle}`}
                        ></div>
                        <div
                          className={`${classes.holes} ${classes.holeBottom}`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${classes.section} container`}
                id="mietbedingungen"
              >
                <MultiTextWrapper
                  id="63ef598b2253202af1779c28"
                  setEdit={setEditMietbedingungen}
                  applyData={setMietbedingungen}
                  setLoaded={setMietbedingungenLoaded}
                >
                  {mietbedingungenLoaded && (
                    <>
                      <h1 className={classes.sectionHeading}>
                        <MultiTextHeader
                          value={mietbedingungenn}
                          setValue={setMietbedingungen}
                          edit={editMietbedingungen}
                          setEdit={setEditMietbedingungen}
                          richText={richText}
                          setRichText={setRichText}
                        />
                        <MultiTextAdminInfo value={mietbedingungenn} />
                      </h1>
                      <div className={classes.mietWrapper}>
                        <img
                          src={weinsberg_background}
                          alt="Wohnmobil auf einem Parkplatz vor einer Berglandschaft"
                          className={classes.mietBackground}
                          loading="lazy"
                        />
                        <div className={classes.mietOverlay}>
                          <div className={classes.mietContainer}>
                            {mietbedingungenn.texts.map((b, i) => {
                              return (
                                <Animation
                                  key={i}
                                  sx={{
                                    paddingLeft:
                                      'calc(env(safe-area-inset-left))',
                                    paddingRight:
                                      'calc( env(safe-area-inset-right))',
                                  }}
                                >
                                  <Accordion
                                    expanded={expanded === 'panel' + i}
                                    onChange={handleChangeAccordion(
                                      'panel' + i
                                    )}
                                    className={classes.accordion}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <ExpandMoreIcon
                                          className={
                                            classes.accordionExpandIcon
                                          }
                                        />
                                      }
                                    >
                                      <div className="grid grid-cols-12 gap-x-2 w-full">
                                        <b className="col-span-12 sm:col-span-5 text-left">
                                          <MultiTextTitle
                                            id={mietbedingungenn._id}
                                            value={b}
                                            setValue={setMietbedingungen}
                                            edit={editMietbedingungen}
                                            setEdit={setEditMietbedingungen}
                                          />
                                        </b>
                                        <div className="col-span-12 sm:col-span-7 text-left">
                                          <MultiTextTitle
                                            id={mietbedingungenn._id}
                                            value={b}
                                            setValue={setMietbedingungen}
                                            edit={editMietbedingungen}
                                            setEdit={setEditMietbedingungen}
                                            subtitle
                                            sx={{
                                              whiteSpace: {
                                                xs: 'nowrap',
                                                md: 'unset',
                                              },
                                            }}
                                          />
                                        </div>
                                        <div className="col-span-0.5 text-right flex items-center justify-end">
                                          <MultiTextDeleteIcon
                                            id={mietbedingungenn._id}
                                            value={b}
                                            setValue={setMietbedingungen}
                                            edit={editMietbedingungen}
                                          />
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      className={classes.accordionText}
                                    >
                                      <MultiTextEntry
                                        id={mietbedingungenn._id}
                                        value={b}
                                        setValue={setMietbedingungen}
                                        edit={editMietbedingungen}
                                        setEdit={setEditMietbedingungen}
                                        richText={richText}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </Animation>
                              );
                            })}
                            {editMietbedingungen && authCtx.isAdmin && (
                              <Animation>
                                <Accordion
                                  expanded={expanded === 'panel0'}
                                  onChange={handleChangeAccordion('panel0')}
                                  className={classes.accordion}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        className={classes.accordionExpandIcon}
                                      />
                                    }
                                  >
                                    <div className="grid grid-cols-12 gap-4">
                                      <div className="col-span-5">
                                        <MultiTextTitle
                                          id={mietbedingungenn._id}
                                          value={newTitle}
                                          setValue={setNewTitle}
                                          edit={editMietbedingungen}
                                          setEdit={setEditMietbedingungen}
                                          new
                                        />
                                      </div>
                                      <div className="col-span-6.5">
                                        <MultiTextTitle
                                          id={mietbedingungenn._id}
                                          value={newSubtitle}
                                          setValue={setNewSubtitle}
                                          edit={editMietbedingungen}
                                          setEdit={setEditMietbedingungen}
                                          subtitle
                                          new
                                        />
                                      </div>
                                      <div className="col-span-0.5 text-right flex items-center justify-end">
                                        <MultiTextSubmitIcon
                                          submit={
                                            submitNewMietbedingungenHandler
                                          }
                                        />
                                      </div>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    className={classes.accordionText}
                                  >
                                    <MultiTextEntry
                                      id={mietbedingungenn._id}
                                      value={newEntry}
                                      setValue={setNewEntry}
                                      edit={editMietbedingungen}
                                      setEdit={setEditMietbedingungen}
                                      richText={richText}
                                      new
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </Animation>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </MultiTextWrapper>
              </div>
              <div
                className={`container ${classes.section}`}
                id="checklisten"
                style={{ marginBottom: '40px' }}
              >
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 md:col-span-8 mb-10">
                    <div className="w-full">
                      <h1 className={classes.sectionHeading}>Checklisten</h1>
                    </div>
                    <div className="grid grid-cols-2 gap-2 md:gap-6 h-full pb-5">
                      {checklisten.map((c, index) => (
                        <div key={index} className="col-span-2 sm:col-span-1">
                          <div className={classes.checklistWrapper}>
                            <Animation dir="scaleIn">
                              <a
                                href={c.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={c.image}
                                  alt={'Symbolbild: ' + c.title}
                                  className="mx-auto w-[60%] -mb-[10px] object-cover"
                                  loading="lazy"
                                />
                                <br />
                                <span
                                  className={`${classes.checklistTitle} leading-4`}
                                >
                                  {c.title}
                                </span>
                              </a>
                            </Animation>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 md:col-span-4 justify-center gap-4">
                    <h1 className={`sectionHeading col-span-12`}>Mehr Infos</h1>
                    <div className="col-span-12 max-w-[400px]">
                      <a
                        href={getApiUrl(
                          '/pdf/caravan/knaus-tabbert-packliste-campingurlaub.pdf'
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={`bg-[var(--light-contrast)] h-[80px] rounded-lg flex items-center justify-center hover:shadow-xl text-[var(--text-color)] font-[300] uppercase`}
                        >
                          <Animation dir="left">
                            Packliste Campingurlaub
                          </Animation>
                        </div>
                      </a>
                    </div>
                    <div className="col-span-12 max-w-[400px]">
                      <a
                        href={getApiUrl(
                          '/pdf/caravan/knaus-tabbert-tipps-fuer-reisen-in-europa.pdf'
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={`bg-[var(--light-contrast)] h-[80px] rounded-lg flex items-center justify-center hover:shadow-xl text-[var(--text-color)] font-[300] uppercase`}
                        >
                          <Animation dir="right">Tipps und Tricks</Animation>
                        </div>
                      </a>
                    </div>
                    <div className="col-span-12 max-w-[400px]">
                      <a
                        href={
                          getMobileOperatingSystem() === 'ios'
                            ? 'https://apps.apple.com/de/app/california-on-tour/id1480369449'
                            : 'https://play.google.com/store/apps/details?id=de.volkswagen.CaliforniaOnTour&hl=gsw&gl=US'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={`bg-[var(--light-contrast)] h-[80px] rounded-lg flex items-center justify-center hover:shadow-xl text-[var(--text-color)] font-[300] uppercase`}
                        >
                          <Animation dir="left">
                            California on Tour App
                          </Animation>
                        </div>
                      </a>
                    </div>
                    <div className="col-span-12 max-w-[400px]">
                      <a
                        href="https://www.alpacacamping.de/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={`bg-[var(--light-contrast)] h-[80px] rounded-lg flex items-center justify-center hover:shadow-xl text-[var(--text-color)] font-[300] uppercase`}
                        >
                          <Animation dir="right">Campingplatz finden</Animation>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${classes.section} container`}>
                <div className="w-full">
                  <h1 className={classes.sectionHeading}>Einweisungsvideos</h1>
                </div>
                <div className={`w-full relative ${classes.videoContainer}`}>
                  <Animation dir="blurIn">
                    <img
                      src={knaus_background}
                      alt="Wohnmobil auf einer Brücke mit Blick in die Landschaft"
                      className={classes.backgroundImageIFrame}
                      loading="lazy"
                    />
                  </Animation>
                  <div className={classes.iFrameContainer}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '10px 0',
                        width: '100%',
                      }}
                    >
                      <Animation dir="right">
                        <ButtonGroup
                          variant="outlined"
                          aria-label="outlined button group"
                          style={{ backgroundColor: 'white' }}
                          orientation={
                            isVerticalOrientation ? 'vertical' : 'horizontal'
                          }
                          className={classes.buttonGroupVert}
                        >
                          <ColorButton
                            onClick={() => setVideoLink('11e4JdNvx2s')}
                            className={`${
                              videoLink === '11e4JdNvx2s' && 'videoChecked'
                            }`}
                          >
                            KNAUS BoxLife
                          </ColorButton>
                          <ColorButton
                            className={`${
                              videoLink === 'dql7YJk3_zs' && 'videoChecked'
                            }`}
                            onClick={() => setVideoLink('dql7YJk3_zs')}
                          >
                            WEINSBERG CaraTour
                          </ColorButton>
                          <ColorButton
                            onClick={() => setVideoLink('_R7JLQOZWfQ')}
                            className={`${
                              videoLink === '_R7JLQOZWfQ' && 'videoChecked'
                            }`}
                          >
                            WEINSBERG CaraSuite
                          </ColorButton>
                        </ButtonGroup>
                      </Animation>
                    </Box>
                    <Animation dir="left">
                      <iframe
                        className={classes.posIframe}
                        src={'https://www.youtube.com/embed/' + videoLink}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{}}
                      />
                    </Animation>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              dir={theme.direction}
              id="wohnmobilverkauf"
            >
              <Wohnmobilbestand />
            </TabPanel>
          </SwipeableViews>
        </div>
        <div className="w-full pb-10">
          <StaffWidgets dep="caravans" />
        </div>
      </div>
    </>
  );
}

export default Wohnmobile;
