import classes from './SuggestedCars.module.css';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import CompareContext from '../../store/compare-context';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';

// IMPORT REACT SWIPER COMPONENTS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ListItemText } from '@mui/material';

// IMPORT FONTAWESOME ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faList,
  faCircleMinus,
  faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import Animation from '../UI/Animation';
import useFetchContent from '../../hooks/useFetchContent';
import usePostContent from '../../hooks/usePostContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import { CarPreview } from './Cars';
import { globals } from '../../data/global-vars';
import { getApiUrl } from '../../config/api';

function SuggestedCarModal(props) {
  const history = useHistory();
  const compareCtx = useContext(CompareContext);
  const brandObject = props.brandObject;

  // declare data vars
  const c = props.car;

  let brandUrl = brandObject.url;
  if (c.branch === 'moenchhof') brandUrl = globals.BRANDS.SKODA.url;
  else if (c.branch === 'seyfarth_nw' || c.branch === 'seyfarth_gw')
    brandUrl = globals.BRANDS.VW.url;
  else if (c.brand === globals.BRANDS.AUDI.carzillaBrand)
    brandUrl = globals.BRANDS.AUDI.url;
  else if (c.brand === globals.BRANDS.VW_NFZ.carzillaBrand)
    brandUrl = globals.BRANDS.VW_NFZ.url;

  // const d = c.importantDetails;
  const [compare, setCompare] = useState(
    compareCtx.cars.includes(c.carzillaId)
  );

  // redirect to according detail page
  function showDetailPage() {
    return history.push(`/${brandUrl}/details/${c.carzillaId}`);
  }
  const displayOptionsOrder = [
    'transmissionType',
    'km',
    'enkvFuelType',
    'kW',
    'firstRegistration',
  ];

  return (
    <Grid xs={12} container sx={{ padding: '20px 0' }}>
      <Grid xs={12}>
        <CarPreview
          data={c}
          displayOptionsOrder={displayOptionsOrder}
          priceOptions={['leasing', 'total', 'financing']}
          brandObject={brandObject}
          noHeader
          keyboardScroll
        />
      </Grid>
      <Grid
        xs={12}
        columnSpacing={2}
        rowSpacing={0}
        sx={{ marginTop: '20px' }}
        container
      >
        <Grid xs="auto">
          <Button className={`${classes.button}`} onClick={showDetailPage}>
            Mehr Informationen
          </Button>
        </Grid>
        <Grid xs="auto">
          <Button
            className={`${classes.button}`}
            onClick={() =>
              history.push(`/${brandUrl}/details/${c.carzillaId}`, {
                scroll: true,
                data: {
                  reason: 'Probefahrt',
                },
              })
            }
          >
            Probefahrt
          </Button>
        </Grid>
        <Grid xs="auto">
          <Button
            className={`${classes.button}`}
            onClick={() =>
              history.push(`/${brandUrl}/details/${c.carzillaId}`, {
                scroll: true,
                data: {
                  reason: 'Beratung',
                },
              })
            }
          >
            Beratungstermin
          </Button>
        </Grid>
        <Grid xs="auto">
          <Button
            icon={faHeart}
            color={compare ? props.primeColor : 'var(--text-color)'}
            className={`${classes.button}`}
            onClick={() => {
              compareCtx.toggleCars(c.carzillaId);
              setCompare((v) => !v);
            }}
          >
            Vergleichen
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

// SINGLE SUGGESTED CAR WIDGET
function SuggestedCar({ c, patchSuggestedCars }) {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColor = brandObject.primeColor;
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteSuggestedCar } = useDeleteContent();
  const suggestedCarId = c.suggestedCarId;

  function showModalHandler() {
    setTimeout(() => {
      window.auWidgetEmbed?.init();
    }, 50);
    if (c)
      showModal(
        c.brand + ' ' + c.model,
        null,
        <SuggestedCarModal brandObject={brandObject} car={c} />
      );
  }

  function deleteSuggestedCarHandler() {
    if (c)
      showModal(
        c.brand + ' ' + c.model,
        null,
        <Button
          onClick={() => {
            deleteSuggestedCar(suggestedCarId, (d) => {
              if (!deleteError) {
                patchSuggestedCars(d, 'delete');
              }
            });
            setPopupMessage(
              '"' + c.brand + ' ' + c.model + '" von den Vorschlägen entfernt.',
              'success'
            );
            closeModal();
          }}
          color="red"
        >
          VON VORSCHLÄGEN ENTFERNEN
        </Button>
      );
  }
  if (!c) return;
  else
    return (
      <Animation whileTap>
        <div className={classes.container}>
          {authCtx.isAdmin && (
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon
                icon={faCircleMinus}
                className={classes.deleteIcon}
                onClick={deleteSuggestedCarHandler}
              />
            </div>
          )}
          <div onClick={showModalHandler}>
            <img
              src={getApiUrl(`/images/cars/${c.carzillaId}_01.jpg`)}
              className={classes.widgetImage}
              alt={'Vorschau ' + c.model + ' der Firma ' + c.brand}
              loading="lazy"
            />
            <div className={classes.shortInfo}>
              <span className={classes.model} style={{ color: primeColor }}>
                {c.model}
              </span>
              <span className={classes.price}>
                {Math.round(c.importantDetails.price).toLocaleString()}€
              </span>
            </div>
          </div>
        </div>
      </Animation>
    );
}

const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function SuggestedCarListItem(props) {
  const { error: uploadError, postSuggestedCar } = usePostContent();
  const { error: deleteError, deleteSuggestedCar } = useDeleteContent();
  const car = props.car;

  function clickHandler() {
    if (props.toAdd) {
      postSuggestedCar({ _id: car.carzillaId, brand: car.brand }, (d) => {
        if (!uploadError) {
          props.patchSuggestedCars(d, 'add');
        }
      });
    } else {
      deleteSuggestedCar(car.suggestedCarId, (d) => {
        if (!deleteError) {
          props.patchSuggestedCars(d, 'delete');
        }
      });
    }
  }

  return (
    <Grid
      xs={12}
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: props.toAdd && '10px',
        marginBottom: props.toAdd && '10px',
        cursor: 'pointer',
      }}
      onClick={clickHandler}
    >
      <Grid
        xs={12}
        sm="auto"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        {props.toAdd ? (
          <FontAwesomeIcon
            icon={faCirclePlus}
            className={`${classes.textIcon} ${classes.addIcon}`}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <HighlightOffIcon
            style={{ cursor: 'pointer' }}
            className={`${classes.textIcon} ${classes.cancelIcon}`}
          />
        )}
        <img
          src={getApiUrl(
            `/images/cars/${car.carzillaId}_${String(1).padStart(2, '0')}.jpg`
          )}
          className={classes.sortImage}
          alt="Bild"
          loading="lazy"
        />
      </Grid>
      <Grid xs={12} sm={'auto'}>
        <ListItemText
          primary={car.brand + ' ' + car.model}
          secondary={
            Math.round(car.importantDetails.price).toLocaleString() + '€'
          }
        />
      </Grid>
    </Grid>
  );
}
function SuggestedCarsListView(props) {
  const [cars, setCars] = useState({ docs: [] });
  const [suggestedCars, setSuggestedCars] = useState(props.suggestedCars);
  const { error, getCarsFiltered } = useFetchContent();
  const [carsLoaded, setCarsLoaded] = useState(false);

  useEffect(() => {
    getCarsFiltered({ limit: 1000 }, { brand: [props.brand] }, (d) => {
      if (!error) {
        setCars(d);
        setCarsLoaded(true);
      }
    });
  }, [props.brand]);

  function patchSuggestedCars(data, action) {
    if (action === 'add') {
      props.setSuggestedCars((arr) => {
        return [{ ...data.carDoc, suggestedCarId: data._id }, ...arr];
      });
      setSuggestedCars((arr) => {
        return [{ ...data.carDoc, suggestedCarId: data._id }, ...arr];
      });
    } else if (action === 'delete') {
      props.setSuggestedCars((items) =>
        items.filter((item) => item.suggestedCarId !== data._id)
      );
      setSuggestedCars((items) =>
        items.filter((item) => item.suggestedCarId !== data._id)
      );
    }
  }

  if (!carsLoaded) {
    return (
      <Grid
        xs={12}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '40px 0',
        }}
      >
        <PageLoaderIcon isLoading={true} />
      </Grid>
    );
  } else {
    return (
      <Grid xs={12} container>
        <Grid xs={6}>
          <span className="sectionHeading">Alle</span>
          {cars.docs.map((item, index) => {
            return (
              <SuggestedCarListItem
                key={index}
                car={item}
                patchSuggestedCars={patchSuggestedCars}
                // setSuggestedCars={setSuggestedCars}
                toAdd
              />
            );
          })}
        </Grid>
        <Grid xs={6}>
          <span
            className="sectionHeading"
            style={{
              height: '100%',
              hyphens: 'auto',
              overfowY: 'auto',
            }}
          >
            Vorgeschlagen
          </span>
          {suggestedCars.map((item, index) => {
            return (
              <SuggestedCarListItem
                key={index}
                car={item}
                patchSuggestedCars={patchSuggestedCars}
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

function ManageSuggestedCars(props) {
  const { showModal } = useContext(ModalContext);
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  function showModalHandler() {
    showModal(
      'Vorgeschlagene Autos verwalten',
      null,
      <SuggestedCarsListView
        suggestedCars={props.suggestedCars}
        setSuggestedCars={props.setSuggestedCars}
        brand={brandObject.carzillaBrand}
      />
    );
  }

  return (
    <div className={classes.manageContainer} onClick={showModalHandler}>
      <FontAwesomeIcon icon={faList} className={classes.manageIcon} />
      <span className={classes.manageText}>Verwalten</span>
    </div>
  );
}

function SuggestedCars() {
  const authCtx = useContext(AuthContext);
  const { error, getAllSuggestedCarsByBrand } = useFetchContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);

  const [suggestedCars, setSuggestedCars] = useState([]);

  useEffect(() => {
    getAllSuggestedCarsByBrand(brandObject.carzillaBrand, (d) => {
      if (!error) setSuggestedCars(d);
    });
  }, [brand]);

  function patchSuggestedCars(data, action) {
    if (action === 'delete') {
      const filteredItems = suggestedCars.filter(
        (item) => item.suggestedCarId !== data._id
      );
      setSuggestedCars(filteredItems);
    }
  }
  return (
    <div className={classes.wrapper}>
      <Swiper
        slidesPerView={'auto'}
        // grabCursor={true}
        className={`${classes.swiper} noSelect`}
        modules={[Mousewheel]}
        mousewheel={mousewheelOptions}
        centerInsufficientSlides={true}
      >
        {authCtx.isAdmin && (
          <SwiperSlide className={classes.swiperSlide}>
            <ManageSuggestedCars
              suggestedCars={suggestedCars}
              setSuggestedCars={setSuggestedCars}
            />
          </SwiperSlide>
        )}
        {suggestedCars.map((obj, i) => {
          return (
            <SwiperSlide className={classes.swiperSlide} key={i}>
              <SuggestedCar c={obj} patchSuggestedCars={patchSuggestedCars} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default SuggestedCars;
