import classes from './Autohaus.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';

// IMPORT OWN COMPONENTS
import StaffWidgets from '../About/StaffWidgets';
import Button from '../UI/Button';
import Collapsible from '../UI/Collapsible';
import Jobs from '../About/Jobs';
import NewsWidgets from '../About/NewsWidgets';
import PageHeading from '../UI/PageHeading';
import SingleText from '../UI/SingleText';
import Animation from '../UI/Animation';

// IMPORT MUI AND OTHER COMPONENTS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import '../UI/swiperStyle.css';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import Grid from '@mui/material/Unstable_Grid2';
import { TextField } from '@mui/material/';
import ReactPlayer from 'react-player/lazy';

// IMPORT DATA
import { compData, globals } from '../../data/global-vars';
import { sponsorships, partners } from '../../data/autohaus.js';
import { getApiUrl } from '../../config/api';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { Link, useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import ModalContext from '../../store/modal-context';

// IMPORT IMAGES
import zeitstrahl from '../../assets/company_sources/zeitstrahl.jpg';
import zeitstrahl_skoda from '../../assets/company_sources/zeitstrahl_skoda.jpg';
import staff_image_ars from '../../assets/company_sources/staff_img_ars.png';
import staff_image_aam from '../../assets/company_sources/staff_img_aam.png';
import efre_logo from '../../assets/company_sources/csm_efre.png';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Events from '../About/Events';
import { Helmet } from 'react-helmet';
import { useDepartments } from '../../hooks/departments.js';
import { useOpeningHours } from '../../hooks/openingHours.js';

// for swiper
const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function Autohaus() {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const brandHouse = useBrandFind(brand, 'house');
  const [videoIsLoading, setVideoIsLoading] = useState(true);
  const { showModal } = useContext(ModalContext);

  const { data: departments } = useDepartments(brandObject);
  const { data: item } = useOpeningHours(brandObject);

  function showLoyaltyInfoHandler() {
    showModal(
      <SingleText id="6437164965171cc7c96fcf90" field="title" />,
      null,
      <Animation dir="left">
        <SingleText id="6437164965171cc7c96fcf90" field="text" />
      </Animation>
    );
  }

  const [showWidget, setShowWidget] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWidget(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      <PageHeading title="Über uns">Autohaus</PageHeading>
      <Grid container xs={12} className="container" sx={{ paddingTop: 0 }}>
        <Grid xs={12}>
          <div
            style={{
              position: 'relative',
            }}
          >
            {videoIsLoading && (
              <div
                style={{
                  backgroundColor: 'white',
                  zIndex: 90,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
              >
                <PageLoaderIcon isLoading={videoIsLoading} />
              </div>
            )}
            <div
              style={{
                position: 'relative',
                width: '100%',
                aspectRatio:
                  brandObject.url === globals.BRANDS.SKODA.url
                    ? '1918 / 836'
                    : '1920 / 1080',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <ReactPlayer
                url={
                  brandObject.url === globals.BRANDS.SKODA.url
                    ? getApiUrl('/images/videos/automobile_am_moenchhof.mp4')
                    : getApiUrl('/images/videos/imagefilm_lang.mp4')
                }
                playing={true}
                controls={true}
                loop={true}
                muted={true}
                playsinline={true}
                pip={false}
                light={false}
                width="100%"
                height="100%"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                onReady={() => {
                  setVideoIsLoading(false);
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid container xs={12} columnSpacing={2} sx={{ margin: '40px 0' }}>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6">
              {brandObject.url === globals.BRANDS.SKODA.url ? (
                <Animation dir="right">
                  <h1 className="sectionHeading" id="ueber-uns">
                    <SingleText id="646f41f4d906fa7cc1e9246c" field="title" />
                  </h1>
                  <div className="standardText" style={{ lineHeight: '1.6' }}>
                    <SingleText id="646f41f4d906fa7cc1e9246c" field="text" />
                  </div>
                </Animation>
              ) : (
                <Animation dir="right">
                  <h1 className="sectionHeading" id="ueber-uns">
                    <SingleText id="63cd0463b5887e97e3569e46" field="title" />
                  </h1>
                  <div className="standardText" style={{ lineHeight: '1.6' }}>
                    <SingleText id="63cd0463b5887e97e3569e46" field="text" />
                  </div>
                </Animation>
              )}
            </div>
            <div className="col-span-12 md:col-span-6 flex justify-center">
              <div className="max-w-[450px] text-left p-[20px] rounded-lg shadow-2xl">
                <Animation dir="left">
                  <Grid xs={12} className={classes.compInfoContainerHeading}>
                    {brand === globals.BRANDS.SKODA.url
                      ? compData.AAM.NAME
                      : compData.SEYFARTH.NAME}
                  </Grid>
                  <p className="text-primary text-2xl uppercase font-[300]">
                    Öffnungszeiten:
                  </p>
                  <Grid
                    xs={12}
                    container
                    className={classes.compInfoContainerText}
                    spacing={1}
                  >
                    {item.weekdaysAsString && (
                      <Grid xs={12} container>
                        <Grid xs={'auto'} sm={4}>
                          Montag - Freitag:
                        </Grid>
                        <Grid xs="auto" sm={8}>
                          {item.weekdaysAsString}
                        </Grid>
                      </Grid>
                    )}
                    {item.saturdayAsString && (
                      <Grid xs={12} container>
                        <Grid xs={4} sm={4}>
                          Samstag:
                        </Grid>
                        <Grid xs="auto" sm={8}>
                          {item.saturdayAsString}
                        </Grid>
                      </Grid>
                    )}
                    {item.sundayAsString && (
                      <Grid xs={12} container>
                        <Grid xs={4} sm={4}>
                          Sonntag:
                        </Grid>
                        <Grid xs="auto" sm={8}>
                          {item.sundayAsString}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <p className="mt-4 text-primary text-2xl uppercase font-[300]">
                    Kontaktdaten:
                  </p>
                  <Grid
                    xs={12}
                    container
                    className={classes.compInfoContainerText}
                    spacing={1}
                  >
                    <Grid xs={12} container>
                      <Grid xs={4} sm={4}>
                        Telefon:
                      </Grid>
                      <Grid
                        xs="auto"
                        sm={8}
                        onClick={() => {
                          window.location.href =
                            'tel:+' + brandHouse.TELEFONNUMMER_LINK;
                        }}
                        sx={{
                          cursor: 'pointer',
                          color: 'var(--primary-color)',
                        }}
                      >
                        {brandHouse.TELEFONNUMMER}
                      </Grid>
                    </Grid>
                    <Grid xs={12} container>
                      <Grid xs={4} sm={4}>
                        Fax:
                      </Grid>
                      <Grid xs="auto" sm={8}>
                        {brand === globals.BRANDS.SKODA.url
                          ? compData.AAM.FAX
                          : compData.SEYFARTH.FAX}
                      </Grid>
                    </Grid>
                    <Grid xs={12} container>
                      <Grid xs={4} sm={4}>
                        E-Mail:
                      </Grid>
                      <Grid xs="auto" sm={8}>
                        <a
                          href={'mailto:' + brandHouse.EMAIL}
                          style={{
                            cursor: 'pointer',
                            color: 'var(--primary-color)',
                          }}
                        >
                          {brandHouse.EMAIL}
                        </a>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      container
                      onClick={() => {
                        window.location.href =
                          'tel:+' + brandHouse.NOTFALL_LINK;
                      }}
                      sx={{
                        cursor: 'pointer',
                        color: 'var(--primary-color)',
                      }}
                    >
                      <Grid xs={4} sm={4}>
                        Notdienst:
                      </Grid>
                      <Grid xs="auto" sm={8}>
                        {brandHouse.NOTFALL}
                      </Grid>
                    </Grid>
                  </Grid>
                  <p className="mt-4 text-primary text-2xl uppercase font-[300]">
                    Anfahrt:
                  </p>
                  <Grid
                    xs={12}
                    container
                    className={classes.compInfoContainerText}
                    spacing={1}
                  >
                    <Grid xs={12}>
                      {brand === globals.BRANDS.SKODA.url
                        ? compData.AAM.ADRESSE.KURZFORM
                        : compData.SEYFARTH.ADRESSE.KURZFORM}
                    </Grid>
                    <Grid xs={12}>
                      {brand === globals.BRANDS.SKODA.url
                        ? compData.AAM.ADRESSE.PLZ +
                          ' ' +
                          compData.AAM.ADRESSE.ORT
                        : compData.SEYFARTH.ADRESSE.PLZ +
                          ' ' +
                          compData.SEYFARTH.ADRESSE.ORT}
                    </Grid>
                  </Grid>
                </Animation>
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={12}>
          <Collapsible
            label={
              <h1 className="sectionHeading" id="unser-team">
                Unser Team
              </h1>
            }
            slow
          >
            <Grid
              container
              xs={12}
              spacing={4}
              sx={{
                paddingBottom: '40px',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <div className="grid grid-cols-12 p-4 gap-4">
                {departments?.map((d, i) => {
                  if (d.showMemberCount > 0 && !d.hideOverview) {
                    return (
                      <div
                        className={`col-span-12 ${
                          d.key === 'management' || d.showMemberCount >= 3
                            ? 'lg:col-span-12'
                            : 'lg:col-span-6'
                        } p-4 bg-[var(--light-contrast)] rounded-lg`}
                        key={i}
                      >
                        <h3 className="subheading">{d.title}</h3>
                        <StaffWidgets dep={d.key} all noBackground />
                      </div>
                    );
                  } else return;
                })}
              </div>
            </Grid>
          </Collapsible>
        </Grid>
        <Grid xs={12}>
          <Collapsible
            label={
              <h1 className="sectionHeading" id="unsere-geschichte">
                Unsere Geschichte
              </h1>
            }
          >
            <Animation dir="left">
              <img
                src={
                  brand === globals.BRANDS.SKODA.url
                    ? zeitstrahl_skoda
                    : zeitstrahl
                }
                style={{
                  width: '100%',
                  maxWidth: '800px',
                  borderRadius: '8px',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                alt={'Gruppenfoto mit den Mitarbeitern'}
                loading="lazy"
              />
            </Animation>
          </Collapsible>
        </Grid>
        <Grid xs={12}>
          <Collapsible
            label={
              <h1 className="sectionHeading" id="unsere-geschichte">
                Unsere Auszeichnungen
              </h1>
            }
          >
            <Animation dir="left">
              <img src={efre_logo} style={{ margin: '20px 0' }} />
            </Animation>
            <div style={{ paddingBottom: '20px' }}>
              {showWidget ? (
                <Helmet>
                  <script
                    type="text/javascript"
                    charset="UTF-8"
                    src="https://apps.autohauskenner.de/Widget_Js/5322/27221/Widget.js"
                  ></script>
                </Helmet>
              ) : null}
              <Animation dir="left">
                <div id="kenner-widget"></div>
              </Animation>
            </div>
          </Collapsible>
        </Grid>
        <Grid xs={12}>
          <Animation dir="blurIn">
            <img
              src={
                brand === globals.BRANDS.SKODA.url
                  ? staff_image_aam
                  : staff_image_ars
              }
              style={{
                width: '100%',
                borderRadius: '8px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
              alt={'Gruppenfoto mit den Mitarbeitern'}
              loading="lazy"
            />
          </Animation>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        container
        className="container-contrast"
        sx={{
          paddingLeft: 'calc(20px + env(safe-area-inset-left))',
          paddingRight: 'calc(20px +  env(safe-area-inset-right))',
          // margin: '30px 0',
        }}
      >
        <Grid xs={12} container className="container-contrast-inner">
          <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
            <h1 className="sectionHeading">Wir sind Sponsor:</h1>
            <Swiper
              spaceBetween={50}
              slidesPerView="auto"
              grabCursor={true}
              mousewheel={mousewheelOptions}
              modules={[Mousewheel, Navigation]}
              pagination={{ clickable: true }}
              centerInsufficientSlides={true}
              className={classes.imgSwiper}
            >
              {sponsorships.map((item, i) => (
                <SwiperSlide
                  key={i}
                  className="noSelect"
                  style={{
                    width: 'fit-content',
                  }}
                >
                  <img
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      maxHeight: '150px',
                      maxWidth: '300px',
                      borderRadius: '8px',
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
            <h1 className="sectionHeading" id="unsere-partner">
              Unsere Partner:
            </h1>
            <Swiper
              spaceBetween={50}
              slidesPerView="auto"
              grabCursor={true}
              mousewheel={mousewheelOptions}
              modules={[Mousewheel, Pagination]}
              pagination={{ clickable: true }}
              centerInsufficientSlides={true}
              className={classes.imgSwiper}
            >
              {partners.map((item, i) => (
                <SwiperSlide style={{ width: 'fit-content' }} key={i}>
                  <img
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      maxHeight: '150px',
                      height: '100%',
                      maxWidth: '300px',
                      borderRadius: '8px',
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container className="container" sx={{ margin: '20px 0' }}>
        <div className="w-full" id="aktuelles">
          <NewsWidgets header />
        </div>
      </Grid>
      <Grid
        xs={12}
        className="container-contrast"
        sx={{
          paddingLeft: 'calc(20px + env(safe-area-inset-left))',
          paddingRight: 'calc(20px +  env(safe-area-inset-right))',
          margin: '30px 0',
        }}
        container
        id="karriere"
      >
        <Grid xs={12} container className="container-contrast-inner">
          <h1 className="sectionHeading">Karriere</h1>
          <Grid container xs={12} rowSpacing={2}>
            <Grid xs={12} sm={4}>
              <Link to={`/${brandObject.url}/online-bewerbung`}>
                <div className={classes.carreerOption}>
                  <span className="noSelect">
                    {globals.PAGES.BEWERBUNG.name}
                  </span>
                </div>
              </Link>
            </Grid>
            <Grid xs={12} sm={4} sx={{ cursor: 'pointer' }}>
              <div
                className={classes.carreerOption}
                onClick={() => {
                  showModal(
                    <SingleText id="6419ea68b659cf7a0cd0133d" field="title" />,
                    null,
                    <Animation dir="left">
                      <SingleText id="6419ea68b659cf7a0cd0133d" field="text" />
                    </Animation>
                  );
                }}
              >
                <span className="noSelect">Ausbildung</span>
              </div>
            </Grid>
            <Grid xs={12} sm={4} sx={{ cursor: 'pointer' }}>
              <div
                className={classes.carreerOption}
                onClick={() => {
                  showModal(
                    <SingleText id="6419ea84b659cf7a0cd0133e" field="title" />,
                    null,
                    <Animation dir="left">
                      <SingleText id="6419ea84b659cf7a0cd0133e" field="text" />
                    </Animation>
                  );
                }}
              >
                <span className="noSelect">Praktikum</span>
              </div>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <br />
            <h1 className="subheading">Aktuelle Stellenangebote</h1>
            <Jobs />
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} className="container">
        <Grid xs={12} sm={6} md={6}>
          <form
            action="https://ahseyfarth.loyserv.com/login.html"
            name="loginboxform"
            method="post"
            target="_blank"
            style={{ width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h1 className="sectionHeading" id="bonuskarte">
                Bonuskarte{' '}
              </h1>
              <FontAwesomeIcon
                icon={faQuestion}
                className={classes.loyaltyQuestion}
                onClick={showLoyaltyInfoHandler}
              />
            </div>
            <div className={classes.bonusCardFormWrapper}>
              <TextField
                required
                name="username"
                id="outlined-required1"
                label="Nutzername"
                sx={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
              />
              <TextField
                required
                name="password"
                id="outlined-required2"
                label="Passwort"
                type="password"
                sx={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
              />
              <Button type="submit" maxWidth="200px">
                Einloggen
              </Button>
              <span
                style={{
                  width: '100%',
                  textAlign: 'left',
                  cursor: 'pointer',
                }}
              >
                <a
                  href="https://ahseyfarth.loyserv.com/password.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'var(--text-color)',
                    fontStyle: 'italic',
                    fontSize: '12px',
                  }}
                >
                  Passwort vergessen?
                </a>
              </span>
              <br />
              <br />
            </div>
          </form>
        </Grid>
        <Grid xs={12} sm={6} md={6} sx={{ textAlign: 'right' }}>
          <Events />
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default Autohaus;
