import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import classes from './PriceInfo.module.css';
import { getApiUrl } from '../../config/api';

function PriceInfo(props) {
  let primeColor = props.primeColor;
  const priceFeature = props.priceFeature;
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const [selected, setSelected] = useState(['total', 'financing', 'leasing']);

  useEffect(() => {
    if (props.setSelected) props.setSelected(selected);
  }, [selected]);

  function toggleSelected(i) {
    if (!selected.includes(i)) setSelected((s) => [...s, i]);
    else setSelected((s) => s.filter((v) => v !== i));
  }

  if (!props.primeColor && brandObject) primeColor = brandObject.primeColor;
  else primeColor = props.primeColor;
  if (props.select) {
    return (
      <div>
        <h4>Preise anzeigen</h4>
        <div className={classes.priceInfo}>
          <span
            className={`${classes.priceCategory} ${
              selected.includes('total') && classes.active
            } noSelect`}
            style={{
              color: selected.includes('total') && primeColor,
              cursor: 'pointer',
            }}
            onClick={() => {
              toggleSelected('total');
            }}
          >
            <span>Gesamt</span>
            {/* <span className={classes.small}>
            ab {Math.round(43000).toLocaleString()}€
          </span> */}
          </span>
          <span
            className={`${classes.priceCategory} ${
              selected.includes('financing') && classes.active
            } noSelect`}
            style={{
              color: selected.includes('financing') && primeColor,
              cursor: 'pointer',
            }}
            onClick={() => {
              toggleSelected('financing');
            }}
          >
            <span>monatl.</span>
            {/* <span className={classes.small}>
            ab {Math.round(723).toLocaleString()}€
          </span> */}
          </span>
          {/* <span
          className={`${classes.priceCategory} ${
            selected.includes('leasing') && classes.active
          } noSelect`}
          style={{
            color: selected.includes('leasing') && primeColor,
            cursor: 'pointer',
          }}
          onClick={() => {
            toggleSelected('leasing');
          }}
        >
          <span>Leasing</span>
          <span className={classes.small}>
            ab {Math.round(605).toLocaleString()}€
          </span>
        </span> */}
        </div>
      </div>
    );
  } else
    return (
      <div className={classes.priceInfo}>
        {props.total && (
          <span
            className={`${classes.priceCategory} ${
              priceFeature === 'total' && classes.active
            }`}
            style={{
              color: priceFeature === 'total' && primeColor,
            }}
          >
            <span className={classes.small}>Gesamt:</span>
            <span>{Math.round(props.total).toLocaleString()}€</span>
          </span>
        )}
        {props.financing && (
          <span
            className={`${classes.priceCategory} ${
              priceFeature === 'financing' && classes.active
            }`}
            style={{
              color: priceFeature === 'financing' && primeColor,
            }}
          >
            <span className={classes.small}>monatl.</span>
            <span>{Math.round(props.financing).toLocaleString()}€</span>
          </span>
        )}
        {props.leasing && (
          <span
            className={`${classes.priceCategory} ${
              priceFeature === 'leasing' && classes.active
            }`}
            style={{
              color: priceFeature === 'leasing' && primeColor,
            }}
          >
            <span className={classes.small}>Leasing:</span>
            <span>{Math.round(props.leasing).toLocaleString()}€</span>
          </span>
        )}
        {!props.noAU && props.id && (
          <span className={`${classes.priceCategory} `}>
            <div
              className="au-widget-car-rating"
              data-car-url={getApiUrl('/vw/details/' + props.id)}
              data-layout="tall_smaller"
              data-theme="transparent"
              lazyload="false"
            ></div>
          </span>
        )}
      </div>
    );
}

export default PriceInfo;
