import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PopupContext from '../../store/popup-context';

function DragAndDropFiles(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const [label, setLabel] = useState(
    !props.label ? 'Datei hinzufügen' : props.label
  );

  useEffect(() => {
    if (props.label) setLabel(props.label);
  }, [props.label]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.size > 1024 * 1024) {
          // 1MB in bytes
          setPopupMessage('Datei zu groß. Max 1 Megabyte.', 'error');
          return;
        }
        props.handleChange(file);
        setLabel(file.name);
      }
    },
    [props, setPopupMessage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.types.reduce((acc, type) => {
      acc[`.${type.toLowerCase()}`] = [];
      return acc;
    }, {}),
    maxFiles: 1,
  });

  return (
    <div
      {...getRootProps()}
      className={`noSelect flex flex-row border-lg justify-start items-center !h-[3.5rem] w-full px-3 py-2 border border-gray-400 border-opacity-65 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary hover:border-gray-500 font-light ${
        isDragActive ? 'border-primary bg-gray-50' : ''
      }`}
      style={{ cursor: 'pointer' }}
    >
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faFile} className={'ml-2'} />
      <span className={'ml-3 text-left'}>{label}</span>
      <span className={'mr-3 ml-auto text-[12px] text-right'}>
        {props.types.join(', ')}
      </span>
    </div>
  );
}

export default DragAndDropFiles;
