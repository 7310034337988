import { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import React from 'react';

import './ReactTags.css';

// IMPORT MUI COMPONENTS
import { NumericFormat } from 'react-number-format';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { motion } from 'framer-motion';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';

// IMPORT FONTAWESOME ICONS
import { useHistory, useParams } from 'react-router-dom';
import { globals } from '../../data/global-vars';
import { faArrowRight, faX } from '@fortawesome/free-solid-svg-icons';
import { Skeleton, TextField } from '@mui/material';
import Collapsible from '../UI/Collapsible';
import { useBrandFind } from '../../hooks/useFinders';
import { useCarsPreflight } from '../../hooks/carsPreflight';

const featureSuggestions = [
  { key: 'isDamaged', translation: 'Beschädigt' },
  {
    key: 'automaticClimatisation',
    translation: 'Automatische Klimatisierung',
  },
  { key: 'manualClimatisation', translation: 'Manuelle Klimatisierung' },
  { key: 'taxi', translation: 'Taxi' },
  { key: 'disabledAccessible', translation: 'Behindertengerecht' },
  { key: 'metallic', translation: 'Metallic' },
  { key: 'hasWarranty', translation: 'Mit Garantie' },
  { key: 'alloyWheels', translation: 'Leichtmetallfelgen' },
  { key: 'esp', translation: 'ESP' },
  { key: 'abs', translation: 'ABS' },
  { key: 'trailerCoupling', translation: 'Anhängerkupplung' },
  { key: 'immobilizer', translation: 'Wegfahrsperre' },
  { key: 'navigationSystem', translation: 'Navigationssystem' },
  { key: 'sunroof', translation: 'Schiebedach' },
  { key: 'centralLocking', translation: 'Zentralverriegelung' },
  { key: 'electricWindows', translation: 'Elektrische Fensterheber' },
  { key: 'fourWheelDrive', translation: 'Allradantrieb' },
  { key: 'powerAssistedSteering', translation: 'Servolenkung' },
  { key: 'bioDiesel', translation: 'Biodiesel' },
  { key: 'fullServiceHistory', translation: 'Scheckheftgepflegt' },
  { key: 'cruiseControl', translation: 'Tempomat' },
  { key: 'parkingHeater', translation: 'Standheizung' },
  { key: 'xenonHeadlights', translation: 'Xenon-Scheinwerfer' },
  { key: 'heatedSeats', translation: 'Sitzheizung' },
  { key: 'particleFilter', translation: 'Partikelfilter' },
  { key: 'newHUonBuy', translation: 'Neue HU bei Kauf' },
  { key: 'isExportCar', translation: 'Exportfahrzeug' },
  { key: 'e10Suitable', translation: 'E10-geeignet' },
  { key: 'vegetableOilSuitable', translation: 'Pflanzenöl geeignet' },
  { key: 'electricSideMirror', translation: 'Elektrische Seitenspiegel' },
  { key: 'sportsSuspension', translation: 'Sportfahrwerk' },
  { key: 'bluetooth', translation: 'Bluetooth' },
  { key: 'sportsPackage', translation: 'Sportpaket' },
  { key: 'boardComputer', translation: 'Bordcomputer' },
  { key: 'CDPlayer', translation: 'CD-Player' },
  {
    key: 'electricSeatAdjustment',
    translation: 'Elektrische Sitzverstellung',
  },
  { key: 'headUpDisplay', translation: 'Head-Up Display' },
  { key: 'handsFreeKit', translation: 'Freisprecheinrichtung' },
  { key: 'MP3Interface', translation: 'MP3-Schnittstelle' },
  { key: 'multifunctionSteeringWheel', translation: 'Multifunktionslenkrad' },
  { key: 'skiBag', translation: 'Ski-Bag' },
  { key: 'tunerOrRadio', translation: 'Tuner/Radio' },
  { key: 'sportSeats', translation: 'Sportsitze' },
  { key: 'panoramicRoof', translation: 'Panoramadach' },
  { key: 'isofix', translation: 'Isofix' },
  { key: 'adaptiveCorneringLights', translation: 'Adaptive Kurvenlichter' },
  { key: 'lightSensor', translation: 'Lichtsensor' },
  { key: 'fogLamp', translation: 'Nebelscheinwerfer' },
  { key: 'daytimeRunningLights', translation: 'Tagfahrlicht' },
  { key: 'tractionControl', translation: 'Traktionskontrolle' },
  { key: 'startStopSystem', translation: 'Start-Stopp-System' },
  { key: 'rainSensor', translation: 'Regensensor' },
  { key: 'nonSmokerVehicle', translation: 'Nichtraucherfahrzeug' },
  { key: 'roofRails', translation: 'Dachreling' },
  { key: 'isAccidentDamaged', translation: 'Unfallwagen' },
  { key: 'roadworthy', translation: 'Verkehrssicher' },
  { key: 'parkingSensorsFront', translation: 'Einparkhilfe vorne' },
  { key: 'parkingSensorsRear', translation: 'Einparkhilfe hinten' },
  { key: 'parkingSensorsCamera', translation: 'Einparkhilfe mit Kamera' },
  {
    key: 'parkingSensorsCamera360',
    translation: 'Einparkhilfe mit 360-Grad-Kamera',
  },
  {
    key: 'parkingSensorsSelfSteeringSystem',
    translation: 'Einparkhilfe mit Selbstlenksystem',
  },
  { key: 'plugInHybrid', translation: 'Plug-in-Hybrid' },
  { key: 'headlightWasherSystem', translation: 'Scheinwerfer-Waschanlage' },
  { key: 'highBeamAssist', translation: 'Fernlichtassistent' },
  { key: 'ambientLighting', translation: 'Ambientebeleuchtung' },
  { key: 'heatedRearSeats', translation: 'Beheizbare Rücksitze' },
  {
    key: 'electricBackseatAdjustment',
    translation: 'Elektrische Rücksitzverstellung',
  },
  { key: 'smokersPackage', translation: 'Raucherpaket' },
  { key: 'foldFlatPassengerSeat', translation: 'Beifahrersitz umklappbar' },
  { key: 'digitalCockpit', translation: 'Digitales Cockpit' },
  { key: 'winterPackage', translation: 'Winterpaket' },
  { key: 'androidAuto', translation: 'Android Auto' },
  { key: 'appleCarPlay', translation: 'Apple CarPlay' },
  {
    key: 'inductionSmartphoneCharging',
    translation: 'Induktive Smartphone-Ladefunktion',
  },
  {
    key: 'integratedMusicStreaming',
    translation: 'Integriertes Musik-Streaming',
  },
  { key: 'WiFi', translation: 'WLAN' },
  { key: 'tyresAllSeason', translation: 'Ganzjahresreifen' },
  { key: 'tyresSummer', translation: 'Sommerreifen' },
  { key: 'tyresWinter', translation: 'Winterreifen' },
  { key: 'emergencyTyre', translation: 'Notfallreifen' },
  { key: 'emergencyTyreRepairKit', translation: 'Notfallreifenreparaturset' },
  { key: 'spareTyre', translation: 'Reserverad' },
  { key: 'steelWheels', translation: 'Stahlfelgen' },
  { key: 'eCarWithoutBattery', translation: 'Elektroauto ohne Batterie' },
  {
    key: 'eCarBatteryBought',
    translation: 'Elektroauto mit gekaufter Batterie',
  },
  {
    key: 'eCarBatteryRented',
    translation: 'Elektroauto mit gemieteter Batterie',
  },
  { key: 'rangeExtender', translation: 'Reichweitenverlängerer' },
  { key: 'isofixPassengerSeat', translation: 'Isofix auf Beifahrersitz' },
  { key: 'cargoBarrier', translation: 'Laderaumabtrennung' },
  {
    key: 'trailerCouplingSwiveling',
    translation: 'Schwenkbare Anhängerkupplung',
  },
  {
    key: 'trailerCouplingDetachable',
    translation: 'Abnehmbare Anhängerkupplung',
  },
  { key: 'trailerCouplingFix', translation: 'Feste Anhängerkupplung' },
  { key: 'slidingDoorRight', translation: 'Schiebetür rechts' },
  { key: 'slidingDoorLeft', translation: 'Schiebetür links' },
  { key: 'slidingDoorBothSides', translation: 'Schiebetür beidseitig' },
  { key: 'biXenonHeadlights', translation: 'Bi-Xenon-Scheinwerfer' },
  { key: 'laserHeadlights', translation: 'Laser-Scheinwerfer' },
  { key: 'alarmSystem', translation: 'Alarmanlage' },
  { key: 'heatedSteeringWheel', translation: 'Beheizbares Lenkrad' },
  {
    key: 'automaticallyDimmingInteriorMirror',
    translation: 'Automatisch abblendender Innenspiegel',
  },
  { key: 'laneKeepingAssist', translation: 'Spurhalteassistent' },
  { key: 'adaptiveCruiseControl', translation: 'Adaptiver Tempomat' },
  { key: 'LEDHeadlights', translation: 'LED-Scheinwerfer' },
  { key: 'emergencyBrakeAssist', translation: 'Notbremsassistent' },
  { key: 'distanceWarningSystem', translation: 'Abstandswarnsystem' },
  { key: 'laneChangeAssist', translation: 'Spurwechselassistent' },
  { key: 'seatVentilation', translation: 'Sitzbelüftung' },
  { key: 'armRest', translation: 'Armauflage' },
  { key: 'heatedWindshield', translation: 'Beheizbare Windschutzscheibe' },
  { key: 'hillStartAssist', translation: 'Berganfahrassistent' },
  { key: 'DABRadio', translation: 'DAB-Radio' },
  { key: 'electricTailgate', translation: 'Elektrische Heckklappe' },
  { key: 'LEDRunningLights', translation: 'LED-Tagfahrlicht' },
  { key: 'leatherSteeringWheel', translation: 'Lederlenkrad' },
  { key: 'lumbarSupport', translation: 'Lordosenstütze' },
  { key: 'airSuspension', translation: 'Luftfederung' },
  { key: 'massageSeats', translation: 'Massagefunktion für Sitze' },
  { key: 'fatigueWarningSystem', translation: 'Müdigkeitserkennungssystem' },
  { key: 'nightVisionAssist', translation: 'Nachtsichtassistent' },
  { key: 'emergencyCallSystem', translation: 'Notrufsystem' },
  { key: 'tyrePressureMonitoring', translation: 'Reifendrucküberwachung' },
  { key: 'paddleShifters', translation: 'Schaltwippen' },
  { key: 'slidingDoor', translation: 'Schiebetür' },
  { key: 'soundSystem', translation: 'Soundsystem' },
  { key: 'voiceControl', translation: 'Sprachsteuerung' },
  { key: 'touchscreen', translation: 'Touchscreen' },
  { key: 'usbPort', translation: 'USB-Anschluss' },
  { key: 'trafficSignRecognition', translation: 'Verkehrszeichenerkennung' },
  {
    key: 'keylessCentralLocking',
    translation: 'Keyless-Zentralverriegelung',
  },
  { key: 'clima2Zones', translation: 'Zweizonen-Klimaautomatik' },
  { key: 'clima3Zones', translation: 'Dreizonen-Klimaautomatik' },
  { key: 'clima4Zones', translation: 'Vierzonen-Klimaautomatik' },
  {
    key: 'glareFreeHighBeamHeadlights',
    translation: 'Blendfreies Fernlicht',
  },
  {
    key: 'speedLimitControlSystem',
    translation: 'Geschwindigkeitsregelanlage',
  },
  { key: 'roofRack', translation: 'Dachreling' },
  { key: 'heatedOutsideMirror', translation: 'Beheizbare Außenspiegel' },
  { key: 'privacyGlas', translation: 'Privacy-Verglasung' },
  { key: 'sportExhaustSystem', translation: 'Sportauspuffanlage' },
  { key: 'heatInsulatingGlass', translation: 'Wärmeschutzverglasung' },
];

Array.prototype.sortArray = function () {
  return this.slice().sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};

function ShortFilter(props) {
  const unfiltered = props.unfiltered;
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);

  const [count, setCount] = useState(0);
  const [d, setD] = useState(null);
  const [countLoaded, setCountLoaded] = useState(false);
  // BRANDS
  const [brands, setBrands] = useState(
    unfiltered?.brands || [brandObject.carzillaBrand]
  );
  const [includeOtherBrands, setIncludeOtherBrands] = useState(
    unfiltered?.includeOtherBrands
  );
  const [showOtherBrands, setShowOtherBrands] = useState(false);

  // MODELS
  const [models, setModels] = useState(unfiltered?.models || []);
  const [modelOptions, setModelOptions] = useState([]);

  // COLORS
  const [baseColors, setBaseColors] = useState(unfiltered?.baseColors || []);
  const [colorOptions, setColorOptions] = useState([]);

  // FUELTYPES
  const [fuelTypes, setFuelTypes] = useState(unfiltered?.fuelTypes || []);
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);

  // TRANSMISSION
  const [transmissions, setTransmissions] = useState(
    unfiltered?.transmissions || []
  );
  const [transmissionOptions, setTransmissionOptions] = useState([]);

  // TAGS
  const [features, setFeatures] = useState(unfiltered?.features || []);

  const [priceMin, setPriceMin] = useState(unfiltered?.priceFrom || 0);
  const [priceMax, setPriceMax] = useState(unfiltered?.priceTo || 0);
  const [mileageMin, setMileageMin] = useState(unfiltered?.kmFrom || 0);
  const [mileageMax, setMileageMax] = useState(unfiltered?.kmTo || 0);
  const [yearMin, setYearMin] = useState(unfiltered?.firstRegFrom || 0);
  const [yearMax, setYearMax] = useState(unfiltered?.firstRegTo || 0);

  const history = useHistory();

  useCarsPreflight({
    props,
    brands,
    models,
    baseColors,
    fuelTypes,
    transmissions,
    features,
    unfiltered,
    featureSuggestions,
    priceMin,
    priceMax,
    yearMin,
    yearMax,
    mileageMin,
    mileageMax,
    includeOtherBrands,
    setBrands,
    setD,
    setCount,
    setCountLoaded,
    setModels,
    setBaseColors,
    setFuelTypes,
    setTransmissions,
    setPriceMin,
    setPriceMax,
    setYearMin,
    setYearMax,
    setMileageMin,
    setMileageMax,
    setModelOptions,
    setColorOptions,
    setFuelTypeOptions,
    setTransmissionOptions,
    setShowOtherBrands,
  });

  const handlePriceChange = (event, value) => {
    if (event) {
      if (event.target.name === 'priceMin') {
        setPriceMin(value);
      } else if (event.target.name === 'priceMax') {
        setPriceMax(value);
      }
    }
  };

  const handleMileageChange = (event, value) => {
    if (event) {
      if (event.target.name === 'mileageMin') {
        setMileageMin(value);
      } else if (event.target.name === 'mileageMax') {
        setMileageMax(value);
      }
    }
  };

  const handleYearChange = (event, value) => {
    if (event) {
      if (event.target.name === 'yearMin') {
        setYearMin(value);
      } else if (event.target.name === 'yearMax') {
        setYearMax(value);
      }
    }
  };

  // FIND ON SUBMIT
  function submitHandler() {
    if (props.short && !props.newCars) {
      history.push(
        `/${brandObject.url}/${globals.PAGES.GEBRAUCHTWAGEN.url}/p=1`,
        {
          filter: {
            isNew: props.newCars ? true : false,
            brand: brands,
            model: models,
            baseColor: baseColors,
            fuelType: fuelTypes,
            transmissionType: transmissions,
            tags: features.map(
              (k) =>
                featureSuggestions.find((obj, id) => obj.translation === k).key
            ),
            priceFrom: Number(priceMin),
            priceTo: Number(priceMax),
            firstRegFrom: props.newCars ? null : yearMin + '-01',
            firstRegTo: props.newCars ? null : yearMax + '-12',
            kmFrom: props.newCars ? null : Number(mileageMin),
            kmTo: props.newCars ? null : Number(mileageMax),
            class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
          },
          unfilteredFilter: {
            isNew: props.newCars ? true : false,
            brands,
            models,
            baseColors,
            fuelTypes,
            transmissions,
            features,
            priceFrom: Number(priceMin),
            priceTo: Number(priceMax),
            firstRegFrom: props.newCars ? null : Number(yearMin),
            firstRegTo: props.newCars ? null : Number(yearMax),
            kmFrom: props.newCars ? null : Number(mileageMin),
            kmTo: props.newCars ? null : Number(mileageMax),
            class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
            includeOtherBrands: includeOtherBrands,
          },
        }
      );
    } else {
      props.setFilter({
        isNew: props.newCars ? true : false,
        brand: brands,
        model: models,
        baseColor: baseColors,
        fuelType: fuelTypes,
        transmissionType: transmissions,
        tags: features.map(
          (k) => featureSuggestions.find((obj, id) => obj.translation === k).key
        ),
        priceFrom: Number(priceMin),
        priceTo: Number(priceMax),
        firstRegFrom: props.newCars ? null : yearMin + '-01',
        firstRegTo: props.newCars ? null : yearMax + '-12',
        kmFrom: props.newCars ? null : Number(mileageMin),
        kmTo: props.newCars ? null : Number(mileageMax),
        class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      });
    }
    let filterKey = 'filter';
    if (props.newCars) filterKey = 'newCarFilter';
    else if (props.mobileHome) filterKey = 'motorhomeFilter';
    localStorage.setItem(
      filterKey,
      JSON.stringify({
        filter: {
          isNew: props.newCars ? true : false,
          brand: brands,
          model: models,
          baseColor: baseColors,
          fuelType: fuelTypes,
          transmissionType: transmissions,
          tags: features.map(
            (k) =>
              featureSuggestions.find((obj, id) => obj.translation === k).key
          ),
          priceFrom: Number(priceMin),
          priceTo: Number(priceMax),
          firstRegFrom: props.newCars ? null : yearMin + '-01',
          firstRegTo: props.newCars ? null : yearMax + '-12',
          kmFrom: props.newCars ? null : Number(mileageMin),
          kmTo: props.newCars ? null : Number(mileageMax),
          class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
        },
        unfilteredFilter: {
          isNew: props.newCars ? true : false,
          brands,
          models,
          baseColors,
          fuelTypes,
          transmissions,
          features,
          priceFrom: Number(priceMin),
          priceTo: Number(priceMax),
          firstRegFrom: props.newCars ? null : Number(yearMin),
          firstRegTo: props.newCars ? null : Number(yearMax),
          kmFrom: props.newCars ? null : Number(mileageMin),
          kmTo: props.newCars ? null : Number(mileageMax),
          class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
          includeOtherBrands: includeOtherBrands,
        },
      })
    );
  }

  useEffect(() => {
    if (!history.location.state?.filter && countLoaded) {
      setBrands([brandObject.carzillaBrand]);
      setIncludeOtherBrands(false);
      setModels([]);
      setBaseColors([]);
      setFuelTypes([]);
      setFeatures([]);
      setTransmissions([]);
      if (props.newCars) localStorage.removeItem('newCarFilter');
      else if (props.mobileHome) localStorage.removeItem('motorhomeFilter');
      else localStorage.removeItem('filter');
      if (!props.short) {
        props.setFilter({
          brand: [brandObject.carzillaBrand],
          isNew: props.newCars ? true : false,
        });
      }
    }
  }, [brandObject]);

  function clearHandler() {
    setBrands([brandObject.carzillaBrand]);
    setModels([]);
    setBaseColors([]);
    setFuelTypes([]);
    setFeatures([]);
    setTransmissions([]);
    setIncludeOtherBrands(false);

    localStorage.removeItem('detailId');
    if (props.newCars) localStorage.removeItem('newCarFilter');
    else if (props.mobileHome) localStorage.removeItem('motorhomeFilter');
    else localStorage.removeItem('filter');

    let url;
    if (props.newCars) url = globals.PAGES.NEUWAGEN.url + '/bestand/p=1';
    else if (props.mobileHome) url = globals.PAGES.WOHNMOBILE + '/p=1';
    else url = globals.PAGES.GEBRAUCHTWAGEN.url + '/p=1';

    if (!props.short) {
      history.push(`/${brandObject.url}/${url}/`, {
        filter: {
          brand: [brandObject.carzillaBrand],
        },
        unfilteredFilter: {
          brand: [brandObject.carzillaBrand],
        },
      });
      props.setFilter({
        brand: [brandObject.carzillaBrand],
        isNew: props.newCars ? true : false,
        class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      });
    }
  }

  function toggleBrand(brand) {
    const index = brands.indexOf(brand);
    if (index > -1) {
      const updatedBrands = [...brands];
      updatedBrands.splice(index, 1);
      setBrands(updatedBrands);
    } else {
      setBrands((prevBrands) => [...prevBrands, brand]);
    }
  }

  return (
    <div className="w-full flex justify-center">
      <div
        className={`w-full grid grid-cols-12 gap-y-8 ${
          props.short
            ? 'max-w-[700px] my-[30px] md:my-[60px] xl:my-2'
            : 'max-w-[1200px]'
        }`}
      >
        <div className={`mt-4 col-span-12 flex justify-center`} id="suche">
          <motion.div
            style={{
              whiteSpace: 'nowrap',
              display: 'flex',
              flexDiretion: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              textAlign: 'center',
              maxWidth: '400px',
            }}
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: {
                  delayChildren: 0.5,
                  staggerChildren: 0.1,
                },
              },
            }}
            animate="visible"
            initial="hidden"
          >
            {!countLoaded ? (
              <Skeleton width="100%" height="100px" />
            ) : (
              Object.values(globals.BRANDS).map((item, i) => {
                if (
                  !item.hideInBrandswitch &&
                  (!props.mobileHome ||
                    (props.mobileHome && !item.hideInMotorhome)) &&
                  (!props.newCars || (props.newCars && !item.onlyService))
                ) {
                  return (
                    <motion.div
                      style={{ width: '100%' }}
                      whileTap={{ scale: 0.98 }}
                      variants={{
                        hidden: { opacity: 0 },
                        visible: { opacity: 1 },
                      }}
                      onClick={() => toggleBrand(item.carzillaBrand)}
                      key={i}
                    >
                      <img
                        className={`shadow-none mx-[4px] cursor-pointer inline-flex pb-[10px] border-b-[2px] border-transparent ${
                          item.logoStyle
                        } noSelect ${
                          brands.indexOf(item.carzillaBrand) < 0 && 'grayscale'
                        }`}
                        src={item.sale_logo}
                        alt={item.name + ' Logo'}
                        style={{
                          borderColor:
                            brands.indexOf(item.carzillaBrand) >= 0 &&
                            item.primeColor,
                        }}
                      />
                    </motion.div>
                  );
                }
              })
            )}
            {!props.newCars && showOtherBrands && (
              <motion.div
                whileTap={{ scale: 0.98 }}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                className="noSelect"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
                onClick={() => setIncludeOtherBrands((v) => !v)}
              >
                <span
                  style={{
                    marginBottom: '10px',
                    marginLeft: '20px',
                    fontSize: '15px',
                    borderBottom: '2px solid',
                    borderColor: includeOtherBrands
                      ? 'var(--text-color)'
                      : 'transparent',
                  }}
                >
                  Andere Marken
                </span>
              </motion.div>
            )}
          </motion.div>
        </div>

        <div
          className={`${
            props.short ? 'col-span-12' : 'col-span-12 md:col-span-6'
          }`}
        >
          <div className={`grid grid-cols-12 w-full gap-x-4 gap-y-8 px-2`}>
            <div className="col-span-6">
              <span className="p-float-label">
                <MultiSelect
                  value={[...new Set(models)].filter(
                    (m) => m && modelOptions.some((opt) => opt.value === m) // Nur gültige Werte behalten
                  )}
                  onChange={(e) => {
                    setModels(e.value);
                  }}
                  options={modelOptions}
                  filterInputAutoFocus={false}
                  filter
                  maxSelectedLabels={3}
                  className="text-left w-full md:w-20rem focus:outline-none focus:ring-0 focus:border-transparent !shadow-none"
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label className="px-3">Modelle</label>
              </span>
            </div>
            <div className="col-span-6" xs={12} sm={6}>
              <span className="p-float-label">
                <MultiSelect
                  value={[...new Set(baseColors)].filter(
                    (c) => c && colorOptions.some((opt) => opt.value === c)
                  )}
                  options={colorOptions}
                  onChange={(e) => {
                    setBaseColors(e.value);
                  }}
                  filterInputAutoFocus={false}
                  filter
                  maxSelectedLabels={3}
                  className="text-left w-full md:w-20rem focus:outline-none focus:ring-0 focus:border-transparent !shadow-none"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label className="px-3">Farben</label>
              </span>
            </div>
            <div className="col-span-6" xs={12} sm={6}>
              <span className="p-float-label">
                <MultiSelect
                  value={[...new Set(fuelTypes)].filter(
                    (f) => f && fuelTypeOptions.some((opt) => opt.value === f)
                  )}
                  options={fuelTypeOptions}
                  onChange={(e) => {
                    setFuelTypes(e.value);
                  }}
                  filterInputAutoFocus={false}
                  maxSelectedLabels={3}
                  className="text-left w-full md:w-20rem focus:outline-none focus:ring-0 focus:border-transparent !shadow-none"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label style={{ padding: '0 10px' }}>Kraftstoffart</label>
              </span>
            </div>
            <div className="col-span-6" xs={12} sm={6}>
              <span className="p-float-label">
                <MultiSelect
                  value={[...new Set(transmissions)].filter(
                    (t) =>
                      t && transmissionOptions.some((opt) => opt.value === t)
                  )}
                  options={transmissionOptions}
                  onChange={(e) => {
                    setTransmissions(e.value);
                  }}
                  filterInputAutoFocus={false}
                  maxSelectedLabels={3}
                  className="text-left w-full md:w-20rem focus:outline-none focus:ring-0 focus:border-transparent !shadow-none"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label className="px-3">Getriebe</label>
              </span>
            </div>

            <div className="col-span-12" xs={12} sm={6}>
              <span className="p-float-label">
                <MultiSelect
                  value={[...new Set(features)].filter(
                    (f) =>
                      f && featureSuggestions.some((s) => s.translation === f)
                  )}
                  options={featureSuggestions.map((s) => s.translation).sort()}
                  onChange={(e) => {
                    setFeatures(e.value);
                  }}
                  filterInputAutoFocus={false}
                  maxSelectedLabels={3}
                  className="text-left w-full md:w-20rem focus:outline-none focus:ring-0 focus:border-transparent !shadow-none"
                  style={{
                    width: '100%',
                  }}
                  filter
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label className="px-3">Kriterien hinzufügen</label>
              </span>
            </div>
          </div>
        </div>
        {props.short ? (
          <div className={`col-span-12`}>
            <Collapsible
              label={
                <span className="text-xs">Weitere Kriterien hinzufügen</span>
              }
            >
              <div className="w-full py-6 grid grid-cols-12 gap-x-4 gap-y-8 px-2">
                <div className="col-span-6">
                  <NumericFormat
                    customInput={TextField}
                    className="[&_.MuiOutlinedInput-input]:h-[24px] !w-full"
                    thousandSeparator="."
                    decimalSeparator=","
                    allowedDecimalSeparators={[',']}
                    label="Preis (min)"
                    name="priceMin"
                    value={priceMin}
                    size="small"
                    onValueChange={(v, e) => {
                      handlePriceChange(e.event, v.floatValue);
                    }}
                    decimalScale={2}
                    suffix=" €"
                    inputProps={{
                      min: d?.priceMin || 0,
                      max: d?.priceMax || 0,
                      lang: 'de-DE',
                    }}
                  />
                </div>
                <div className="col-span-6">
                  <NumericFormat
                    customInput={TextField}
                    className="[&_.MuiOutlinedInput-input]:h-[24px] !w-full"
                    thousandSeparator="."
                    decimalSeparator=","
                    allowedDecimalSeparators={[',']}
                    label="Preis (max)"
                    name="priceMax"
                    value={priceMax}
                    size="small"
                    onValueChange={(v, e) => {
                      handlePriceChange(e.event, v.floatValue);
                    }}
                    decimalScale={2}
                    suffix=" €"
                    inputProps={{
                      min: d?.priceMin || 0,
                      max: d?.priceMax || 0,
                      lang: 'de-DE',
                    }}
                  />
                </div>
                {!props.newCars && (
                  <>
                    <div className="col-span-6">
                      <NumericFormat
                        customInput={TextField}
                        className="[&_.MuiOutlinedInput-input]:h-[24px] !w-full"
                        thousandSeparator="."
                        decimalSeparator=","
                        allowedDecimalSeparators={[',']}
                        label="Kilometerzahl (min)"
                        name="mileageMin"
                        value={mileageMin}
                        size="small"
                        onValueChange={(v, e) => {
                          handleMileageChange(e.event, v.floatValue);
                        }}
                        decimalScale={2}
                        suffix=" km"
                        inputProps={{
                          min: d?.mileageMin || 0,
                          max: d?.mileageMax || 0,
                          lang: 'de-DE',
                        }}
                      />
                    </div>
                    <div className="col-span-6">
                      <NumericFormat
                        customInput={TextField}
                        className="[&_.MuiOutlinedInput-input]:h-[24px] !w-full"
                        thousandSeparator="."
                        decimalSeparator=","
                        allowedDecimalSeparators={[',']}
                        label="Kilometerzahl (max)"
                        name="mileageMin"
                        value={mileageMax}
                        size="small"
                        onValueChange={(v, e) => {
                          handleMileageChange(e.event, v.floatValue);
                        }}
                        decimalScale={2}
                        suffix=" km"
                        inputProps={{
                          min: d?.mileageMin || 0,
                          max: d?.mileageMax || 0,
                          lang: 'de-DE',
                        }}
                      />
                    </div>
                    <div className="col-span-6">
                      <NumericFormat
                        customInput={TextField}
                        className="[&_.MuiOutlinedInput-input]:h-[24px] !w-full"
                        thousandSeparator=""
                        allowedDecimalSeparators={['']}
                        label="Erstzulassung (min)"
                        name="yearMin"
                        value={yearMin}
                        size="small"
                        onValueChange={(v, e) => {
                          handleYearChange(e.event, v.floatValue);
                        }}
                        decimalScale={0}
                        inputProps={{
                          min:
                            new Date(d?.firstRegistrationMin).getFullYear() ||
                            new Date().getFullYear(),
                          max:
                            new Date(d?.firstRegistrationMax).getFullYear() ||
                            new Date().getFullYear(),
                          lang: 'de-DE',
                        }}
                      />
                    </div>
                    <div className="col-span-6">
                      <NumericFormat
                        customInput={TextField}
                        className="[&_.MuiOutlinedInput-input]:h-[24px] !w-full"
                        thousandSeparator=""
                        allowedDecimalSeparators={['']}
                        label="Erstzulassung (max)"
                        name="yearMax"
                        value={yearMax}
                        size="small"
                        onValueChange={(v, e) => {
                          handleYearChange(e.event, v.floatValue);
                        }}
                        decimalScale={0}
                        inputProps={{
                          min:
                            new Date(d?.firstRegistrationMin).getFullYear() ||
                            new Date().getFullYear(),
                          max:
                            new Date(d?.firstRegistrationMax).getFullYear() ||
                            new Date().getFullYear(),
                          lang: 'de-DE',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Collapsible>
          </div>
        ) : (
          <div className={`col-span-12 md:col-span-6`}>
            <div className="w-full grid grid-cols-12 gap-x-4 gap-y-8 px-2">
              <div className="col-span-6">
                <NumericFormat
                  customInput={TextField}
                  className="[&_.MuiOutlinedInput-input]:h-[33px] !w-full"
                  thousandSeparator="."
                  decimalSeparator=","
                  allowedDecimalSeparators={[',']}
                  label="Preis (min)"
                  name="priceMin"
                  value={priceMin}
                  size="small"
                  onValueChange={(v, e) => {
                    handlePriceChange(e.event, v.floatValue);
                  }}
                  decimalScale={2}
                  suffix=" €"
                  inputProps={{
                    min: d?.priceMin || 0,
                    max: d?.priceMax || 0,
                    lang: 'de-DE',
                  }}
                />
              </div>
              <div className="col-span-6">
                <NumericFormat
                  customInput={TextField}
                  className="[&_.MuiOutlinedInput-input]:h-[33px] !w-full"
                  thousandSeparator="."
                  decimalSeparator=","
                  allowedDecimalSeparators={[',']}
                  label="Preis (max)"
                  name="priceMax"
                  value={priceMax}
                  size="small"
                  onValueChange={(v, e) => {
                    handlePriceChange(e.event, v.floatValue);
                  }}
                  decimalScale={2}
                  suffix=" €"
                  inputProps={{
                    min: d?.priceMin || 0,
                    max: d?.priceMax || 0,
                    lang: 'de-DE',
                  }}
                />
              </div>
              {!props.newCars && (
                <>
                  <div className="col-span-6">
                    <NumericFormat
                      customInput={TextField}
                      className="[&_.MuiOutlinedInput-input]:h-[33px] !w-full"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowedDecimalSeparators={[',']}
                      label="Kilometerzahl (min)"
                      name="mileageMin"
                      value={mileageMin}
                      size="small"
                      onValueChange={(v, e) => {
                        handleMileageChange(e.event, v.floatValue);
                      }}
                      decimalScale={2}
                      suffix=" km"
                      inputProps={{
                        min: d?.mileageMin || 0,
                        max: d?.mileageMax || 0,
                        lang: 'de-DE',
                      }}
                    />
                  </div>
                  <div className="col-span-6">
                    <NumericFormat
                      customInput={TextField}
                      className="[&_.MuiOutlinedInput-input]:h-[33px] !w-full"
                      thousandSeparator="."
                      decimalSeparator=","
                      allowedDecimalSeparators={[',']}
                      label="Kilometerzahl (max)"
                      name="mileageMin"
                      value={mileageMax}
                      size="small"
                      onValueChange={(v, e) => {
                        handleMileageChange(e.event, v.floatValue);
                      }}
                      decimalScale={2}
                      suffix=" km"
                      inputProps={{
                        min: d?.mileageMin || 0,
                        max: d?.mileageMax || 0,
                        lang: 'de-DE',
                      }}
                    />
                  </div>
                  <div className="col-span-6">
                    <NumericFormat
                      customInput={TextField}
                      className="[&_.MuiOutlinedInput-input]:h-[33px] !w-full"
                      thousandSeparator=""
                      allowedDecimalSeparators={['']}
                      label="Erstzulassung (min)"
                      name="yearMin"
                      value={yearMin}
                      size="small"
                      onValueChange={(v, e) => {
                        handleYearChange(e.event, v.floatValue);
                      }}
                      decimalScale={0}
                      inputProps={{
                        min:
                          new Date(d?.firstRegistrationMin).getFullYear() ||
                          new Date().getFullYear(),
                        max:
                          new Date(d?.firstRegistrationMax).getFullYear() ||
                          new Date().getFullYear(),
                        lang: 'de-DE',
                      }}
                    />
                  </div>
                  <div className="col-span-6">
                    <NumericFormat
                      customInput={TextField}
                      className="[&_.MuiOutlinedInput-input]:h-[33px] !w-full"
                      thousandSeparator=""
                      allowedDecimalSeparators={['']}
                      label="Erstzulassung (max)"
                      name="yearMax"
                      value={yearMax}
                      size="small"
                      onValueChange={(v, e) => {
                        handleYearChange(e.event, v.floatValue);
                      }}
                      decimalScale={0}
                      inputProps={{
                        min:
                          new Date(d?.firstRegistrationMin).getFullYear() ||
                          new Date().getFullYear(),
                        max:
                          new Date(d?.firstRegistrationMax).getFullYear() ||
                          new Date().getFullYear(),
                        lang: 'de-DE',
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className={`col-span-12`}>
          <div className="w-full grid grid-cols-12">
            <div className="col-span-12 md:col-span-6">
              <Button
                icon={faX}
                color="red"
                maxWidth="200px"
                onClick={clearHandler}
                iconPos="pre"
              >
                Auswahl löschen
              </Button>
            </div>
            <div className="col-span-12 md:col-span-6">
              <Button
                icon={faArrowRight}
                maxWidth="200px"
                onClick={submitHandler}
                iconPos="post"
              >
                Suche {countLoaded && '(' + count + ')'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShortFilter;
