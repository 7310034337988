import { useContext, useEffect, useState } from 'react';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import ModalContext from '../../store/modal-context';

import Button from '../UI/Button';
import PopupContext from '../../store/popup-context';
import AuthContext from '../../store/auth-context';
import { MultiSelect } from 'primereact/multiselect';
import useHttp from '../../hooks/use-http';

const UserGroupForm = ({ existingGroup = null, setGroups }) => {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { closeModal } = useContext(ModalContext);
  const { error, sendRequest } = useHttp();

  const [formData, setFormData] = useState({
    name: '',
    assignedUsers: [],
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (existingGroup) {
      setFormData({
        name: existingGroup.name || '',
        assignedUsers: existingGroup.assignedUsers?.map((u) => u._id) || [],
      });
    }
  }, [existingGroup]);

  useEffect(() => {
    sendRequest(
      {
        url: '/api/v1/user/full',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        if (Array.isArray(data)) {
          setUsers(
            data.map((user) => ({
              label: `${user.firstName} ${user.lastName} (${user.email})`,
              value: user._id,
            }))
          );
        }
      }
    );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const groupId = existingGroup ? existingGroup._id : null;

    sendRequest(
      {
        url: groupId ? `/api/v1/user-group/${groupId}` : '/api/v1/user-group',
        method: groupId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
        body: formData,
      },
      (data) => {
        setPopupMessage(
          `Gruppe erfolgreich ${groupId ? 'bearbeitet' : 'erstellt'}!`,
          'success'
        );
        closeModal();
        if (groupId) {
          setGroups((prev) => prev.map((g) => (g._id === groupId ? data : g)));
        } else {
          setGroups((prev) => [data, ...prev]);
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="pb-4">
      <span className="col-span-12 text-sm text-danger">{error}</span>

      {/* Gruppenname */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Gruppenname
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
          required
        />
      </div>

      {/* Zugewiesene Benutzer */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Zugewiesene Benutzer
        </label>
        <MultiSelect
          value={[...new Set(formData.assignedUsers)].filter(
            (u) =>
              u &&
              users.some((user) => user.value === u) &&
              u !== authCtx.userId
          )}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, assignedUsers: e.value }))
          }
          options={users.filter((u) => u.value !== authCtx.userId)}
          filter
          className="w-full"
          display="chip"
          placeholder="Benutzer auswählen"
        />
      </div>

      <div className="flex w-full justify-end">
        <Button color="green" type="submit" maxWidth="200px">
          {loading
            ? 'Speichern...'
            : existingGroup
            ? 'Aktualisieren'
            : 'Erstellen'}
        </Button>
      </div>
    </form>
  );
};

const UserGroupsTable = () => {
  const authCtx = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const { sendRequest } = useHttp();

  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);

  useEffect(() => {
    sendRequest(
      {
        url: '/api/v1/user-group',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        setGroups(data);
      }
    );
  }, []);

  useEffect(() => {
    const lowercasedSearch = searchTerm.toLowerCase();
    setFilteredGroups(
      groups.filter((group) =>
        group.name.toLowerCase().includes(lowercasedSearch)
      )
    );
  }, [searchTerm, groups]);

  const showAddForm = () => {
    showModal(
      'Neue Benutzergruppe erstellen',
      null,
      <UserGroupForm setGroups={setGroups} />
    );
  };

  const deleteGroup = (groupId) => {
    sendRequest(
      {
        url: `/api/v1/user-group/${groupId}`,
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      () => {
        setGroups((prev) => prev.filter((g) => g._id !== groupId));
      }
    );
  };

  return (
    <div className="mt-2 mb-4">
      <div className="grid grid-cols-12 relative overflow-hidden bg-white border-lightgray border-[1px] rounded-lg">
        <div className="border-b py-2 col-span-12 px-2 grid grid-cols-12 gap-2">
          <div className="col-span-6">
            <form className="flex items-center">
              <label className="sr-only">Suchen</label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      rillrole="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      cliprole="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2 outline-none"
                  placeholder="Suchen"
                  required=""
                />
              </div>
            </form>
          </div>
          <div className="col-span-6 flex justify-end gap-x-2">
            <button
              className="col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              onClick={showAddForm}
            >
              + Hinzufügen
            </button>
          </div>
        </div>

        <div className="col-span-12 bg-white rounded-lg overflow-hidden">
          {filteredGroups.map((group) => (
            <div
              key={group._id}
              className="grid grid-cols-12 px-4 py-3 border-b items-center"
            >
              <div className="col-span-12 md:col-span-6 text-left">
                {group.name}
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-row justify-end text-right space-x-2">
                <Button
                  color="blue"
                  maxWidth="150px"
                  onClick={() =>
                    showModal(
                      'Gruppe bearbeiten',
                      null,
                      <UserGroupForm
                        existingGroup={group}
                        setGroups={setGroups}
                      />
                    )
                  }
                >
                  Bearbeiten
                </Button>
                <Button
                  color="red"
                  maxWidth="150px"
                  onClick={() => deleteGroup(group._id)}
                >
                  Löschen
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserGroupsTable;
