import classes from './SingleText.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import useFetchContent from '../../hooks/useFetchContent';
import usePatchContent from '../../hooks/usePatchContent';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';
import dateformat from 'dateformat';
import { getApiUrl } from '../../config/api';

// IMPORT MUI COMPONENTS
import { Switch, TextField, Box } from '@mui/material/';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// CK EDITOR
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

// IMPORT OTHER COMPONENTS
import Typewriter from 'typewriter-effect';
import useHttp from '../../hooks/use-http';
import ModalContext from '../../store/modal-context';
import Button from './Button';

// HTML TEXT PARSER
const parse = require('html-react-parser');

function TextEditor(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const { updateHeight } = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  // declare data vars
  const [value, setValue] = useState(props.value);
  const adminInfo = props.adminInfo;
  const [richText, setRichText] = useState(props.defRich ? true : false);

  const [confirmCancel, setConfirmCancel] = useState(false);

  function updateHandler() {
    props.updateHandler(value);
  }

  const { sendRequest } = useHttp();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('upload', file);
            sendRequest(
              {
                url: '/api/v1/pagetext/inlineimage',
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + authCtx.token,
                },
                body: formData,
                noJSON: true,
              },
              (data) => {
                resolve({
                  default: getApiUrl(data.url),
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <Fragment>
      {confirmCancel && (
        <div style={{ width: '100%' }}>
          <Button
            color="red"
            onClick={() => {
              props.cancelHandler();
              setPopupMessage('Bearbeitungsvorgang abgebrochen.', 'warning');
            }}
          >
            wirklich verwerfen
          </Button>
          <br />
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <div className={classes.editor}>
          {richText ? (
            <CKEditor
              editor={Editor}
              data={value}
              onChange={(e, editor) => {
                const text = editor.getData();
                setValue(text);
                updateHeight();
              }}
              config={{
                // plugins: [ImageResize],
                extraPlugins: [uploadPlugin],
              }}
            />
          ) : (
            <TextField
              className={classes.inheritInputStyle}
              value={value}
              onChange={(v) => setValue(v.target.value)}
              multiline
              fullWidth
            />
          )}
          <span className="adminInfo">{adminInfo}</span>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CheckCircleOutlineIcon
              onClick={updateHandler}
              className={`${classes.textIcon} ${classes.submitIcon}`}
            />
            <HighlightOffIcon
              // onClick={cancelHandler}
              onClick={() => {
                setConfirmCancel((v) => !v);
              }}
              className={`${classes.textIcon} ${classes.cancelIcon} ${
                confirmCancel && classes.inactive
              }`}
            />
          </Box>
          <Switch checked={richText} onChange={() => setRichText((r) => !r)} />
        </Box>
      </Box>
    </Fragment>
  );
}

function UserInfo(props) {
  const { isLoading, error, getSingleUserById } = useFetchContent();
  const [user, setUser] = useState({ username: '', role: '' });
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (authCtx.isAdmin && props.editedBy !== '') {
      getSingleUserById(props.editedBy, (d) =>
        setUser({ username: d.username, role: d.role })
      );
    }
  }, [getSingleUserById, authCtx.isAdmin, props.editedBy]);

  if (!error && !isLoading) {
    return (
      user.username +
      ' (' +
      user.role +
      ') ' +
      ', ' +
      dateformat(new Date(props.editedAt), 'dd.mm.yyyy') +
      ' um ' +
      dateformat(new Date(props.editedAt), 'H:MM:ss') +
      ' Uhr'
    );
  } else return null;
}

function SingleText(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);

  //declare data vars
  const [data, setData] = useState({
    text: '',
    title: '',
    editedBy: '',
    editedAt: new Date(),
  });
  const [edit, setEdit] = useState(false);
  const [field, setField] = useState('text');
  const [value, setValue] = useState('');
  const [defaultValue, setDefaultValue] = useState('');

  // declare hook vars
  const {
    isLoading,
    error,
    getSingleText: fetchSingleText,
  } = useFetchContent();
  const {
    isLoading: isUpdating,
    error: updateError,
    patchSingleText,
  } = usePatchContent();

  // FETCHING
  useEffect(() => {
    fetchSingleText(props.id, (data) => {
      setData(data);
      return;
    });
  }, [fetchSingleText, props.id]);

  // declare vars according to field
  useEffect(() => {
    switch (props.field) {
      case 'text':
        setField('text');
        setValue(data.text);
        setDefaultValue(data.text);
        break;
      case 'title':
        setField('title');
        setValue(data.title);
        setDefaultValue(data.title);
        break;

      default:
        setField('text');
        setValue('');
        setDefaultValue('');
        break;
    }
  }, [data, props.field]);

  // UPDATING
  function updateHandler(v) {
    // check if value has changed
    if (v !== defaultValue) {
      patchSingleText(props.id, { field: field, value: v }, (d) => {
        if (!updateError) {
          setEdit(false);
          setData(d);
          setPopupMessage('Text bearbeitet.', 'success');
        }
      });
    } else {
      setEdit(false);
      setPopupMessage('Keine Änderungen vorgenommen.', 'warning');
    }
  }

  function cancelHandler() {
    setEdit(false); // popup message is handled by TextEditor
  }

  useEffect(() => {
    if (isUpdating) setPopupMessage('Hochladen...', 'info');
  }, [isUpdating, setPopupMessage]);

  useEffect(() => {
    if (updateError) setPopupMessage('Fehler beim Hochladen!', 'error');
  }, [updateError, setPopupMessage]);

  // const typewriterOptions = props.typewriterOptions;

  // const typewriter = Object.assign(
  //   {},
  //   { strings: [parse(value)] }
  //   // props.typewriterOptions !== {} && props.typewriterOptions
  // );

  if (error) {
    return 'Fehler beim Laden des Texts.';
  } else if (isLoading) {
    return '...';
  } else {
    return (
      <div>
        {authCtx.isLoggedIn && edit ? (
          <TextEditor
            value={value}
            updateHandler={updateHandler}
            cancelHandler={cancelHandler}
            adminInfo={
              <UserInfo editedBy={data.editedBy} editedAt={data.editedAt} />
            }
            defRich={field === 'text' ? true : false}
          />
        ) : (
          <span
            onClick={(e) =>
              e.detail === 2 &&
              authCtx.isAdmin &&
              !props.noEdit &&
              setEdit(true)
            }
          >
            {props.typewriterOptions ? (
              <Typewriter
                options={{
                  ...props.typewriterOptions,
                  strings: value,
                }}
              />
            ) : (
              parse(
                value.replace(
                  /<a\s+([^>]*)>/g,
                  '<a $1 target="_blank" rel="noopener noreferrer">'
                )
              )
            )}
          </span>
        )}
      </div>
    );
  }
}

export default SingleText;
