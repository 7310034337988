import { useContext, useEffect, useState } from 'react';
import classes from './MultiText.module.css';

// IMPORT MUI COMPONENTS
import {
  Box,
  TextField,
  styled,
  Switch,
  ListItemText,
  ListItemAvatar,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { Edit, HighlightOff } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import useFetchContent from '../../hooks/useFetchContent';
import usePatchContent from '../../hooks/usePatchContent';
import useKeyPress from '../../hooks/useKeyPress';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';
import dateformat from 'dateformat';

// CK EDITOR
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

// IMPORT OWN COMPONENTS
import Button from './Button';
import useDeleteContent from '../../hooks/useDeleteContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleMinus,
  faList,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import useHttp from '../../hooks/use-http';
import DraggableList, { DraggableListItem } from './DraggableList';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import { getApiUrl } from '../../config/api';

// HTML PARSER
const parse = require('html-react-parser');

// STYLE MUI COMPONENTS
const StyledTextField = styled(TextField)({
  width: '100%',
});

function MultiTextWrapper(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);

  // hooks for fetching
  const { error, getMultiText } = useFetchContent();

  // fetching
  useEffect(() => {
    getMultiText(props.id, (d) => {
      if (!error) {
        props.applyData(d);
        if (props.setLoaded) props.setLoaded(true);
      } else setPopupMessage('Fehler beim Laden!', 'error');
    });
  }, []);

  // useEffect(() => {
  //   props.applyData(data);
  // }, [data, props]);

  // function finishedEditing() {
  //   props.setEdit(false);
  // }
  if (authCtx.isAdmin) {
    return (
      <span
        onClick={(e) => {
          e.detail === 2 && authCtx.isAdmin && props.setEdit(true);
        }}
        // ref={ref}
      >
        {props.children}
      </span>
    );
  } else return props.children;
}

function MultiTextSortList(props) {
  // declare data vars
  const { setPopupMessage } = useContext(PopupContext);
  const [items, setItems] = useState(props.value.texts);

  const { error, sortMultiTextEntries } = usePatchContent();

  // sortItems
  function sortItems(arr, droppableIndex, draggableIndex) {
    setItems((items) => {
      if (droppableIndex === draggableIndex) {
        setPopupMessage('Keine Änderung.', 'warning');
        return items;
      } else {
        sortMultiTextEntries(
          props.value._id,
          arr[droppableIndex]._id,
          droppableIndex === 0 ? 0 : arr[droppableIndex - 1]._id,
          (d) => {
            if (error) {
              setPopupMessage('Fehler bei der Sortierung.', 'error');
            }
          }
        );
        props.sortMultiHandler({
          ...props.value,
          texts: arr,
        });
        return arr;
      }
    });
  }

  return (
    <Grid xs={12} container sx={{ padding: '20px' }}>
      <Grid xs={12}>
        <DraggableList items={items} setItems={sortItems}>
          {items.map((item, index) => {
            return (
              <DraggableListItem
                item={item}
                index={index}
                key={index.toString()}
                id={index.toString()}
              >
                <ListItemAvatar
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <FontAwesomeIcon icon={faFile} />
                </ListItemAvatar>
                <ListItemText primary={item.title} />
              </DraggableListItem>
            );
          })}
        </DraggableList>
      </Grid>
    </Grid>
  );
}

function MultiTextController(props) {
  // is only shown if is admin
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal } = useContext(ModalContext);

  function sortMultiHandler() {
    showModal(
      'Sortieren',
      null,
      <MultiTextSortList
        sortMultiHandler={props.sortMultiHandler}
        value={props.value}
      />
    );
  }

  if (props.edit && authCtx.isAdmin) {
    // only show if is admin and edit mode
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <HighlightOff
          style={{ cursor: 'pointer' }}
          onMouseDown={(e) => {
            e.preventDefault();
            props.setValue(props.value);
            props.setEdit(false);
            setPopupMessage('Bearbeitungsvorgang abgebrochen.', 'warning');
          }}
        />
        <FontAwesomeIcon
          icon={faList}
          style={{ margin: '0 10px', cursor: 'pointer' }}
          onClick={sortMultiHandler}
        />
        <Switch
          checked={props.richText}
          onChange={() => props.setRichText((r) => !r)}
        />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Edit onMouseDown={() => props.setEdit(true)} />
        <FontAwesomeIcon
          icon={faList}
          style={{ margin: '0 10px' }}
          onClick={sortMultiHandler}
        />
      </Box>
    );
  }
}

function MultiTextHeader(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  // declare data vars
  const [value, setValue] = useState(props.value.title || '');

  // useEffect(() => {
  //   if (props.value.title !== value) setValue(props.value.title);
  // }, [props.value]);

  // hooks
  const { error, patchMultiText } = usePatchContent();

  function sortMultiHandler(d) {
    props.setValue(d);
    setPopupMessage('Texte neu sortiert.', 'success');
  }

  function updateHandler() {
    if (value !== props.value.title) {
      patchMultiText(props.value._id, { field: 'title', value: value }, (d) => {
        if (!error) {
          props.setValue(d);
          setPopupMessage('Text bearbeitet.', 'success');
        }
      });
    }
  }

  useEffect(() => {
    if (error) setPopupMessage('Fehler beim Hochladen.', 'error');
  }, [error, setPopupMessage]);

  return (
    <span style={{ display: 'flex' }}>
      {authCtx.isAdmin && (
        <span style={{ marginRight: '10px' }}>
          <MultiTextController
            edit={props.edit}
            value={props.value}
            setEdit={props.setEdit}
            setValue={props.setValue}
            richText={props.richText}
            setRichText={props.setRichText}
            sortMultiHandler={sortMultiHandler}
          />
        </span>
      )}
      <span
        onClick={(e) => {
          e.detail === 2 && authCtx.isAdmin && props.setEdit(true);
          e.stopPropagation();
        }}
        style={{ width: '100%' }}
      >
        {props.edit && authCtx.isAdmin ? (
          <StyledTextField
            value={value}
            variant="outlined"
            onChange={(v) => setValue(v.target.value)}
            onBlur={updateHandler}
            sx={{ width: '100%' }}
            fullWidth
          />
        ) : (
          parse(
            value
              ? value.replace(
                  /<a\s+([^>]*)>/g,
                  '<a $1 target="_blank" rel="noopener noreferrer">'
                )
              : ''
          )
        )}
      </span>
    </span>
  );
}

function MultiTextTitle(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);

  // declare data vars
  let fieldValue = '';
  if (!props.new)
    fieldValue = props.subtitle ? props.value.subtitle : props.value.title;
  const [value, setValue] = useState(fieldValue);

  // hooks
  const { error, patchMultiTextEntry } = usePatchContent();

  function updateHandler() {
    if (props.new) {
      props.setValue(value);
    } else if (value !== fieldValue) {
      patchMultiTextEntry(
        props.id,
        props.value._id,
        { field: props.subtitle ? 'subtitle' : 'title', value: value },
        (d) => {
          if (!error) {
            props.setValue(d);
            setPopupMessage('Text bearbeitet.', 'success');
          }
        }
      );
    }
  }

  useEffect(() => {
    if (error) setPopupMessage('Fehler beim Hochladen.', 'error');
  }, [error, setPopupMessage]);

  if (props.edit && authCtx.isAdmin) {
    return (
      <StyledTextField
        value={value}
        variant="outlined"
        onChange={(v) => {
          setValue(v.target.value);
        }}
        multiline
        fullWidth
        label={props.subtitle ? 'Subtitel' : 'Titel'}
        onBlur={updateHandler}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    );
  } else {
    return parse(
      fieldValue
        ? fieldValue.replace(
            /<a\s+([^>]*)>/g,
            '<a $1 target="_blank" rel="noopener noreferrer">'
          )
        : ''
    );
  }
}

function MultiTextUrl(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);

  // declare data vars
  const [value, setValue] = useState(props.new ? props.value : props.value.url);

  // hooks
  const { error, patchMultiTextEntry } = usePatchContent();

  function updateHandler() {
    if (props.new) {
      props.setValue(value);
    } else if (value !== props.value.url) {
      patchMultiTextEntry(
        props.id,
        props.value._id,
        { field: 'url', value: value },
        (d) => {
          if (!error) {
            props.setValue(d);
            setPopupMessage('Text bearbeitet.', 'success');
          }
        }
      );
    }
  }

  useEffect(() => {
    if (error) setPopupMessage('Fehler beim Hochladen.', 'error');
  }, [error, setPopupMessage]);

  if (props.edit && authCtx.isAdmin) {
    return (
      <StyledTextField
        label="Url"
        value={value}
        variant="outlined"
        onChange={(v) => {
          setValue(v.target.value);
        }}
        fullWidth
        onBlur={updateHandler}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    );
  } else {
    return parse(
      value
        ? value.replace(
            /<a\s+([^>]*)>/g,
            '<a $1 target="_blank" rel="noopener noreferrer">'
          )
        : ''
    );
  }
}

function MultiTextStaff(props) {
  const params = useParams();
  const brandObject = useBrandFind(params.brand);
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);

  const [departments, setDepartments] = useState([]);
  const { getAllDepartments } = useFetchContent();
  useEffect(() => {
    getAllDepartments((d) => {
      setDepartments(
        d.filter((obj) => obj.hideOverview && obj.brand === brandObject.make)
      );
    });
  }, []);

  // declare data vars
  const valuePrev = props.value.departmentKey || ``;
  const [value, setValue] = useState(props.new ? [] : valuePrev);

  // hooks
  const { error, patchMultiTextEntry } = usePatchContent();

  function updateHandler() {
    if (props.new) {
      props.setValue(value);
    } else if (value !== props.value.departmentKey) {
      patchMultiTextEntry(
        props.id,
        props.value._id,
        { field: 'departmentKey', value: value },
        (d) => {
          if (!error) {
            props.setValue(d);
            setPopupMessage('Text bearbeitet.', 'success');
          }
        }
      );
    }
  }

  useEffect(() => {
    if (error) setPopupMessage('Fehler beim Hochladen.', 'error');
  }, [error, setPopupMessage]);

  if (props.edit && authCtx.isAdmin) {
    return (
      <FormControl fullWidth>
        <InputLabel>Abteilung</InputLabel>
        <Select
          name="departmentKey"
          label="Abteilung"
          value={value}
          sx={{ textAlign: 'left' }}
          onChange={(v) => {
            setValue(v.target.value);
          }}
          onBlur={updateHandler}
          fullWidth
        >
          <MenuItem value="">
            <i>keine</i>
          </MenuItem>
          {departments.map((d, i) => {
            return <MenuItem value={d.key}>{d.title}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  }
}

function MultiTextEntry(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { updateHeight } = useContext(ModalContext);

  // delcare data vars
  const [value, setValue] = useState(props.new ? '' : props.value.text);

  // hooks
  const { error, patchMultiTextEntry } = usePatchContent();
  const enterSubmit = useKeyPress('Enter');
  const shiftHeld = useKeyPress('Shift');

  // set value if text or edit mode changes
  // useEffect(() => {
  //   setValue(props.new ? '' : props.value.text);
  // }, [props.value, props.edit, props.new]);

  function updateHandler() {
    if (props.new) {
      props.setValue(value);
    } else {
      patchMultiTextEntry(
        props.id,
        props.value._id,
        { field: 'text', value: value },
        (d) => {
          if (!error) {
            props.setValue(d);
            setPopupMessage('Text bearbeitet.', 'success');
          }
        }
      );
    }
  }

  // useEffect(() => {
  //   if (isLoading) setPopupMessage('Hochladen...', 'info');
  // }, [isLoading, setPopupMessage]);

  useEffect(() => {
    if (error) setPopupMessage('Fehler beim Hochladen.', 'error');
  }, [error, setPopupMessage]);

  // // submit on enter
  // useEffect(() => {
  //   if (enterSubmit && props.edit && !shiftHeld) {
  //     props.setEdit(false);
  //   }
  // }, [enterSubmit, props, shiftHeld]);

  const { sendRequest } = useHttp();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('upload', file);
            sendRequest(
              {
                url: '/api/v1/multipagetext/inlineimage',
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + authCtx.token,
                },
                body: formData,
                noJSON: true,
              },
              (data) => {
                resolve({
                  default: getApiUrl(data.url),
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  if (props.edit && authCtx.isAdmin) {
    return (
      <>
        {props.richText ? (
          <CKEditor
            editor={Editor}
            data={value}
            onChange={(e, editor) => {
              const text = editor.getData();
              if ((enterSubmit && shiftHeld) || !enterSubmit) {
                setValue(text);
              }
              updateHeight();
            }}
            config={{
              // plugins: [ImageResize],
              extraPlugins: [uploadPlugin],
            }}
            onBlur={updateHandler}
          />
        ) : (
          <StyledTextField
            value={value}
            // variant="standard"
            label="Text"
            onChange={(v) => {
              if ((enterSubmit && shiftHeld) || !enterSubmit)
                setValue(v.target.value);
            }}
            multiline
            fullWidth
            style={{
              marginTop: '5px',
              marginBottom: '5px',
            }}
            onBlur={updateHandler}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </>
    );
  } else {
    return parse(
      props.value.text
        ? props.value.text.replace(
            /<a\s+([^>]*)>/g,
            '<a $1 target="_blank" rel="noopener noreferrer">'
          )
        : ''
    );
  }
}

function MultiTextAdminInfo(props) {
  const authCtx = useContext(AuthContext);

  // declare data vars
  const [user, setUser] = useState({ username: '', role: '' });
  const [date, setDate] = useState(new Date());

  // hooks
  const { error, getSingleUserById } = useFetchContent();

  // fetch user id
  useEffect(() => {
    if (
      authCtx.isAdmin &&
      props.value.editedBy !== undefined &&
      props.value.editedBy !== ''
    ) {
      getSingleUserById(props.value.editedBy, (d) =>
        setUser({ username: d.username, role: d.role })
      );
    }
    setDate(props.value.editedAt);
  }, [props.value, authCtx.isAdmin, getSingleUserById]);

  if (authCtx.isAdmin && !error)
    return (
      <span className="adminInfo" style={{ float: 'left' }}>
        {user.username + ' (' + user.role + ')'}
        {props.value.editedAt !== '' &&
          ', ' +
            dateformat(date, 'dd.mm.yyyy') +
            ' um ' +
            dateformat(date, 'H:MM:ss') +
            ' Uhr'}
      </span>
    );
}

function MultiTextDeleteIcon(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error, deleteMultiTextEntry } = useDeleteContent();
  function deleteHandler() {
    showModal(
      'Wirklich löschen?',
      null,
      <div style={{ padding: '20px 0' }}>
        <Button
          color="red"
          maxWidth="200px"
          onClick={() => {
            deleteMultiTextEntry(props.id, props.value._id, (d) => {
              if (!error) {
                props.setValue(d);
                setPopupMessage('Erfolgreich gelöscht!', 'success');
              } else {
                setPopupMessage('Es gab einen Fehler beim Löschen!', 'error');
              }
              closeModal();
            });
          }}
        >
          Wirklich löschen
        </Button>
      </div>
    );
    return;
  }
  if (props.edit)
    return (
      <FontAwesomeIcon
        icon={faCircleMinus}
        onClick={(e) => {
          e.stopPropagation();
          deleteHandler();
        }}
        className={`${classes.textIcon} ${classes.cancelIcon}`}
      />
    );
}

function MultiTextSubmitIcon(props) {
  return (
    <CheckCircleOutlineIcon
      onClick={(e) => {
        props.submit();
        e.stopPropagation();
      }}
      className={`${classes.textIcon} ${classes.submitIcon}`}
    />
  );
}

export {
  MultiTextWrapper,
  MultiTextController,
  MultiTextHeader,
  MultiTextTitle,
  MultiTextEntry,
  MultiTextAdminInfo,
  MultiTextDeleteIcon,
  MultiTextSubmitIcon,
  MultiTextUrl,
  MultiTextStaff,
};
