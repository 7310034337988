import { useContext, useEffect, useState } from 'react';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import ModalContext from '../../store/modal-context';

// IMPORT MUI COMPONENTS
import {
  faCancel,
  faCirclePlus,
  faEye,
  faFile,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';
import usePostContent from '../../hooks/usePostContent';
import usePatchContent from '../../hooks/usePatchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import PopupContext from '../../store/popup-context';
import useDeleteContent from '../../hooks/useDeleteContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globals } from '../../data/global-vars';

// error message for adding a new employee
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

export function DepartmentForm(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const { isLoading, error, postDepartment } = usePostContent();
  const { error: updateError, patchDepartment } = usePatchContent();
  const { closeModal } = useContext(ModalContext);
  const s = props.selected;

  const [add, setAdd] = useState(s.edit ? false : true);
  const [key, setKey] = useState(s.key ? s.key : '');
  const [title, setTitle] = useState(s.title ? s.title : '');
  const [brand, setBrand] = useState(
    s.brandKey ? s.brandKey : globals.BRANDS.VW.make
  );

  useEffect(() => {
    setKey(s.key ? s.key : '');
    setTitle(s.title ? s.title : '');
    setBrand(s.brandKey ? s.brandKey : globals.BRANDS.VW.make);
    setAdd(s.edit ? false : true);
  }, [s]);

  function handleSubmit(e) {
    e.preventDefault();
    const department = {
      key,
      title,
      brand,
    };

    if (add) {
      postDepartment(department, (d) => {
        if (!error) {
          props.patchDepartments(d, 'add');
          closeModal();
        }
      });
    } else {
      patchDepartment(s._id, department, (d) => {
        if (!updateError) {
          props.patchDepartments(d, 'edit');
          setPopupMessage(
            'Abteilung: "' + department.title + '" bearbeitet.',
            'success'
          );
          closeModal();
        }
      });
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  } else
    return (
      <form
        className="w-full mb-4 grid grid-cols-12 gap-2"
        onSubmit={handleSubmit}
      >
        {(error || (updateError && errorMessage)) && (
          <p className="col-span-12 text-red-600 text-sm">{errorMessage}</p>
        )}

        {/* Abteilungsbezeichnung */}
        <div className="col-span-12">
          <label className="block text-sm font-medium text-gray-700">
            Abteilungsbezeichnung
          </label>
          <input
            type="text"
            className="mt-1 p-2 w-full border rounded-md"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        {/* Key */}
        <div className="col-span-12">
          <label className="block text-sm font-medium text-gray-700">
            einmaliger Key
          </label>
          <input
            type="text"
            className="mt-1 p-2 w-full border rounded-md"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            required
            disabled={!add}
          />
        </div>

        {/* Marke (Select) */}
        <div className="col-span-12">
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Marke
          </label>
          <select
            name="selectedBrand"
            className="mt-1 p-2 w-full border rounded-md"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          >
            {Object.entries(globals.BRANDS).map(([key, value]) => (
              <option key={value.make} value={value.make}>
                {value.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-12 flex justify-end">
          <Button
            color="green"
            icon={add ? faCirclePlus : faPencil}
            type="submit"
            disabled={key === '' && title === '' ? true : false}
            maxWidth="200px"
          >
            {add ? 'Hinzufügen' : 'Aktualisieren'}
          </Button>
        </div>
      </form>
    );
}

function DepartmentLine(props) {
  const e = props.data;
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal } = useContext(ModalContext);
  const { error: deleteError, deleteDepartment } = useDeleteContent();
  const { patchDepartment } = usePatchContent();

  function deleteDepartmentHandler() {
    const confirmed = window.confirm(
      `Möchten Sie die Abteilung "${e.title}" wirklich löschen?`
    );

    if (!confirmed) return;

    deleteDepartment(e._id, (d) => {
      if (!deleteError) {
        props.patchDepartments(d, 'delete');
        setPopupMessage(
          `Abteilung "${e.title}" erfolgreich gelöscht`,
          'success'
        );
      } else {
        setPopupMessage('Fehler beim Löschen der Abteilung!', 'error');
      }
    });
  }
  function toggleHideOverviewHandler() {
    patchDepartment(e._id, { hideOverview: !e.hideOverview }, (d) => {
      props.patchDepartments(d, 'edit');
      setPopupMessage(
        `Abteilung: ${d.title} (${d.brand}) ${
          d.hideOverview ? 'ausgeblendet' : 'eingeblendet'
        }.`,
        'success'
      );
    });
  }

  return (
    <div
      xs={12}
      className="w-full bg-[var(--light-contrast)] border-[1px] border-lightgray rounded-lg p-2 flex flex-row gap-2 relative items-center"
    >
      <span
        className="text-[var(--text-color)] text-lg text-left cursor-pointer"
        onClick={toggleHideOverviewHandler}
      >
        <FontAwesomeIcon
          icon={e.hideOverview ? faFile : faEye}
          title={e.hideOverview ? 'Einblenden' : 'Ausblenden'}
          className={`mr-2 w-[20px] ${
            e.hideOverview ? 'text-[var(--text-color)]' : `text-primary`
          }`}
        />
        {e.title}
      </span>
      <div className="mr-0 ml-auto flex gap-2">
        <FontAwesomeIcon
          icon={faPencil}
          className="manageColor"
          onClick={() => {
            showModal(
              'Abteilung bearbeiten',
              null,
              <DepartmentForm
                selected={{
                  ...e,
                  edit: true,
                }}
                patchDepartments={props.patchDepartments}
              />
            );
          }}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className="cancelColor"
          onClick={deleteDepartmentHandler}
        />
      </div>
    </div>
  );
}

export function DepartmentsList(props) {
  const { showModal } = useContext(ModalContext);
  const showAddForm = () => {
    showModal(
      'Mitarbeiter hinzufügen',
      null,
      <DepartmentForm selected={{}} patchDepartments={props.patchDepartments} />
    );
  };
  const departments = [
    ...props.departments.map((e) => {
      return e.departments.map((d) => {
        return {
          ...d,
          brandTitle: e.brandTitle,
          brandKey: e.brandKey,
        };
      });
    }),
  ].flat();
  return (
    <div className="grid grid-cols-12 relative overflow-hidden bg-white border-lightgray border-[1px] rounded-lg gap-4">
      <div className="col-span-12 px-2 py-4 grid grid-cols-12 gap-2 border-b border-b-lightgray">
        <div className="order-2 md:order-1 col-span-12 md:col-span-6"></div>
        <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex justify-end gap-x-2">
          <button
            className="col-span-4 md:col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
            onClick={showAddForm}
          >
            + Hinzufügen
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 col-span-12 gap-4 p-4 max-h-[600px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 border-b border-b-lightgray">
        {props.departments.map((e, i) => {
          return (
            <div
              key={e._id || i}
              className="col-span-12 grid grid-cols-12 gap-2"
            >
              <h3 className="col-span-12 sectionHeading">{e.brandTitle}</h3>
              {e.departments.map((d, ii) => {
                return (
                  <div
                    className="col-span-12 md:col-span-6 lg:col-span-4"
                    key={d._id || ii}
                  >
                    <DepartmentLine
                      data={d}
                      setSelected={props.setSelected}
                      selected={props.selected}
                      patchDepartments={props.patchDepartments}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="col-span-12 flex items-center justify-between bg-white px-4 py-3 sm:px-6">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              <span className="font-medium">{departments.length + ' '}</span>
              Abteilungen
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentsList;
