import { useCallback, useContext, useEffect } from 'react';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { useHistory, useParams } from 'react-router-dom';
import SuggestedCars from '../Cars/SuggestedCars';
import { Box, Fab, Zoom, useMediaQuery, useScrollTrigger } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import CompareContext from '../../store/compare-context';
import { globals } from '../../data/global-vars';
import Gebrauchtwagenbestand from './Gebrauchtwagenbestand';

function Gebrauchtwagen() {
  const params = useParams();
  const brand = params.brand;
  const history = useHistory();
  const compareCtx = useContext(CompareContext);
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    if (brand === globals.BRANDS.VW_NFZ.url)
      history.push(
        `/${globals.BRANDS.VW.url}/${globals.PAGES.GEBRAUCHTWAGEN.url}`
      );
  });

  // trigger for scroll up button
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.removeItem('detailId');
    history.push(`/${brand}/${globals.PAGES.GEBRAUCHTWAGEN.url}/p=${1}`);
  }, [brand, history]);

  return (
    <>
      <PageHeading title="Gebrauchtwagen">Gebrauchtwagen</PageHeading>
      <div className="container">
        <Gebrauchtwagenbestand />
        <Grid xs={12} sx={{ paddingBottom: '40px' }}>
          <SuggestedCars />
        </Grid>
      </div>
      <Zoom in={trigger}>
        <Box
          role="presentation"
          sx={{
            position: 'fixed',
            bottom: compareCtx.cars.length > 0 && !isMobile ? 84 : 32,
            right: 32,
            zIndex: 1,
          }}
        >
          <Fab
            onClick={scrollToTop}
            color="primary"
            size="small"
            aria-label="scroll back to top"
            sx={{ borderRadius: '8px' }}
          >
            <KeyboardArrowUp />
          </Fab>
        </Box>
      </Zoom>
    </>
  );
}

export default Gebrauchtwagen;
