import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import useHttp from './use-http';
import AuthContext from '../store/auth-context';

export const useInternalNews = (onSuccessCallback) => {
  const { sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);

  return useQuery({
    queryKey: ['internalNews'],
    queryFn: () =>
      new Promise((resolve, reject) => {
        sendRequest(
          {
            url: '/api/v1/internalnews',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            if (data) {
              if (onSuccessCallback) {
                onSuccessCallback(data);
              }
              resolve(data);
            } else {
              reject(new Error('Fehler beim Laden der internen News.'));
            }
          }
        );
      }),
    enabled: !!authCtx?.token,
    initialData: [],
  });
};
