import Grid from '@mui/material/Unstable_Grid2';
import useFetchContent from '../../hooks/useFetchContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import Collapsible from '../UI/Collapsible';
import ShortFilter from '../Filter/ShortFilter';
import DisplayOptions from '../Filter/DisplayOptions';
import SingleText from '../UI/SingleText';
import { CarPreview, CarPreviewSkeleton } from '../Cars/Cars';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { globals } from '../../data/global-vars';
import { useBrandFind } from '../../hooks/useFinders';

function Wohnmobilbestand() {
  const standardLimit = 40;
  const params = useParams();
  const history = useHistory();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { error, getCarsFiltered } = useFetchContent();
  const [data, setData] = useState({ docs: [] });
  const [carsLoaded, setCarsLoaded] = useState(false);
  const localFilter =
    localStorage.getItem('motorhomeFilter') &&
    !localStorage.getItem('motorhomeFilter') !== 'undefined' &&
    JSON.parse(localStorage.getItem('motorhomeFilter'));
  const [filter, setFilter] = useState(
    localFilter?.filter ||
      history.location.state?.filter || {
        isNew: false,
        brand: [brandObject.carzillaBrand],
        model: [],
        baseColor: [],
        category: [],
        fuelType: [],
        transmissionType: [],
        feature: [],
        class: ['Motorhome'],
      }
  );
  const unfilteredFilter =
    localFilter?.unfilteredFilter ||
    history.location.state?.unfilteredFilter ||
    {};
  const [sort, setSort] = useState(
    localStorage.getItem('sort') &&
      !localStorage.getItem('sort') !== 'undefined'
      ? localStorage.getItem('sort').replace(/"/g, '')
      : ''
  );

  const [displayOptions, setDisplayOptions] = useState({
    transmissionType: [true, 'Kraftstoffart'],
    km: [true, 'Kilometer'],
    color: [true, 'Farbe'],
    enkvFuelType: [true, 'Kraftstoff'],
    kW: [true, 'Leistung'],
    firstRegistration: [false, 'Erstzulassung'],
    nextHU: [false, 'nächste HU'],
    yearOfConstruction: [false, 'Baujahr'],
  });
  const [priceOptions, setPriceOptions] = useState([]);
  const displayOptionsOrder = [
    'transmissionType',
    'km',
    'color',
    'enkvFuelType',
    'kW',
    'firstRegistration',
    'nextHU',
    'yearOfConstruction',
  ];

  // const swiperRef = useRef(null);
  const gridRef = useRef(null);

  // 1. check if query params in url
  useEffect(() => {
    const urlPage = Number(params[0].split('=')[1]) || 1;
    if (urlPage === 1) {
      setData({ docs: [] });
      setCarsLoaded(false);
    }
    if (params[0] !== 'ankauf' && isNaN(params[0].split('=')[1])) {
      history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/p=${1}`);
    }
  }, [params[0]]);

  // 2. first fetch
  useEffect(() => {
    const filteredFilter = Object.entries(filter).reduce(
      (acc, [key, value]) => {
        if (!Array.isArray(value) || value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    const urlPage = Number(params[0].split('=')[1]) || 1;
    if (data.docs.length === 0)
      getCarsFiltered(
        {
          page: 1,
          limit: standardLimit * urlPage,
          sort: sort === '' ? '_sortby=model&_sortdir=asc' : sort,
        },
        { ...filteredFilter, class: ['Motorhome'] },
        (d) => {
          // console.log('first load');
          if (!error) {
            setData((dd) => {
              return { ...d, docs: [...dd.docs, ...d.docs] };
            });
            setCarsLoaded(true);
          }
        }
      );
    // setTimeout(() => {
    //   window.auWidgetEmbed?.init();
    // }, 1000);
  }, [filter, sort, carsLoaded]);

  // 3. update url on scroll
  function fetchMoreData() {
    const urlPage = Number(params[0].split('=')[1]) || 1;
    if (data.docs.length < data.totalDocs) localStorage.removeItem('detailId');
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/p=${urlPage + 1}`);
    // console.log('more', data);
    // if (gridRef) {
    //   if (gridRef.current) {
    //     // Holen Sie die aktuelle Position des <div>-Elements
    //     const divPosition = gridRef.current.getBoundingClientRect().top;

    //     // Füge hier den zusätzlichen Abstand hinzu, den du möchtest (z.B. -20 für 20px nach oben scrollen)
    //     const scrollY = window.scrollY + divPosition - 600;

    //     // Scrolle zur berechneten Position
    //     window.scrollTo({ top: scrollY, behavior: 'instant' });
    //   }
    // }
    // setData((dd) => {
    //   return { docs: [] };
    // });
    // const filteredFilter = Object.entries(filter).reduce(
    //   (acc, [key, value]) => {
    //     if (!Array.isArray(value) || value.length > 0) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   },
    //   {}
    // );
    // if (data.hasNextPage && data.nextPage !== null)
    //   getCarsFiltered(
    //     {
    //       page: urlPage + 1,
    //       limit: standardLimit,
    //       sort: sort === '' ? '_sortby=model&_sortdir=asc' : sort,
    //     },
    //     filteredFilter,
    //     (d) => {
    //       if (!error) {
    //         setData(d);
    //         setCarsLoaded(false);
    //         if (data.docs.length < data.totalDocs)
    //           localStorage.removeItem('detailId');
    //       }
    //       history.push(
    //         `/${brand}/${globals.PAGES.WOHNMOBILE.url}/p=${d.page}`
    //       );

    //       console.log(d);
    //     }
    //   );
  }

  // 4. fetch if url changed
  useEffect(() => {
    if (data.docs.length > 1) {
      const filteredFilter = Object.entries(filter).reduce(
        (acc, [key, value]) => {
          if (!Array.isArray(value) || value.length > 0) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      const urlPage = Number(params[0].split('=')[1]);
      // console.log('log more');
      if (data.hasNextPage && urlPage === data.nextPage)
        getCarsFiltered(
          {
            page: urlPage,
            limit: standardLimit,
            sort: sort === '' ? '_sortby=model&_sortdir=asc' : sort,
          },
          { ...filteredFilter, class: ['Motorhome'] },
          (d) => {
            // console.log('add some');
            if (!error) {
              setData((dd) => {
                // console.log('log more', dd);
                return { ...d, docs: [...dd.docs, ...d.docs] };
                // return d;
              });
            }
          }
        );
    }
    // setTimeout(() => {
    //   window.auWidgetEmbed?.init();
    // }, 1000);
  }, [params]);

  // 5. change the filter
  function setFilterHandler(pFilter) {
    setCarsLoaded(false);
    pFilter && setFilter(pFilter);
    localStorage.removeItem('detailId');
    setData({ docs: [], hasNextPage: true });
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/p=${1}`);
  }

  // 6. change sort
  function setSortHandler(sortKey) {
    setSort(sortKey);
    setCarsLoaded(false);
    localStorage.removeItem('detailId');
    setData({ docs: [], hasNextPage: true });
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/p=${1}`);
  }

  // 7. scroll to detailId
  useEffect(() => {
    const targetDiv = document.getElementById(localStorage.getItem('detailId'));
    if (targetDiv) {
      targetDiv.scrollIntoView(true);
    }
  }, [carsLoaded]);

  // adjust width of image slider
  //   const gridRef = useRef(null);
  const [gridWidth, setGridWidth] = useState(0);
  useEffect(() => {
    const gridElement = gridRef.current;
    if (!gridElement) return;
    const handleResize = () => {
      const newWidth = gridElement.offsetWidth;
      if (newWidth !== gridWidth) {
        setGridWidth(newWidth);
      }
    };
    const handleWindowResize = () => {
      handleResize();
    };
    const observer = new IntersectionObserver(handleResize, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    observer.observe(gridElement);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      observer.unobserve(gridElement);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [gridWidth]);

  return (
    <>
      <Grid
        xs={12}
        container
        sx={{
          paddingLeft: 'calc(env(safe-area-inset-left))',
          paddingRight: 'calc( env(safe-area-inset-right))',
        }}
      >
        <ShortFilter
          filter={filter}
          unfiltered={unfilteredFilter}
          setFilter={setFilterHandler}
          mobileHome
        />
        <Grid
          xs={12}
          sx={{
            margin: '40px 0',
          }}
        >
          <Collapsible
            label={<h1 className="sectionHeading !text-xl">Anzeigeoptionen</h1>}
          >
            <div style={{ width: 'calc(100% - 10px)', paddingTop: '10px' }}>
              <DisplayOptions
                options={displayOptions}
                setOptions={setDisplayOptions}
                order={displayOptionsOrder}
                setPriceOptions={setPriceOptions}
                sort={sort}
                setSort={setSort}
                setSortHandler={setSortHandler}
              />
            </div>
          </Collapsible>
        </Grid>
        <Grid xs={12} id="triggerTop"></Grid>
        {data.docs.length < 1 && carsLoaded && (
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '800px' }}>
              <SingleText id="646a231aee7476ec44dcd8fd" field="text" />
            </div>
          </Grid>
        )}
        <Grid xs={12} container ref={gridRef} id="liste">
          <InfiniteScroll
            // ref={swiperRef}
            dataLength={data.docs.length}
            next={fetchMoreData}
            hasMore={data.hasNextPage}
            loader={
              <Grid xs={12} container>
                {Array.from({ length: 4 }).map((v, i) => {
                  return (
                    <Grid xs={12} md={6} key={i} sx={{ padding: '10px' }}>
                      <CarPreviewSkeleton />
                    </Grid>
                  );
                })}
              </Grid>
            }
            style={{
              width: gridWidth + 'px',
            }}
          >
            <Grid xs={12} container>
              {data.docs.map((c, i) => {
                return (
                  <Grid
                    xs={12}
                    md={6}
                    key={i}
                    sx={{ padding: '10px' }}
                    container
                  >
                    <Grid
                      xs={12}
                      container
                      style={{
                        borderRadius: '20px',
                        padding: '10px',
                        backgroundColor: 'var(--light-contrast)',
                      }}
                      id={c.carzillaId}
                    >
                      <CarPreview
                        data={c}
                        index={i + 1}
                        displayOptions={displayOptions}
                        displayOptionsOrder={displayOptionsOrder}
                        priceOptions={priceOptions}
                        noAU
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
          {!carsLoaded && (
            <Grid xs={12} container>
              {Array.from({ length: 4 }).map((v, i) => {
                return (
                  <Grid xs={12} md={6} key={i} sx={{ padding: '10px' }}>
                    <CarPreviewSkeleton />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Wohnmobilbestand;
