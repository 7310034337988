import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import useHttp from './use-http';
import AuthContext from '../store/auth-context';
import { useLocation } from 'react-router-dom';
import { globals } from '../data/global-vars';
/**
 * Custom Hook to fetch opening hours for a given brand object.
 *
 * @param {Object} brandObject - The brand object containing a "make" property.
 * @returns {Object} Query result object from useQuery (data, isLoading, error, etc.)
 */
export const useOpeningHours = (brandObject, type = 'default') => {
  const { sendRequest } = useHttp();
  const { pathname } = useLocation();
  const authCtx = useContext(AuthContext);
  const brandIdMap = {
    SKODA: '645d51bba4ddeae8a0cb4487',
    VW: '645dc7acac09d8c1537564ab',
  };

  let brandQuery = globals.BRANDS.VW.make;
  let brandId = brandIdMap.VW;
  if (brandObject?.make === 'SKODA') {
    brandId = brandIdMap.SKODA;
    brandQuery = globals.BRANDS.SKODA.make;
  }

  return useQuery({
    queryKey: ['openingHours', brandId, type, pathname],
    queryFn: () =>
      new Promise((resolve, reject) => {
        const headers = {
          'Content-Type': 'application/json',
        };
        if (authCtx?.token) {
          headers['Authorization'] = 'Bearer ' + authCtx.token;
        }
        if (type === 'today') {
          sendRequest(
            {
              url: '/api/v1/openinghour/today/by/brand/' + brandQuery,
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
            (data) => {
              if (data) {
                resolve(data);
              } else {
                reject(
                  new Error('Fehler beim Laden der heutigen Öffnungszeiten')
                );
              }
            }
          );
        } else {
          sendRequest(
            {
              url: '/api/v1/openinghour/' + brandId,
              method: 'GET',
              ...headers,
            },
            (data) => {
              if (data) {
                resolve(data);
              } else {
                reject(new Error('Fehler beim Laden der Öffnungszeiten'));
              }
            }
          );
        }
      }),
    enabled: (type === 'all' && !!authCtx?.token) || type !== 'all',
    initialData: {
      weekdaysAsString: '',
      saturdayAsString: '',
      sundayAsString: '',
      textRepresentation: '',
      nextUpdate: new Date(),
      days: [],
      exceptions: [],
    },
    keepPreviousData: true,
    refetchInterval: (data) => {
      if (type === 'today' && data?.nextUpdate) {
        const now = new Date();
        const next = new Date(data.nextUpdate);
        const interval = next.getTime() - now.getTime();
        return interval > 0 ? interval : false;
      }
      return false;
    },
  });
};
