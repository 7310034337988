import classes from './Cars.module.css';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

// IMPORT HOOKS, CONTEXTS AND FUNCTIONS
import dateformat from 'dateformat';
import { useBrandFind } from '../../hooks/useFinders';
import CompareContext from '../../store/compare-context';
import { getApiUrl } from '../../config/api';

// IMPORT OWN COMPONENTS
import PriceInfo from '../UI/PriceInfo';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { ListItem, ListItemText, Skeleton } from '@mui/material';
import {
  faCalendar,
  faExclamation,
  faHammer,
  faRoad,
} from '@fortawesome/free-solid-svg-icons';

// IMPORT REACT SWIPER COMPONENTS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import '../UI/swiperStyle.css';
import { Mousewheel, Scrollbar, Navigation, Keyboard } from 'swiper';

// IMPORT FONTAWESOME ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGear,
  faDroplet,
  faBrush,
  faGauge,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import Animation from '../UI/Animation';
import AuthContext from '../../store/auth-context';
import { globals } from '../../data/global-vars';

// HTML TEXT PARSER
const parse = require('html-react-parser');

// for swiper
const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

export function CarPreview(props) {
  const data = props.data;
  const params = useParams();
  const brand = params.brand;
  const history = useHistory();
  const brandObjectPrev = useBrandFind(brand);
  const brandObject = props.brandObject ? props.brandObject : brandObjectPrev;
  const compareCtx = useContext(CompareContext);
  let compare = compareCtx.cars.includes(data.carzillaId);
  const authCtx = useContext(AuthContext);

  // configure style
  let primeColor;
  if (!props.primeColor) primeColor = brandObject.primeColor;
  else primeColor = props.primeColor;

  const c = data;
  const cons = data.consumptionAndEnvironment;
  const d = data.importantDetails;
  const [loadPicAmount, setLoadPicAmount] = useState(
    c.pictureCount > 4 ? 4 : c.pictureCount
  );

  let brandUrl = brandObject.url;

  if (c.branch === 'moenchhof') brandUrl = globals.BRANDS.SKODA.url;
  else if (c.branch === 'seyfarth_nw' || c.branch === 'seyfarth_gw')
    brandUrl = globals.BRANDS.VW.url;
  else if (c.brand === globals.BRANDS.AUDI.carzillaBrand)
    brandUrl = globals.BRANDS.AUDI.url;
  else if (c.brand === globals.BRANDS.VW_NFZ.carzillaBrand)
    brandUrl = globals.BRANDS.VW_NFZ.url;

  const lines = [
    { key: 'transmissionType', icon: faGear, text: d.transmissionType },
    { key: 'enkvFuelType', icon: faDroplet, text: d.fuelType },
    { key: 'color', icon: faBrush, text: d.baseColor },
    { key: 'kW', icon: faGauge, text: d.powerKw && d.powerKw + ' kW' },
    {
      key: 'km',
      icon: faRoad,
      text:
        d.kilometers === 0
          ? '0 km'
          : d.kilometers && d.kilometers.toLocaleString() + ' km',
    },
    {
      key: 'firstRegistration',
      icon: faCalendar,
      text:
        d.firstRegistration &&
        dateformat(new Date(d.firstRegistration), 'mm/yyyy'),
    },
    {
      key: 'nextHU',
      icon: faExclamation,
      text: d.nextHU && dateformat(new Date(d.nextHU), 'mm/yyyy'),
    },
    {
      key: 'yearOfConstruction',
      icon: faHammer,
      text: d.yearOfConstruction,
    },
  ].filter((obj) => obj.text !== null);

  const consumptions = [
    {
      case: '<b>NEFZ:</b> Kraftstoffverbrauch innerorts',
      value: !cons.consumptionUrban
        ? null
        : cons.consumptionUrban + ' L/100km*',
    },
    {
      case: '<b>NEFZ:</b> Kraftstoffverbrauch außerorts',
      value: !cons.consumptionExtraUrban
        ? null
        : cons.consumptionExtraUrban + ' L/100km*',
    },
    {
      case: '<b>NEFZ:</b> Kraftstoffverbrauch kombiniert',
      value: !cons.consumptionCombined
        ? null
        : cons.consumptionCombined + ' L/100km*',
    },
    {
      case: '<b>NEFZ:</b> Stromverbrauch (kombiniert)',
      value: !cons.electricConsumptionCombined
        ? null
        : cons.electricConsumptionCombined + ' kWh/100km',
    },
    {
      case: '<b>NEFZ:</b> CO2-Emissionen kombiniert',
      value: !cons.co2Emission ? null : cons.co2Emission + ' g/km*',
    },
    {
      case: '<b>WLTP:</b> Kraftstoffverbrauch (kombiniert)',
      value: !cons.consumptionWLTPCombined
        ? null
        : cons.consumptionWLTPCombined + ' L/100km',
    },
    {
      case: '<b>WLTP:</b> Kraftstoffverbrauch (langsam)',
      value: !cons.WLTPSlow ? null : cons.WLTPSlow + ' L/100km',
    },
    {
      case: '<b>WLTP:</b> Kraftstoffverbrauch (mittel)',
      value: !cons.WLTPMedium ? null : cons.WLTPMedium + ' L/100km',
    },
    {
      case: '<b>WLTP:</b> Kraftstoffverbrauch (schnell)',
      value: !cons.WLTPFast ? null : cons.WLTPFast + ' L/100km',
    },
    {
      case: '<b>WLTP:</b> Kraftstoffverbrauch (sehr schnell)',
      value: !cons.WLTPVeryFast ? null : cons.WLTPVeryFast + ' L/100km',
    },
    {
      case: '<b>WLTP:</b> Stromverbrauch (kombiniert)',
      value: !cons.electricConsumptionCombinedWLTP
        ? null
        : cons.electricConsumptionCombinedWLTP + ' kWh/100km',
    },
    {
      case: '<b>WLTP:</b> CO2-Emissionen',
      value: !cons.emissionWLTP ? null : cons.emissionWLTP + ' g/km',
    },
    {
      case: 'CO2-Effizienzklasse',
      value: !cons.co2Efficiency ? null : cons.co2Efficiency + '*',
    },
    {
      case: 'Emissionsklasse',
      value: cons.emissionClass,
    },
  ].filter((obj) => obj.value !== null);

  return (
    <Animation dir="left">
      <Grid xs={12} container className={classes.container}>
        {!props.noHeader && (
          <Grid xs={12} sx={{ position: 'relative' }}>
            {authCtx.isLoggedIn && (
              <span className="adminInfo">
                Nr.: {props.index} (ID: {data.carzillaId})
              </span>
            )}
            <Link
              to={`/${brandUrl}/details/${c.carzillaId}`}
              onClick={() => {
                window.scroll(0, 0);
              }}
              style={{
                color: 'inherit',
                width: 'fit-content',
              }}
            >
              <h2 className={`${classes.carHeading} text-3xl text-primary`}>
                {c.brand + ' ' + c.model}{' '}
                {d.modelYear && ' (' + d.modelYear + ')'}
              </h2>
            </Link>
            <FontAwesomeIcon
              icon={faHeart}
              onClick={() => {
                compareCtx.toggleCars(c.carzillaId);
              }}
              className={`${classes.compareIcon} ${compare && classes.active}`}
              style={{
                color: compare ? primeColor : 'var(--text-color)',
                cursor: 'pointer',
              }}
            />
          </Grid>
        )}
        <Grid xs={12} container>
          <Grid
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Swiper
              slidesPerView={1}
              grabCursor={true}
              mousewheel={mousewheelOptions}
              modules={[Mousewheel, Navigation, Scrollbar, Keyboard]}
              centerInsufficientSlides={true}
              navigation
              className={classes.swiper}
              scrollbar={{ draggable: true }}
              // only load 4 at first then load more if user scrolls
              onSlideChange={(e) => {
                if (e.activeIndex + 1 === loadPicAmount)
                  setLoadPicAmount(c.pictureCount);
              }}
              keyboard={{
                enabled: props.keyboardScroll ? true : false,
              }}
            >
              {Array.from({ length: loadPicAmount }).map((v, i) => {
                return (
                  <SwiperSlide key={i} className={classes.swiperSlide}>
                    <img
                      onClick={() => {
                        history.push(`/${brandUrl}/details/${c.carzillaId}`, {
                          scroll: true,
                        });
                      }}
                      className={`${classes.image} select-none`}
                      alt={
                        'Bild des Neuwagens ' +
                        c.model +
                        ' der Firma ' +
                        c.brand
                      }
                      loading={i >= 1 ? 'lazy' : 'eager'}
                      src={getApiUrl(
                        `/images/cars/${c.carzillaId}_${String(i + 1).padStart(
                          2,
                          '0'
                        )}.jpg`
                      )}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Grid>
          <Grid xs={12} md={6} container>
            {lines.map((line, i) => {
              if (
                !props.displayOptions ||
                props.displayOptions[line.key][0] === true
              )
                return (
                  <Grid xs={6} md={!props.displayOptions ? 6 : 12} key={i}>
                    <Link
                      to={`/${brandUrl}/details/${c.carzillaId}`}
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                      style={{
                        color: 'inherit',
                        width: 'fit-content',
                      }}
                    >
                      <ListItem key={i} sx={{ padding: '10px 5px' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '20px',
                            margin: '0 10px',
                          }}
                        >
                          <FontAwesomeIcon icon={line.icon} />
                        </div>
                        <ListItemText
                          className={classes.listItemText}
                          primary={line.text}
                        />
                      </ListItem>
                    </Link>
                  </Grid>
                );
            })}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 30px',
            margin: '20px 0',
          }}
        >
          <PriceInfo
            total={props.priceOptions.includes('total') && d.price}
            financing={
              props.priceOptions.includes('financing') &&
              c.financingProposal.monthlyRate
            }
            priceFeature="financing"
            id={data.carzillaId}
            noAU={props.noAU}
          />
        </Grid>
        <Grid xs={12} sx={{ marginTop: '20px' }}>
          <Link
            to={`/${brandUrl}/details/${c.carzillaId}`}
            onClick={() => {
              window.scroll(0, 0);
            }}
            style={{
              color: 'inherit',
              width: 'fit-content',
            }}
          >
            {consumptions.map((c, i) => {
              return (
                <span key={i} className="adminInfo">
                  <span>{parse(c.case)}:</span>
                  <span>{' ' + c.value}</span>
                  <span>{i !== consumptions.length - 1 && ', '}</span>
                </span>
              );
            })}
          </Link>
        </Grid>
        <span className="adminInfo">(ID: {data.carzillaId})</span>
      </Grid>
    </Animation>
  );
}

export function CarPreviewSkeleton() {
  return (
    <Grid
      xs={12}
      container
      className={classes.container}
      sx={{
        borderRadius: '8px',
        padding: '10px',
        backgroundColor: 'var(--light-contrast)',
      }}
    >
      <Grid
        xs={12}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '20px',
        }}
      >
        <Skeleton variant="rounded" height="40px" width="400px" />
      </Grid>
      <Grid xs={12} container>
        <Grid xs={12} md={6}>
          <div
            style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Skeleton variant="rounded" height="120px" width="100%" />
            <br />
            <Skeleton variant="rounded" height="40px" width="100%" />
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          {Array.from({ length: 5 }).map((v, i) => {
            return (
              <ListItem key={i}>
                <Skeleton variant="rounded" width="95%" height="20px" />
              </ListItem>
            );
          })}
        </Grid>
      </Grid>
      <Grid xs={12} sx={{ marginTop: '20px' }}>
        <Skeleton variant="rounded" width="95%" height="40px" />
      </Grid>
    </Grid>
  );
}
