import classes from './NewCar.module.css';
import { useEffect, useState, useContext } from 'react';

// IMPORT OWN COMPONENTS
import PageLoaderIcon from '../UI/PageLoaderIcon';
import Button from '../UI/Button';

// IMPORT HOOKS, CONTEXTS AND FUNCTIONS
import usePostContent from '../../hooks/usePostContent';
import PopupContext from '../../store/popup-context';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  ListItemText,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// IMPORT FONTAWESOME ICONS
import DraggableList, { DraggableListItem } from '../UI/DraggableList';
import { globals } from '../../data/global-vars';
import { getApiUrl } from '../../config/api';
import DragAndDropFiles from '../UI/DragAndDropFiles';
import useDeleteContent from '../../hooks/useDeleteContent';
import usePatchContent from '../../hooks/usePatchContent';

const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

export const AddNewCarModel = (props) => {
  const { setPopupMessage } = useContext(PopupContext);
  const { isLoading, error, postNewCarModel, postNewCarThumbnail } =
    usePostContent();

  // declare data vars
  const [model, setModel] = useState('');
  const [configLink, setConfigLink] = useState('');
  const [infoLink, setInfoLink] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [motorType, setMotorType] = useState('');
  const fileTypes = ['JPG', 'PNG'];
  const [newImage, setNewImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const carItem = {
      model,
      brand: selectedBrand,
      brandDescription: selectedBrand,
      infoLink: infoLink,
      configLink: configLink,
      motorType: motorType.split(',').map((text) => text.trim()),
      image: newImage,
    };
    postNewCarModel(carItem, (d) => {
      if (!error) {
        if (newImage !== null) {
          const formData = new FormData();
          formData.append('upload', newImage);
          postNewCarThumbnail(formData, d._id, (i) => {
            props.patchCars({ ...d, image: { src: i.url } }, 'add');
            setPopupMessage(
              'Modell: "' + d.model + '" hinzugefügt.',
              'success'
            );
          });
        } else {
          props.patchCars(d, 'add');
          setPopupMessage('Modell: "' + d.model + '" hinzugefügt.', 'success');
        }
      } else {
        setPopupMessage('Fehler beim Hinzufügen des Modells.', 'error');
      }
    });
  };

  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  else
    return (
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', overflow: 'hidden' }}
      >
        <Grid
          xs={12}
          container
          spacing={2}
          sx={{ overflow: 'hidden', paddingBottom: '20px' }}
        >
          <Grid xs={12}>{error && errorMessage}</Grid>
          <Grid xs={12}>
            <TextField
              label="Modell"
              variant="outlined"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Link zu den Informationen"
              variant="outlined"
              value={infoLink}
              onChange={(e) => setInfoLink(e.target.value)}
              fullWidth
              multiline
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Link zum Konfigurator"
              variant="outlined"
              value={configLink}
              onChange={(e) => setConfigLink(e.target.value)}
              multiline
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Antriebsarten (per Komma getrennt)"
              variant="outlined"
              value={motorType}
              onChange={(e) => setMotorType(e.target.value)}
              multiline
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="level-label">Marke</InputLabel>
              <Select
                labelId="level-label"
                id="level-select"
                value={selectedBrand}
                label="Level"
                onChange={(e) => setSelectedBrand(e.target.value)}
                variant="outlined"
                required
              >
                {Object.keys(globals.BRANDS).map((brand, index) => {
                  if (!globals.BRANDS[brand].onlyService)
                    return (
                      <MenuItem value={globals.BRANDS[brand].make} key={index}>
                        {globals.BRANDS[brand].name}
                      </MenuItem>
                    );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <DragAndDropFiles
                types={fileTypes}
                handleChange={setNewImage}
                label="Ein Icon hinzufügen"
              />
            </div>
          </Grid>
          <Grid xs={12}>
            <Button
              color="green"
              type="submit"
              disabled={model === '' ? true : false}
            >
              Veröffentlichen
            </Button>
          </Grid>
        </Grid>
      </form>
    );
};

export const EditNewCarModel = (props) => {
  const { setPopupMessage } = useContext(PopupContext);
  const { isLoading, error, patchNewCarModel } = usePatchContent();
  const { postNewCarThumbnail } = usePostContent();

  const c = props.car;

  // declare data vars
  const [model, setModel] = useState(c.model);
  const [configLink, setConfigLink] = useState(c.configLink || '');
  const [infoLink, setInfoLink] = useState(c.infoLink || '');
  const [selectedBrand, setSelectedBrand] = useState(c.brand || '');
  const [motorType, setMotorType] = useState(c.motorType.join(', '));
  const fileTypes = ['JPG', 'PNG'];
  const [newImage, setNewImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const carItem = {
      model,
      brand: selectedBrand,
      brandDescription: selectedBrand,
      infoLink: infoLink,
      configLink: configLink,
      motorType: motorType.split(',').map((text) => text.trim()),
      image: newImage,
    };
    patchNewCarModel(c._id, carItem, (d) => {
      if (!error) {
        if (newImage !== null) {
          const formData = new FormData();
          formData.append('upload', newImage);
          postNewCarThumbnail(formData, d._id, (i) => {
            props.patchCars({ ...d, image: { src: i.url } }, 'edit');
            setPopupMessage(
              'Neuwagenmodell: "' + d.model + '" aktualisiert.',
              'success'
            );
          });
        } else {
          props.patchCars({ ...d }, 'edit');
          setPopupMessage(
            'Neuwagenmodell: "' + d.model + '" aktualisiert.',
            'success'
          );
        }
      } else {
        setPopupMessage('Fehler beim Hinzufügen des Modells.', 'error');
      }
    });
  };

  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  else
    return (
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', overflow: 'hidden' }}
      >
        <Grid
          xs={12}
          container
          spacing={2}
          sx={{ overflow: 'hidden', paddingBottom: '20px' }}
        >
          <Grid xs={12}>{error && errorMessage}</Grid>
          <Grid xs={12}>
            <TextField
              label="Modell"
              variant="outlined"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Link zu den Informationen"
              variant="outlined"
              value={infoLink}
              onChange={(e) => setInfoLink(e.target.value)}
              fullWidth
              multiline
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Link zum Konfigurator"
              variant="outlined"
              value={configLink}
              onChange={(e) => setConfigLink(e.target.value)}
              multiline
              fullWidth
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Antriebsarten (per Komma getrennt)"
              variant="outlined"
              value={motorType}
              onChange={(e) => setMotorType(e.target.value)}
              multiline
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="level-label">Marke</InputLabel>
              <Select
                labelId="level-label"
                id="level-select"
                value={selectedBrand}
                label="Level"
                onChange={(e) => setSelectedBrand(e.target.value)}
                variant="outlined"
              >
                {Object.keys(globals.BRANDS).map((brand, index) => {
                  if (!globals.BRANDS[brand].onlyService)
                    return (
                      <MenuItem value={globals.BRANDS[brand].make} key={index}>
                        {globals.BRANDS[brand].name}
                      </MenuItem>
                    );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <DragAndDropFiles
                types={fileTypes}
                handleChange={setNewImage}
                label="Ein Icon hinzufügen"
              />
            </div>
          </Grid>
          <Grid xs={12}>
            <Button
              color="green"
              type="submit"
              disabled={model === '' ? true : false}
            >
              Aktualisieren
            </Button>
          </Grid>
        </Grid>
      </form>
    );
};

function NewCarLine(props) {
  // const [isActive, setIsActive] = useState(true);
  const { setPopupMessage } = useContext(PopupContext);
  const { error, deleteNewCar } = useDeleteContent();
  const c = props.data;

  function deleteHandler() {
    deleteNewCar(c._id, (d) => {
      if (!error) {
        props.patchCars(c, 'delete');
        setPopupMessage(
          'Neuwagenmodell: "' + c.model + '" gelöscht.',
          'success'
        );
      }
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {c.image && (
        <img
          src={getApiUrl(c.image.src)}
          className={classes.sortImage}
          alt="Bild"
          loading="lazy"
        />
      )}
      <ListItemText primary={c.model} secondary={c.make} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Switch checked={isActive} onChange={() => setIsActive((r) => !r)} /> */}
        <HighlightOffIcon
          onClick={deleteHandler}
          className={`${classes.textIcon} ${classes.cancelIcon}`}
        />
      </div>
    </div>
  );
}

export function SortNewCarModels(props) {
  const [items, setItems] = useState([]);
  // const { error, isLoading, getAllShortcuts } = useFetchContent();
  const { setPopupMessage } = useContext(PopupContext);
  const { error, sortNewCarModels } = usePatchContent();

  useEffect(() => {
    setItems(props.items);
  }, []);

  // sortItems
  function sortItems(arr, droppableIndex, draggableIndex) {
    setItems((items) => {
      if (droppableIndex === draggableIndex) {
        setPopupMessage('Keine Änderung.', 'warning');
        return items;
      } else {
        sortNewCarModels(
          arr[droppableIndex]._id,
          droppableIndex === 0 ? 0 : arr[droppableIndex - 1]._id,
          (d) => {
            if (!error) {
              props.patchCars(arr, 'sort');
              setPopupMessage('Neuwagenmodelle neu sortiert.', 'success');
            } else {
              setPopupMessage('Fehler bei der Sortierung.', 'error');
            }
          }
        );
        return arr;
      }
    });
  }

  return (
    <Grid xs={12} container sx={{ padding: '10px 0' }}>
      {/* {isLoading ? (
        <Grid xs={12}>
          <PageLoaderIcon isLoading={isLoading} />
        </Grid>
      ) : ( */}
      <Grid xs={12}>
        {/* <div className={classes.addContainer}> */}
        <DraggableList items={items} setItems={sortItems}>
          {items.map((item, index) => {
            return (
              <DraggableListItem
                item={item}
                index={index}
                // key={item._id}
                // id={item._id}
                key={index.toString()}
                id={index.toString()}
              >
                <NewCarLine data={item} patchCars={props.patchCars} />
              </DraggableListItem>
            );
          })}
        </DraggableList>
        {/* </div> */}
      </Grid>
      {/* )} */}
    </Grid>
  );
}
