import classes from './Detailseite.module.css';
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

import co2Appp from '../../assets/efficiency_images/CO2-A+++.jpg';
import co2App from '../../assets/efficiency_images/CO2-A++.jpg';
import co2Ap from '../../assets/efficiency_images/CO2-A+.jpg';
import co2A from '../../assets/efficiency_images/CO2-A.jpg';
import co2B from '../../assets/efficiency_images/CO2-B.jpg';
import co2C from '../../assets/efficiency_images/CO2-C.jpg';
import co2D from '../../assets/efficiency_images/CO2-D.jpg';
import co2F from '../../assets/efficiency_images/CO2-F.jpg';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';
import PageHeading from '../UI/PageHeading';
import dateformat from 'dateformat';
// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';

// IMPORT MUI AND OTHER COMPONENTS
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Zoom,
  Box,
  Fab,
  useScrollTrigger,
} from '@mui/material/';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimeField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// IMPORT REACT SWIPER COMPONENTS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import '../UI/swiperStyle.css';
import { Mousewheel, Scrollbar, Navigation, Keyboard } from 'swiper';

// IMPORT FONTAWESOME ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faCheck,
  faPhone,
  faAt,
} from '@fortawesome/free-solid-svg-icons';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import CompareContext from '../../store/compare-context';
import useFetchContent from '../../hooks/useFetchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import SuggestedCars from '../Cars/SuggestedCars';
import StaffWidgets from '../About/StaffWidgets';
import Animation from '../UI/Animation';
import { globals } from '../../data/global-vars';
import PopupContext from '../../store/popup-context';
import Popup from '../UI/Popup';
import usePostContent from '../../hooks/usePostContent';
import { useBrandFind } from '../../hooks/useFinders';
import { KeyboardArrowUp } from '@mui/icons-material';
import ImageCarousel from '../UI/ImageCarousel';
import { getApiUrl } from '../../config/api';
const parse = require('html-react-parser');

// for swiper
const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function Detailseite() {
  const params = useParams();
  const history = useHistory();
  const brand = params.brand;
  const [brandObject, setBrandObject] = useState(useBrandFind(brand));
  const brandHouse = useBrandFind(brand, 'house');
  const compareCtx = useContext(CompareContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { error, getSingleCar } = useFetchContent();
  const [c, setC] = useState(null);
  const [d, setD] = useState(null);
  const [f, setF] = useState(null);
  const [co2Image, setCo2Image] = useState(null);
  const [cons, setCons] = useState(null);
  const [cLoaded, setCLoaded] = useState(false);
  const [compare, setCompare] = useState(false);
  const requestRef = useRef(null);
  const { error: postError, postRequest } = usePostContent();
  const [showImageCarousel, setShowImageCarousel] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  useEffect(() => {
    if (trigger)
      document.documentElement.style.setProperty('--au-bottom-offset', '110px');
    else
      document.documentElement.style.setProperty('--au-bottom-offset', '64px');
  }, [trigger]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // declare data vars
  const [features, setFeatures] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [equipment, setEquipment] = useState([]);
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    if (history.location.state && requestRef.current !== null) {
      if (history.location.state?.data?.reason)
        requestRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [requestRef.current, history.location]);

  const [formData, setFormData] = useState({
    request: history.location.state?.data?.reason
      ? history.location.state.data.reason
      : 'Probefahrt',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: tomorrow,
    message: '',
    acceptPrivacy: false,
    secCode: '',
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: event.target.type === 'checkbox' ? checked : value,
    }));
  };

  const handleDateChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: new Date(e),
    }));
  };

  const sendRequestHandler = (event) => {
    event.preventDefault();
    // Hier kannst du die Formulardaten weiterverarbeiten, z.B. an den Server senden
    const transformedData = [
      { mailLabel: 'Anfrage', value: formData.request },
      { mailLabel: 'Nachname', value: formData.lastName },
      { mailLabel: 'Vorname', value: formData.firstName },
      { mailLabel: 'E-Mail-Adresse', value: formData.email },
      { mailLabel: 'Telefonnummer', value: formData.phone },
      { mailLabel: 'Fahrzeug', value: c.brand + ' ' + c.model },
      { mailLabel: 'Fahrzeug-ID', value: c.carzillaId },
      {
        mailLabel: 'Link zum Fahrzeug',
        value: getApiUrl('/vw/details/' + c.carzillaId),
      },
      {
        mailLabel: 'Wunschtermin',
        value: dateformat(new Date(formData.date), 'dd.mm.yyyy, H:MM') + ' Uhr',
      },
      { mailLabel: 'Nachricht vom Kunden', value: formData.message },
      {
        mailLabel: 'Datenschutz',
        value:
          formData.acceptPrivacy &&
          'Die Datenschutzerklärung wurde akzeptiert.',
      },
    ];
    const formRequest = {
      fromMail: formData.email,
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      reCaptcha: formData.secCode,
      type: 'sales',
      data: transformedData,
    };

    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));

    postRequest(reqFormData, () => {
      if (!postError) {
        setPopupMessage('Anfrage gesendet!', 'success');
        setFormData({
          request: 'Probefahrt',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          date: tomorrow,
          message: '',
          acceptPrivacy: false,
          secCode: '',
        });
      } else setPopupMessage('Fehler beim Senden der Anfrage!', 'error');
    });
  };

  useEffect(() => {
    setCLoaded(false);
    getSingleCar(params[0], (data) => {
      setCLoaded(true);
      if (!error) {
        localStorage.setItem('detailId', data.carzillaId);
        setC(data);
        setD(data.importantDetails);
        setF(data.features);
        setCons(data.consumptionAndEnvironment);
        setCompare(compareCtx.cars.includes(data.carzillaId));

        // set brandUrl
        if (data.branch === 'moenchhof') {
          setBrandObject(globals.BRANDS.SKODA);
          if (brandObject !== globals.BRANDS.SKODA)
            window.history.pushState(
              {},
              '',
              '/skoda/details/' + data.carzillaId
            );
        } else if (
          data.brand === 'seyfarth_nw' ||
          data.branch === 'seyfarth_gw'
        ) {
          setBrandObject(globals.BRANDS.VW);
          if (brandObject !== globals.BRANDS.VW)
            window.history.pushState({}, '', '/vw/details/' + data.carzillaId);
        }
      }
    });
  }, [params[0]]);

  useEffect(() => {
    if (d) {
      setFeatures(
        [
          {
            title: 'Leistung',
            value:
              d.powerKw &&
              d.powerPS &&
              d.powerKw + ' kW / ' + Math.round(d.powerPS) + ' PS',
          },
          {
            title: 'Kilometerstand',
            value:
              d.kilometers === 0
                ? '0 km'
                : d.kilometers && d.kilometers.toLocaleString() + ' km',
          },
          {
            title: 'Erstzulassung',
            value:
              d.firstRegistration &&
              dateformat(new Date(d.firstRegistration), 'mm/yyyy'),
          },
          {
            title: 'nächste HU',
            value: d.nextHU && dateformat(new Date(d.nextHU), 'mm/yyyy'),
          },
          { title: 'Kraftstoffart', value: d.fuelType },
          { title: 'Getriebe', value: d.transmissionType },
          { title: 'Farbe', value: d.colorDetail },
          { title: 'Sitze', value: d.seatCount },
          { title: 'Vorbesitzer', value: d.previousOwners },
          {
            title: 'Garantie',
            value: c.features.hasWarranty ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faCheck} />
            ),
          },
          { title: 'Hubraum', value: d.ccm > 0 ? d.ccm : null },
          {
            title: 'Baujahr',
            value: d.yearOfConstruction > 0 ? d.yearOfConstruction : null,
          },
          { title: 'Modelljahr', value: d.modelYear > 0 ? d.modelYear : null },
          { title: f.isNew ? 'Neuwagen' : 'Gebrauchtwagen', value: '' },
        ].filter((obj) => obj.value !== null)
      );

      setConsumption(
        [
          {
            case: '<b>NEFZ:</b> Kraftstoffverbrauch innerorts',
            value: !cons.consumptionUrban
              ? null
              : cons.consumptionUrban + ' L/100km*',
          },
          {
            case: '<b>NEFZ:</b> Kraftstoffverbrauch außerorts',
            value: !cons.consumptionExtraUrban
              ? null
              : cons.consumptionExtraUrban + ' L/100km*',
          },
          {
            case: '<b>NEFZ:</b> Kraftstoffverbrauch kombiniert',
            value: !cons.consumptionCombined
              ? null
              : cons.consumptionCombined + ' L/100km*',
          },
          {
            case: '<b>NEFZ:</b> Stromverbrauch (kombiniert)',
            value: !cons.electricConsumptionCombined
              ? null
              : cons.electricConsumptionCombined + ' kWh/100km',
          },
          {
            case: '<b>NEFZ:</b> CO2-Emissionen kombiniert',
            value: !cons.co2Emission ? null : cons.co2Emission + ' g/km*',
          },
          {
            case: '<b>WLTP:</b> Kraftstoffverbrauch (kombiniert)',
            value: !cons.consumptionWLTPCombined
              ? null
              : cons.consumptionWLTPCombined + ' L/100km',
          },
          {
            case: '<b>WLTP:</b> Kraftstoffverbrauch (langsam)',
            value: !cons.WLTPSlow ? null : cons.WLTPSlow + ' L/100km',
          },
          {
            case: '<b>WLTP:</b> Kraftstoffverbrauch (mittel)',
            value: !cons.WLTPMedium ? null : cons.WLTPMedium + ' L/100km',
          },
          {
            case: '<b>WLTP:</b> Kraftstoffverbrauch (schnell)',
            value: !cons.WLTPFast ? null : cons.WLTPFast + ' L/100km',
          },
          {
            case: '<b>WLTP:</b> Kraftstoffverbrauch (sehr schnell)',
            value: !cons.WLTPVeryFast ? null : cons.WLTPVeryFast + ' L/100km',
          },
          {
            case: '<b>WLTP:</b> Stromverbrauch (kombiniert)',
            value: !cons.electricConsumptionCombinedWLTP
              ? null
              : cons.electricConsumptionCombinedWLTP + ' kWh/100km',
          },
          {
            case: '<b>WLTP:</b> CO2-Emissionen',
            value: !cons.emissionWLTP ? null : cons.emissionWLTP + ' g/km',
          },
          {
            case: 'CO2-Effizienzklasse',
            value: !cons.co2Efficiency ? null : cons.co2Efficiency + '*',
          },
          {
            case: 'Emissionsklasse',
            value: cons.emissionClass,
          },
        ].filter((obj) => obj.value !== null)
      );

      setEquipment([
        {
          title: 'Optik',
          elements: [
            f.alloyWheels && 'Leichtmetallfelgen',
            f.taxi && 'Taxi',
            f.metallic && 'Metallic',
            f.steelWheels && 'Stahlfelgen',
            f.cargoBarrier && 'Laderaumtrennwand',
            (f.roofRails || f.roofRack) && 'Dachreling',
            f.trailerCoupling && 'Anhängerkupplung',
            f.trailerCouplingSwiveling &&
              'Abnehmbare schwenkbare Anhängerkupplung',
            f.trailerCouplingDetachable && 'Abnehmbare Anhängerkupplung',
            f.trailerCouplingFix && 'Feste Anhängerkupplung',
            f.slidingDoor && 'Schiebetür',
            f.slidingDoorRight && 'Schiebetür rechts',
            f.slidingDoorLeft && 'Schiebetür links',
            f.slidingDoorBothSides && 'Schiebetür beidseitig',
            f.privacyGlas && 'Getönte Scheiben',
            f.heatInsulatingGlass && 'Wärmeschutzverglasung',
            f.heatedWindshield && 'Beheizbare Windschutzscheibe',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
            f.electricTailgate && 'Elektrische Heckklappe',
          ].filter(Boolean),
        },
        {
          title: 'Sicht',
          elements: [
            f.lightSensor && 'Lichtsensor',
            f.LEDHeadlights && 'LED-Scheinwerfer',
            f.daytimeRunningLights && 'Tagfahrlicht',
            f.LEDRunningLights && 'LED-Tagfahrlicht',
            f.xenonHeadlights && 'Xenon-Scheinwerfer',
            f.biXenonHeadlights && 'Bi-Xenon-Scheinwerfer',
            f.laserHeadlights && 'Laser-Scheinwerfer',
            f.fogLamp && 'Nebelscheinwerfer',
            f.glareFreeHighBeamHeadlights && 'Blendfreies Fernlicht',
            f.automaticallyDimmingInteriorMirror &&
              'Automatisch abblendender Innenrückspiegel',
            f.adaptiveCorneringLights && 'Adaptive Kurvenlichter',
            f.headlightWasherSystem && 'Scheinwerferreinigungsanlage',
            f.heatedWindshield && 'Beheizbare Windschutzscheibe',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
          ].filter(Boolean),
        },
        {
          title: 'Innen',
          elements: [
            f.leatherSteeringWheel && 'Lederlenkrad',
            f.armRest && 'Mittelarmlehne',
            f.armRest && 'Armauflage',
            f.heatedSeats && 'Sitzheizung',
            f.heatedRearSeats && 'Beheizbare Rücksitze',
            f.seatVentilation && 'Sitzbelüftung',
            f.isofix && 'Isofix',
            f.isofixPassengerSeat && 'Isofix auf Beifahrersitz',
            f.electricBackseatAdjustment &&
              'Elektrische Einstellung der Rücksitze',
            f.lumbarSupport && 'Lordosenstütze',
            f.airSuspension && 'Luftfederung',
            f.massageSeats && 'Massagesitze',
            f.sunroof && 'Schiebedach',
            f.panoramicRoof && 'Panoramadach',
            f.automaticClimatisation && 'Automatische Klimatisierung',
            f.manualClimatisation && 'Manuelle Klimatisierung',
            f.clima2Zones && '2-Zonen-Klimaanlage',
            f.clima3Zones && '3-Zonen-Klimaanlage',
            f.clima4Zones && '4-Zonen-Klimaanlage',
            f.heatedSteeringWheel && 'Beheizbares Lenkrad',
            f.ambientLighting && 'Ambientebeleuchtung',
            f.skiBag && 'Skisack',
          ].filter(Boolean),
        },
        {
          title: 'Außen',
          elements: [
            f.metallic && 'Metallic-Lackierung',
            f.alloyWheels && 'Leichtmetallfelgen',
            f.steelWheels && 'Stahlfelgen',
            f.cargoBarrier && 'Laderaumtrennwand',
            (f.roofRails || f.roofRack) && 'Dachreling',
            f.trailerCoupling && 'Anhängerkupplung',
            f.trailerCouplingSwiveling &&
              'Abnehmbare schwenkbare Anhängerkupplung',
            f.trailerCouplingDetachable && 'Abnehmbare Anhängerkupplung',
            f.trailerCouplingFix && 'Feste Anhängerkupplung',
            f.slidingDoor && 'Schiebetür',
            f.slidingDoorRight && 'Schiebetür rechts',
            f.slidingDoorLeft && 'Schiebetür links',
            f.slidingDoorBothSides && 'Schiebetür beidseitig',
            f.privacyGlas && 'Getönte Scheiben',
            f.heatInsulatingGlass && 'Wärmeschutzverglasung',
            f.heatedWindshield && 'Beheizbare Windschutzscheibe',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
            f.electricTailgate && 'Elektrische Heckklappe',
          ].filter(Boolean),
        },
        {
          title: 'Assistenzsysteme',
          elements: [
            f.cruiseControl && 'Tempomat',
            f.adaptiveCruiseControl && 'Adaptiver Tempomat',
            f.laneKeepingAssist && 'Spurhalteassistent',
            f.laneChangeAssist && 'Spurwechselassistent',
            f.emergencyBrakeAssist && 'Notbremsassistent',
            f.hillStartAssist && 'Berganfahrassistent',
            f.fatigueWarningSystem && 'Müdigkeitserkennung',
            f.emergencyCallSystem && 'Notrufsystem',
            f.tyrePressureMonitoring && 'Reifendrucküberwachung',
            f.distanceWarningSystem && 'Abstandswarnsystem',
            f.parkingSensorsFront && 'Einparkhilfe vorne',
            f.parkingSensorsRear && 'Einparkhilfe hinten',
            f.parkingSensorsSelfSteeringSystem &&
              'Einparkhilfe mit Lenkunterstützung',
            f.trafficSignRecognition && 'Verkehrszeichenerkennung',
            f.nightVisionAssist && 'Nachtsichtassistent',
            f.highBeamAssist && 'Fernlichtassistent',
            f.tractionControl && 'Traktionskontrolle',
            f.rangeExtender && 'Reichweitenverlängerer',
            f.speedLimitControlSystem && 'Geschwindigkeitsregelanlage',
          ].filter(Boolean),
        },
        {
          title: 'Technik & Sicherheit',
          elements: [
            f.esp && 'ESP',
            f.abs && 'ABS',
            f.immobilizer && 'Wegfahrsperre',
            f.centralLocking && 'Zentralverriegelung',
            !f.isDamaged && 'Unfallfrei',
            f.fullServiceHistory && 'Scheckheftgepflegt',
            f.alarmSystem && 'Alarmanlage',
            f.particleFilter && 'Partikelfilter',
            f.startStopSystem && 'Start/Stop-Automatik',
            f.immobilizer && 'Wegfahrsperre',
            f.centralLocking && 'Zentralverriegelung',
            f.keylessCentralLocking && 'Keyless-Zentralverriegelung',
            f.powerAssistedSteering && 'Servolenkung',
            f.emergencyCallSystem && 'Notrufsystem',
            f.emergencyBrakeAssist && 'Notbremsassistent',
            f.rainSensor && 'Regensensor',
            f.hasWarranty && 'Mit Garantie',
          ].filter(Boolean),
        },
        {
          title: 'Komfort',
          elements: [
            f.sunroof && 'Schiebedach',
            f.cruiseControl && 'Tempomat',
            f.parkingHeater && 'Standheizung',
            f.electricWindows && 'Elektrische Fensterheber',
            f.automaticClimatisation && 'Automatische Klimatisierung',
            f.manualClimatisation && 'Manuelle Klimatisierung',
            f.powerAssistedSteering && 'Servolenkung',
            f.multifunctionSteeringWheel && 'Multifunktionslenkrad',
            f.parkingSensorsFront && 'Einparkhilfe vorne',
            f.parkingSensorsRear && 'Einparkhilfe hinten',
            f.parkingSensorsCamera && 'Rückfahrkamera',
            f.parkingSensorsCamera360 && '360°-Kamera',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
            f.heatedOutsideMirror && 'Beheizbare Außenspiegel',
            f.parkingSensorsSelfSteeringSystem &&
              'Einparkhilfe mit Lenkunterstützung',
            f.rainSensor && 'Regensensor',
            f.heatedSeats && 'Sitzheizung',
            f.seatVentilation && 'Sitzbelüftung',
            f.clima2Zones && '2-Zonen-Klimaautomatik',
            f.lumbarSupport && 'Lordosenstütze',
            f.airSuspension && 'Luftfederung',
            f.massageSeats && 'Massagesitze',
            f.heatedSteeringWheel && 'Beheizbares Lenkrad',
            f.electricTailgate && 'Elektrische Heckklappe',
          ].filter(Boolean),
        },
        {
          title: 'Infotainment',
          elements: [
            f.bluetooth && 'Bluetooth',
            f.MP3Interface && 'MP3-Schnittstelle',
            f.multifunctionSteeringWheel && 'Multifunktionslenkrad',
            f.tunerOrRadio && 'Radio/Tuner',
            f.navigationSystem && 'Navigationssystem',
            f.DABRadio && 'DAB-Radio',
            f.digitalCockpit && 'Digitales Cockpit',
            f.integratedMusicStreaming && 'Integriertes Musik-Streaming',
            f.soundSystem && 'Soundsystem',
            f.voiceControl && 'Sprachsteuerung',
            f.touchscreen && 'Touchscreen',
            f.usbPort && 'USB-Anschluss',
            f.androidAuto && 'Android Auto',
            f.appleCarPlay && 'Apple CarPlay',
            f.WiFi && 'WiFi',
            f.bluetooth && 'Bluetooth',
            f.CDPlayer && 'CD-Player',
            f.boardComputer && 'Bordcomputer',
            f.headUpDisplay && 'Head-Up Display',
            f.MP3Interface && 'MP3-Schnittstelle',
            f.handsFreeKit && 'Freisprecheinrichtung',
            f.inductionSmartphoneCharging &&
              'Induktive Smartphone-Ladefunktion',
            f.integratedMusicStreaming && 'Integriertes Musik-Streaming',
            f.paddleShifters && 'Schaltwippen',
          ].filter(Boolean),
        },
        {
          title: 'Räder & Reifen',
          elements: [
            f.emergencyTyreRepairKit && 'Reifendichtmittel',
            f.tyresAllSeason && 'Ganzjahresreifen',
            f.tyresSummer && 'Sommerreifen',
            f.tyresWinter && 'Winterreifen',
            f.emergencyTyre && 'Notfallreifen',
            f.emergencyTyreRepairKit && 'Notfallreifen-Reparaturset',
            f.spareTyre && 'Ersatzreifen',
            f.steelWheels && 'Stahlfelgen',
            f.alloyWheels && 'Leichtmetallfelgen',
          ].filter(Boolean),
        },
        {
          title: 'Pakete',
          elements: [
            f.winterPackage && 'Winterpaket',
            f.smokersPackage && 'Raucherpaket',
            f.sportsPackage && 'Sportpaket',
          ].filter(Boolean),
        },
      ]);
      console.log(cons.co2Efficiency);
      switch (cons.co2Efficiency) {
        case 'A+++':
          setCo2Image(co2Appp);
          break;
        case 'A++':
          setCo2Image(co2App);
          break;
        case 'A+':
          setCo2Image(co2Ap);
          break;
        case 'A':
          setCo2Image(co2A);
          break;
        case 'B':
          setCo2Image(co2B);
          break;
        case 'C':
          setCo2Image(co2C);
          break;
        case 'D':
          setCo2Image(co2D);
          break;
        case 'F':
          setCo2Image(co2F);
          break;

        default:
          setCo2Image(null);
          break;
      }
    }
  }, [d, f, cons]);

  // style
  const primeColor = brandObject.primeColor;
  // if (c) {
  //   switch (c.brand) {
  //     case 'VW':
  //       primeColor = 'var(--vw-color)';
  //       break;
  //     case 'SKODA':
  //       primeColor = 'var(--vw-color)';
  //       break;
  //     case 'AUDI':
  //       primeColor = 'var(--vw-color)';
  //       break;

  //     default:
  //       primeColor = 'var(--vw-color)';
  //       break;
  //   }
  // }

  // function parseDescription(text) {
  //   const sections = text.split(/\*{2}(.+?)\*{2}/).filter(Boolean);

  //   return sections.map((section, index) => {
  //     const title = section.trim();
  //     const content = sections[index + 1]?.trim() || '';
  //     if (index % 2 === 0) {
  //       return (
  //         <div key={index} className={classes.descSection}>
  //           <span className={classes.descHeading}>{title}</span>
  //           <div className={classes.descText}>
  //             {content.split('\n').map((item, i) => {
  //               const list = item
  //                 .trim()
  //                 .replace(/\\/g, '')
  //                 .split('*')
  //                 .join(',');
  //               return (
  //                 <span key={i}>
  //                   {list.charAt(0) === ',' &&
  //                     list.substring(1).replace('---- quality by dotzilla', '')}
  //                 </span>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // }

  useEffect(() => {
    window.AuEmbed = {
      config: {
        data: {
          stockId: !error ? params[0] : '',
        },
      },
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.auWidgetEmbed?.init();
    }, 100);
  }, []);

  if (error) {
    return (
      <div className="container">
        <div className={classes.notFoundContainer}>
          <h2>Fahrzeug nicht gefunden...</h2>
          <p>
            Zur aufgerufenen Adresse haben wir das passende Fahrzeug leider
            nicht gefunden. Aber wir haben zahlreiche weitere Angebote in
            unserem online-Bestand...
          </p>
          <Grid xs={12} container>
            <Grid
              sm={brandObject.onlyService ? 12 : 6}
              xs={12}
              sx={{ padding: '5px' }}
            >
              <Link
                to={`/${brand}/${globals.PAGES.GEBRAUCHTWAGEN.url}/`}
                style={{ textDecoration: 'none' }}
              >
                <Button maxWidth="400px">Zu den Gebrauchtwagen</Button>
              </Link>
            </Grid>
            {!brandObject.onlyService && (
              <Grid sm={6} xs={12} sx={{ padding: '5px' }}>
                <Link
                  to={`/${brand}/${globals.PAGES.NEUWAGEN.url}/`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button maxWidth="400px">Zu den Neuwagen</Button>
                </Link>
              </Grid>
            )}
            <Grid xs={12} sx={{ padding: '5px' }}>
              <Link
                to={`/${brand}/${globals.PAGES.STARTSEITE.url}/`}
                style={{ textDecoration: 'none' }}
              >
                <Button maxWidth="400px">Zur Startseite</Button>
              </Link>
            </Grid>
          </Grid>
          <SuggestedCars />
        </div>
      </div>
    );
  } else if (!cLoaded && !error) {
    return (
      <Fragment>
        <Grid xs={12} container className="container">
          <Grid xs={12} container spacing={0} className={classes.container}>
            <Grid
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Skeleton height="60px" width="300px" />
              <Skeleton height="60px" width="500px" />
            </Grid>
            <Grid xs={12} container>
              <Grid xs={12} md={6} container sx={{ display: 'flex' }}>
                <Grid
                  xs={12}
                  sx={{
                    padding: 0,
                    margin: 0,
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '90%',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '100%',
                      height: '400px',
                    }}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <PageLoaderIcon isLoading={true} />
                    </div>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  sx={{
                    marginBottom: 0,
                    marginTop: 'auto',
                  }}
                >
                  <Skeleton width="90%" height="60px" />
                </Grid>
              </Grid>
              <Grid xs={12} md={6} container>
                <Grid
                  xs={12}
                  sx={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  container
                >
                  {Array.from({ length: 12 }).map((line, i) => {
                    return (
                      <Grid xs={6} container key={i}>
                        <Skeleton width="90%" height="40px" />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                columnSpacing={2}
                className={classes.upperButtonGroup}
              >
                <Grid xs={'auto'} sm={'auto'}>
                  <Button icon={faPhone} maxWidth="180px">
                    {brandHouse.TELEFONNUMMER}
                  </Button>
                </Grid>
                <Grid xs={'auto'} sm={'auto'}>
                  <Button icon={faAt} maxWidth="300px">
                    E-Mail schreiben!
                  </Button>
                </Grid>
                <Grid xs={'auto'}>
                  <Button
                    className="au-widget-trade-in"
                    onClick={() =>
                      window.auws.openModal(window.auws.ModuleTypes.TRADE_IN)
                    }
                    maxWidth="400px"
                  >
                    Jetzt Fahrzeug in Zahlung geben!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} container className="container" sx={{ margin: '30px 0' }}>
          <Grid xs={12}>
            <h1 className="sectionHeading">Fahrzeug-Eigenschaften</h1>
          </Grid>
          {Array.from({ length: 12 }).map((f, i) => {
            return (
              <Grid xs={6} container key={i} className={classes.features}>
                <Skeleton width="90%" height="40px" />
              </Grid>
            );
          })}
        </Grid>
        <Grid xs={12} container className="container" sx={{ margin: '30px 0' }}>
          <Grid xs={12} container spacing={2}>
            <Grid xs={12}>
              <h1 className="sectionHeading">Ausstattung</h1>
            </Grid>
            {Array.from({ length: 4 }).map((e, i) => {
              return (
                <Grid xs={6} key={i} className={classes.equipment}>
                  <Skeleton
                    width="280px"
                    height="35px"
                    sx={{
                      position: 'relative',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  />
                  <Grid xs={12}>
                    {Array.from({ length: 8 }).map((el, ii) => {
                      return (
                        <Grid
                          xs={12}
                          key={ii}
                          className={classes.equipmentElement}
                        >
                          <Skeleton width="280px" height="25px" />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            xs={12}
            md={6}
            container
            className={classes.consumption}
            sx={{ marginTop: '20px' }}
          >
            <h1 className="sectionHeading">Verbrauch</h1>
            {Array.from({ length: 4 }).map((c, i) => {
              return (
                <Grid xs={12} key={i}>
                  <Skeleton width="300px" height="30px" />
                </Grid>
              );
            })}
          </Grid>
          <Grid xs={12} md={6} sx={{ marginTop: '20px' }}>
            <h1 className="sectionHeading">
              CO<sub>2</sub>-Effizienz
            </h1>
            <Skeleton width="300px" height="300px" />
          </Grid>
        </Grid>
      </Fragment>
    );
  } else
    return (
      <Fragment>
        {showImageCarousel && (
          <ImageCarousel
            onHide={() => {
              setShowImageCarousel(false);
            }}
            pictureCount={c.pictureCount}
            carzillaId={c.carzillaId}
            model={c.model}
            brand={c.brand}
            show={showImageCarousel}
          />
        )}
        <Popup />
        <Grid xs={12} container className="container" sx={{ padding: 0 }}>
          <Grid xs={12} container spacing={0} className={classes.container}>
            <Grid
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <PageHeading title={c.brand + ' ' + c.model}>
                {c.brand + ' ' + c.model}
              </PageHeading>
              <h3
                style={{
                  textAlign: 'left',
                  marginTop: '-10px',
                  marginLeft: '10px',
                  marginBottom: 0,
                }}
              >
                {/* {c.modelExtended} */}
              </h3>
            </Grid>
            <Grid xs={12} container>
              <Grid
                xs={12}
                md={6}
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '20px',
                  justifyContent: 'center',
                }}
              >
                {!cLoaded ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <PageLoaderIcon isLoading={true} />
                  </div>
                ) : (
                  <Animation>
                    <Swiper
                      slidesPerView={1}
                      grabCursor={true}
                      mousewheel={mousewheelOptions}
                      modules={[Mousewheel, Navigation, Scrollbar, Keyboard]}
                      centerInsufficientSlides={true}
                      navigation
                      className={classes.swiper}
                      scrollbar={{ draggable: true }}
                      keyboard={{
                        enabled: true,
                      }}
                    >
                      {Array.from({ length: c?.pictureCount }).map((v, i) => {
                        return (
                          <SwiperSlide key={i} className={classes.swiperSlide}>
                            <img
                              className={`${classes.image} select-none`}
                              alt={
                                'Bild des Neuwagens ' +
                                c.model +
                                ' der Firma ' +
                                c.brand
                              }
                              src={getApiUrl(
                                `/images/cars/${c.carzillaId}_${String(
                                  i + 1
                                ).padStart(2, '0')}.jpg`
                              )}
                              onClick={() => {
                                setShowImageCarousel(true);
                              }}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </Animation>
                )}
              </Grid>
              <Grid
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                }}
                container
              >
                <Grid
                  xs={12}
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className={classes.priceCalcWrapper}
                    style={{
                      paddingBottom: !c.financingProposal.monthlyRate && '0px',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faHeart}
                      onClick={() => {
                        compareCtx.toggleCars(c.carzillaId);
                        setCompare((v) => !v);
                      }}
                      className={`${classes.compareIcon} ${
                        compare && classes.active
                      }`}
                      style={{
                        color: compare ? primeColor : 'var(--text-color)',
                        cursor: 'pointer',
                      }}
                    />
                    <div className={classes.priceCalcHeader}>
                      <span>Kaufpreis</span>
                      <br />
                      <p
                        className={classes.priceCalcHeaderPrice}
                        style={{ color: primeColor }}
                      >
                        {Math.round(d.price).toLocaleString() + ' €'}
                      </p>
                      {!d.withoutTax && (
                        <p className={classes.taxLegal}>MwSt. ausweisbar</p>
                      )}
                    </div>
                    {c.financingProposal.monthlyRate && (
                      <div className={classes.priceCalcEx}>
                        <span className={classes.priceCalcExHeader}>
                          Finanzierungsbeispiel
                        </span>
                        <div className={classes.priceCalcTable}>
                          {c.financingProposal.downPayment && (
                            <div className={classes.priceCalcLine}>
                              <span>Anzahlung</span>
                              <span>
                                {c.financingProposal.downPayment.toLocaleString() +
                                  ' €'}
                              </span>
                            </div>
                          )}
                          {c.financingProposal.netto && (
                            <div className={classes.priceCalcLine}>
                              <span>Netto Darlehensbetrag</span>
                              <span>
                                {c.financingProposal.netto.toLocaleString() +
                                  ' €'}
                              </span>
                            </div>
                          )}
                          {c.financingProposal.nominalRate && (
                            <div className={classes.priceCalcLine}>
                              <span>
                                Sollzins {c.financingProposal.nominalRateType}{' '}
                                p.a.
                              </span>
                              <span>
                                {c.financingProposal.nominalRate.toLocaleString() +
                                  ' %'}
                              </span>
                            </div>
                          )}
                          {c.financingProposal.apr && (
                            <div className={classes.priceCalcLine}>
                              <span>Effektiver Jahreszins</span>
                              <span>
                                {c.financingProposal.apr.toLocaleString() +
                                  ' %'}
                              </span>
                            </div>
                          )}
                          {c.financingProposal.brutto && (
                            <div className={classes.priceCalcLine}>
                              <span>Brutto Darlehensbetrag</span>
                              <span>
                                {c.financingProposal.brutto.toLocaleString() +
                                  ' €'}
                              </span>
                            </div>
                          )}
                          {c.financingProposal.finalInstalment && (
                            <div className={classes.priceCalcLine}>
                              <span>Schlussrate (bei 10.000 km / Jahr)</span>
                              <span>
                                {c.financingProposal.finalInstalment.toLocaleString() +
                                  ' €'}
                              </span>
                            </div>
                          )}
                          <p className={classes.taxLegal}>
                            {c.financingProposal.providerBank}
                          </p>
                          <div
                            className={classes.priceCalcResultWrapper}
                            style={{ backgroundColor: primeColor }}
                          >
                            {c.financingProposal.monthlyRate && (
                              <div className={classes.priceCalcResultPanel}>
                                <span
                                  className={classes.priceCalcResultHighlight}
                                >
                                  {c.financingProposal.monthlyRate.toLocaleString() +
                                    ' €'}
                                </span>
                                <span className={classes.priceCalcResultExtra}>
                                  Monatl. Rate
                                </span>
                              </div>
                            )}
                            {c.financingProposal.financingTermMonth && (
                              <div className={classes.priceCalcResultPanel}>
                                <span
                                  className={classes.priceCalcResultHighlight}
                                >
                                  {c.financingProposal.financingTermMonth.toLocaleString() +
                                    ' Monate'}
                                </span>
                                <span className={classes.priceCalcResultExtra}>
                                  Laufzeit
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="au-widget-car-rating"
                      data-car-url={getApiUrl('/vw/details/' + c.carzillaId)}
                      style={{ marginTop: '15px' }}
                      data-layout="wider_no_border"
                      data-theme="transparent"
                      lazyload="false"
                    ></div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                columnSpacing={2}
                className={classes.upperButtonGroup}
              >
                <Grid xs={'auto'} sm={'auto'}>
                  <Button
                    icon={faPhone}
                    onClick={() => {
                      window.location.href =
                        'tel:+' + brandHouse.TELEFONNUMMER_LINK;
                    }}
                    maxWidth="180px"
                  >
                    {brandHouse.TELEFONNUMMER}
                  </Button>
                </Grid>
                <Grid xs={'auto'} sm={'auto'}>
                  <Button
                    icon={faAt}
                    onClick={() => {
                      window.location.href =
                        'mailto:' +
                        brandHouse.EMAIL +
                        '?subject=Fahrzeuganfrage%3A' +
                        c.brand +
                        '%20' +
                        c.model +
                        '&body=Informationen%20zum%20Fahrzeug%3A%0A' +
                        c.brand +
                        '%20' +
                        c.model +
                        '%2C%20%0AInterne%20ID%3A%20' +
                        c.carzillaId +
                        '%2C%20%0A%20https%3A%2F%2Fautohaus-seyfarth.de%2Fvw%2Fdetails%2F' +
                        c.carzillaId;
                    }}
                    maxWidth="300px"
                  >
                    E-Mail schreiben!
                  </Button>
                </Grid>
                <Grid xs={'auto'}>
                  <Button
                    className="au-widget-trade-in"
                    onClick={() =>
                      window.auws.openModal(window.auws.ModuleTypes.TRADE_IN)
                    }
                    maxWidth="400px"
                  >
                    Jetzt Fahrzeug in Zahlung geben!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} container className="container-contrast">
          <Grid
            xs={12}
            container
            className="container-contrast-inner"
            sx={{ padding: '20px' }}
          >
            <Grid
              xs={12}
              sx={{
                maxWidth: '100%',
                hyphens: 'auto',
              }}
            >
              <h1 className="sectionHeading">Fahrzeugeigenschaften</h1>
            </Grid>
            {features.map((f, i) => {
              return (
                <Grid
                  xs={12}
                  sm={6}
                  container
                  key={i}
                  className={classes.features}
                >
                  <Grid xs={6} sm={4} style={{ fontWeight: 400 }}>
                    {f.value === '' ? f.title : f.title + ':'}
                  </Grid>
                  <Grid xs={6} sm={8}>
                    {f.value}
                  </Grid>
                </Grid>
              );
            })}
            <div className="w-full text-left adminInfo">
              <span>ID: {c.carzillaId}</span>
            </div>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          container
          className="container"
          sx={{ margin: '10px 0', padding: '20px' }}
        >
          <Grid xs={12} container>
            <Grid xs={12}>
              <h1 className="sectionHeading">Ausstattung</h1>
            </Grid>
            {equipment.map((e, i) => {
              if (e.elements.length >= 1)
                return (
                  <Grid
                    xs={12}
                    sm={6}
                    key={i}
                    className={classes.equipment}
                    sx={{ margin: '10px 0' }}
                  >
                    <Grid
                      xs={12}
                      className={classes.equipmentHeading}
                      sx={{ color: primeColor }}
                    >
                      {e.title}
                    </Grid>
                    <Grid xs={12}>
                      {e.elements.map((el, ii) => {
                        return (
                          <Grid
                            xs={12}
                            key={ii}
                            className={classes.equipmentElement}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ marginRight: '5px' }}
                            />{' '}
                            {el}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
            })}
          </Grid>
          {consumption.length > 0 && (
            <Grid xs={12} sm={6} container>
              <Grid xs={12}>
                <h1 className="sectionHeading">Verbrauch</h1>
              </Grid>
              <Grid
                xs={12}
                container
                className={classes.consumption}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100%',
                  flexDirection: 'column',
                  padding: 0,
                  margin: 0,
                }}
              >
                {consumption.map((c, i) => {
                  return (
                    <Grid
                      // xs={12}
                      key={i}
                      sx={{ margin: '5px 0' }}
                    >
                      <span style={{ fontStyle: 'italic' }}>
                        {parse(c.case)}:
                      </span>
                      <span>{' ' + c.value}</span>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          {cons.co2Efficiency && (
            <Grid xs={12} sm={6}>
              <h1 className="sectionHeading">
                CO<sub>2</sub>-Effizienz
              </h1>
              <Animation>
                <img
                  src={co2Image}
                  className="select-none"
                  alt="Übersicht der Co2-Effizienzklassen"
                  style={{ maxHeight: '200px' }}
                  loading="lazy"
                />
              </Animation>
            </Grid>
          )}
          {f && (
            <Grid xs={12} className="container" sx={{ marginTop: '40px' }}>
              <StaffWidgets
                // noBackground
                brand={brandObject.url}
                dep={f.isNew ? 'newCarSales' : 'usedCarSells'}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          container
          className="container"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            // marginTop: '40px',
            padding: '20px',
          }}
        >
          <form
            onSubmit={sendRequestHandler}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              xs={12}
              container
              spacing={2}
              sx={{
                color: 'var(--text-color)',
              }}
            >
              <Grid xs={12}>
                <h1 className="sectionHeading" id="anfrage" ref={requestRef}>
                  Anfragen
                </h1>
              </Grid>
              <Grid xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-test">Grund</InputLabel>
                  <Select
                    labelId="select-label-test"
                    value={formData.request}
                    label="Grund"
                    name="request"
                    onChange={handleChange}
                    sx={{ textAlign: 'left' }}
                  >
                    <MenuItem value="Probefahrt">Probefahrt</MenuItem>
                    <MenuItem value="Beratung">Beratung</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nachname"
                  variant="outlined"
                  name="lastName"
                  onChange={handleChange}
                  value={formData.lastName}
                  required
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Vorname"
                  variant="outlined"
                  name="firstName"
                  onChange={handleChange}
                  value={formData.firstName}
                  required
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Telefon"
                  variant="outlined"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="E-Mail"
                  type="email"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ backgroundColor: 'white' }}
                  required
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={
                    deDE.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                  adapterLocale="de"
                >
                  <DateTimeField
                    disablePast
                    label="Ihr Wunschtermin"
                    value={dayjs(formData.date)}
                    onChange={handleDateChange}
                    // renderInput={(params) => <TextField {...params} />}
                    // inputFormat="DD.MM.YYYY"
                    fullWidth
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  label="Nachricht"
                  variant="outlined"
                  multiline
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={12} spacing={2}>
                <Grid
                  xs={12}
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Grid xs="auto">
                    <img
                      src={captcha}
                      alt="captcha"
                      loading="lazy"
                      className="select-none"
                      style={{
                        maxWidth: '140px',
                        marginRight: '10px',
                        borderRadius: '8px',
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <TextField
                      fullWidth
                      label="Sicherheitscode"
                      variant="outlined"
                      sx={{ maxWidth: '150px' }}
                      value={formData.secCode}
                      name="secCode"
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} style={{ textAlign: 'left' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      checked={formData.acceptPrivacy}
                    />
                  }
                  name="acceptPrivacy"
                  label={
                    <span>
                      Ich habe die{' '}
                      <a href="/datenschutz">Datenschutzbestimmungen</a> gelesen
                      und akzeptiere diese.
                    </span>
                  }
                  required
                />
              </Grid>
              <Grid xs={12} className={classes.submitButton}>
                <Button
                  xs={12}
                  maxWidth="300px"
                  type="submit"
                  color="green"
                  disabled={
                    formData.name === '' ||
                    formData.email === '' ||
                    formData.phone === '' ||
                    formData.request === '' ||
                    formData.secCode !== '57A1' ||
                    !formData.acceptPrivacy
                  }
                >
                  Abschicken
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid
          xs={12}
          sx={{ marginTop: '40px', paddingBottom: '40px' }}
          className="container"
        >
          <SuggestedCars />
        </Grid>
        <div className={classes.callToAction}>
          <div style={{ margin: '0 10px' }}>
            <Button
              maxWidth="180px"
              onClick={() => {
                requestRef.current.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Jetzt Anfragen!
            </Button>
          </div>
          <div style={{ margin: '0 10px' }}>
            <Button
              icon={faHeart}
              color={compare ? primeColor : 'var(--text-color)'}
              maxWidth="200px"
              onClick={() => {
                if (compare) {
                  const carIdLink = compareCtx.cars
                    .map((c) => c + '&')
                    .join('')
                    .slice(0, -1);
                  history.push(`/${brandObject.url}/vergleich/${carIdLink}`);
                } else {
                  compareCtx.toggleCars(c.carzillaId);
                  setCompare((v) => !v);
                }
              }}
            >
              <span className={classes.middleButtonText}>
                {compare ? 'Zum Vergleich' : 'Vergleichen'}
              </span>
            </Button>
          </div>
          <div
            style={{ margin: '0 10px' }}
            className={classes.smallBottomButton}
          >
            <Button
              icon={faPhone}
              onClick={() => {
                window.location.href = 'tel:+' + brandHouse.TELEFONNUMMER_LINK;
              }}
              maxWidth="180px"
            >
              <span className={classes.smallButtonText}>
                {brandHouse.TELEFONNUMMER}
              </span>
            </Button>
          </div>
          <div
            style={{ margin: '0 10px' }}
            className={classes.smallBottomButton}
          >
            <Button
              icon={faAt}
              onClick={() => {
                window.location.href =
                  'mailto:' +
                  brandHouse.EMAIL +
                  '?subject=Fahrzeuganfrage%3A' +
                  c.brand +
                  '%20' +
                  c.model +
                  '&body=Informationen%20zum%20Fahrzeug%3A%0A' +
                  c.brand +
                  '%20' +
                  c.model +
                  '%2C%20%0AInterne%20ID%3A%20' +
                  c.carzillaId +
                  '%2C%20%0A%20https%3A%2F%2Fautohaus-seyfarth.de%2Fvw%2Fdetails%2F' +
                  c.carzillaId;
              }}
              maxWidth="300px"
            >
              <span className={classes.smallButtonText}>E-Mail schreiben!</span>
            </Button>
          </div>
        </div>
        <Zoom in={trigger}>
          <Box
            role="presentation"
            sx={{
              position: 'fixed',
              bottom: 72,
              right: 32,
              zIndex: 1,
            }}
          >
            <Fab
              onClick={scrollToTop}
              color="primary"
              size="small"
              aria-label="scroll back to top"
              sx={{ borderRadius: '8px' }}
            >
              <KeyboardArrowUp />
            </Fab>
          </Box>
        </Zoom>
      </Fragment>
    );
}

export default Detailseite;
