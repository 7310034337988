import classes from './Jobs.module.css';
import { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getApiUrl } from '../../config/api';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Mousewheel, Scrollbar } from 'swiper';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';
import PageLoaderIcon from '../UI/PageLoaderIcon';

// IMPORT FONTAWESOMEICONS
import {
  faEarthAmerica,
  faPlusCircle,
  faCircleMinus,
  faPenSquare,
  faCirclePlus,
  faPencil,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';

// IMPORT MUI COMPONENTS
import styled from '@mui/material/styles/styled';
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, Box, InputLabel, Skeleton } from '@mui/material';

// IMPORT OTHER COMPONENTS
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Animation from '../UI/Animation';
import DragAndDropFiles from '../UI/DragAndDropFiles';
import usePostContent from '../../hooks/usePostContent';
import usePatchContent from '../../hooks/usePatchContent';
import useFetchContent from '../../hooks/useFetchContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import { useBrandFind } from '../../hooks/useFinders';

const parse = require('html-react-parser');

// STYLE MUI COMPONENTS
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
});

const StyledInput = styled(TextField)({
  width: '100%',
  borderRadius: '8px',
});

const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

const cropImage = async (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const maxWidth = 300;
      const maxHeight = 300;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], 'cropped_image.jpg', {
          type: 'image/jpeg',
        });
        resolve(croppedFile);
      }, 'image/jpeg');
    };

    img.onerror = reject; // Handle image loading errors

    img.src = URL.createObjectURL(image);
  });
};

function JobWidgetForm(props) {
  const { updateHeight, closeModal } = useContext(ModalContext);
  const { isLoading, error, postJob, postJobThumbnail, postJobPdf } =
    usePostContent();
  const { erorr: updateError, patchJob } = usePatchContent();
  // const { error, isLoading, postJobs } = usePostContent();

  const j = props.job;

  // declare variables for news
  const [add, setAdd] = useState(props.edit ? false : true);
  const [title, setTitle] = useState(j.title ? j.title : '');
  // const [subtitle, setSubtitle] = useState(j.subtitle ? j.subtitle : '');
  const [detailsTasks, setDetailsTasks] = useState(
    j.detailsTasks ? j.detailsTasks : ''
  );
  const [detailsOffer, setDetailsOffer] = useState(
    j.detailsOffer ? j.detailsOffer : ''
  );
  const [detailsProfile, setDetailsProfile] = useState(
    j.detailsProfile ? j.detailsProfile : ''
  );
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const fileTypesPDF = ['PDF'];
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    setAdd(props.edit ? false : true);
    setTitle(j.title ? j.title : '');
    // setSubtitle(j.subtitle ? j.subtitle : '');
    setDetailsTasks(j.detailsTasks ? j.detailsTasks : '');
    setDetailsOffer(j.detailsOffer ? j.detailsOffer : '');
    setDetailsProfile(j.detailsProfile ? j.detailsProfile : '');
  }, [j]);

  function handleSubmit(e) {
    e.preventDefault();
    const job = {
      title,
      // subtitle,
      detailsTasks,
      detailsProfile,
      detailsOffer,
    };

    const uploadCroppedImage = async (img, d, action) => {
      try {
        const croppedFile = await cropImage(img);
        const formData = new FormData();
        formData.append('upload', croppedFile);

        postJobThumbnail(formData, d._id, (i) => {
          // if thumbmail is already set, but pdf is also edited
          if (pdf !== null) {
            const formDataPdf = new FormData();
            formDataPdf.append('upload', pdf);
            postJobPdf(formDataPdf, d._id, (ii) => {
              props.patchJobs(
                {
                  ...d,
                  thumbnailImage: { src: i.url },
                  pdfPath: ii.url,
                },
                action
              );
            });
          } else {
            props.patchJobs({ ...d, thumbnailImage: { src: i.url } }, action);
          }
        });
      } catch (error) {
        console.error('Error during cropping or upload:', error);
      }
    };

    if (add) {
      postJob(job, (d) => {
        if (!error) {
          // if thumbnail or pdf is set
          if (thumbnailImage !== null || pdf !== null) {
            // if thumbnail is set
            if (thumbnailImage !== null) {
              uploadCroppedImage(thumbnailImage, d, 'add');
            } else if (pdf !== null) {
              const formDataPdf = new FormData();
              formDataPdf.append('upload', pdf);
              postJobPdf(formDataPdf, d._id, (i) => {
                props.patchJobs({ ...d, pdfPath: i.url });
              });
            }
          } else props.patchJobs(d, 'add');
        }
      });
    } else {
      patchJob(j._id, job, (d) => {
        if (!updateError) {
          // if thumbnail or pdf is set
          if (thumbnailImage !== null || pdf !== null) {
            // if thumbnail is set
            if (thumbnailImage !== null) {
              uploadCroppedImage(thumbnailImage, d, 'edit');
            } else if (pdf !== null) {
              const formDataPdf = new FormData();
              formDataPdf.append('upload', pdf);
              postJobPdf(formDataPdf, d._id, (i) => {
                props.patchJobs({ ...d, pdfPath: i.url }, 'edit');
              });
            }
          } else props.patchJobs(d, 'edit');
        }
      });
    }
    closeModal();
  }

  return (
    <StyledBox>
      {isLoading ? (
        <PageLoaderIcon isLoading={isLoading} />
      ) : (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid xs={12} spacing={2} container>
            <Grid xs={12}>{error && errorMessage}</Grid>
            <Grid xs={12}>
              <StyledInput
                label="Titel"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid xs={6}>
              <DragAndDropFiles
                types={fileTypes}
                handleChange={setThumbnailImage}
                label={
                  !thumbnailImage
                    ? 'Kleines Bild hinzufügen'
                    : 'Kleines Bild ausgewählt'
                }
              />
            </Grid>
            <Grid xs={6}>
              <DragAndDropFiles
                types={fileTypesPDF}
                handleChange={setPdf}
                label={!pdf ? 'PDF hinzufügen (max. 1mb)' : 'PDF ausgewählt'}
                // maxSize={1}
                // onSizeError={() => {
                //   setPdf(null);
                //   setPopupMessage('Datei zu groß. Max 1 Megabyte.', 'error');
                // }}
              />
            </Grid>
            <Grid xs={12}>
              <div style={{ width: '100%' }}>
                <InputLabel>Das sind Ihre Aufgaben:</InputLabel>
                <CKEditor
                  editor={Editor}
                  data={detailsTasks}
                  onChange={(e, editor) => {
                    const text = editor.getData();
                    setDetailsTasks(text);
                    updateHeight();
                  }}
                />
              </div>
            </Grid>
            <Grid xs={12}>
              <div style={{ width: '100%' }}>
                <InputLabel>Das erwartet Sie:</InputLabel>
                <CKEditor
                  editor={Editor}
                  data={detailsOffer}
                  onChange={(e, editor) => {
                    const text = editor.getData();
                    setDetailsOffer(text);
                    updateHeight();
                  }}
                />
              </div>
            </Grid>
            <Grid xs={12}>
              <div style={{ width: '100%' }}>
                <InputLabel>Das bringen Sie mit:</InputLabel>
                <CKEditor
                  editor={Editor}
                  data={detailsProfile}
                  onChange={(e, editor) => {
                    const text = editor.getData();
                    setDetailsProfile(text);
                    updateHeight();
                  }}
                />
              </div>
            </Grid>
            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                color="green"
                maxWidth="200px"
                icon={add ? faCirclePlus : faPencil}
                disabled={
                  title === '' ||
                  detailsTasks === '' ||
                  detailsOffer === '' ||
                  detailsProfile === ''
                    ? true
                    : false
                }
              >
                {add ? 'Hinzufügen' : 'Aktualisieren'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </StyledBox>
  );
}

// function JobWidgetNewForm(props) {
//   const { setPopupMessage } = useContext(PopupContext);
//   const { updateHeight } = useContext(ModalContext);
//   // const { error, isLoading, postJobs } = usePostContent();

//   // declare variables for news
//   const [title, setTitle] = useState('');
//   const [subtitle, setSubtitle] = useState('');
//   const [detailsTasks, setDetailsTasks] = useState('');
//   const [detailsOffer, setDetailsOffer] = useState('');
//   const [detailsProfile, setDetailsProfile] = useState('');
//   const [images, setImages] = useState([]);

//   const handleFileChange = (newFiles) => {
//     setImages(newFiles);
//   };

//   const handleFileDelete = (fileToDelete) => {
//     const filteredFiles = images.filter((img) => img !== fileToDelete);
//     setImages(filteredFiles);
//   };

//   function handleSubmit(e) {
//     e.preventDefault();
//     const job = {
//       _id: Math.random().toString(),
//       title,
//       images,
//       detailsTasks,
//       detailsOffer,
//       detailsProfile,
//     };
//     props.patchJobs(job);
//     setPopupMessage('Stellenangebot: "' + title + '" hochgeladen.', 'success');
//     // postNews(news, (d) => {
//     //   props.patchNews(d);
//     //   setPopupMessage('News: "' + d.title + '" hochgeladen.', 'success');
//     // });
//   }

//   useEffect(() => {
//     console.log(images);
//   }, [images]);

//   const isLoading = false;
//   const error = null;

//   return (
//     <StyledBox>
//       {isLoading ? (
//         <PageLoaderIcon isLoading={isLoading} />
//       ) : (
//         <form onSubmit={handleSubmit} style={{ width: '100%' }}>
//           <Grid xs={12} spacing={2} container>
//             <Grid xs={12}>{error && errorMessage}</Grid>
//             <Grid xs={12}>
//               <StyledInput
//                 label="Titel"
//                 variant="outlined"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//                 required
//               />
//             </Grid>
//             <Grid xs={12}>
//               <StyledInput
//                 label="Subtitel"
//                 variant="outlined"
//                 value={subtitle}
//                 onChange={(e) => setSubtitle(e.target.value)}
//               />
//             </Grid>
//             <Grid xs={12}>
//               <div style={{ width: '100%' }}>
//                 <InputLabel>Das sind Ihre Aufgaben:</InputLabel>
//                 <CKEditor
//                   editor={Editor}
//                   data={detailsTasks}
//                   onChange={(e, editor) => {
//                     const text = editor.getData();
//                     setDetailsTasks(text);
//                     updateHeight();
//                   }}
//                 />
//               </div>
//             </Grid>
//             <Grid xs={12}>
//               <div style={{ width: '100%' }}>
//                 <InputLabel>Das erwartet Sie:</InputLabel>
//                 <CKEditor
//                   editor={Editor}
//                   data={detailsOffer}
//                   onChange={(e, editor) => {
//                     const text = editor.getData();
//                     setDetailsOffer(text);
//                     updateHeight();
//                   }}
//                 />
//               </div>
//             </Grid>
//             <Grid xs={12}>
//               <div style={{ width: '100%' }}>
//                 <InputLabel>Das bringen Sie mit:</InputLabel>
//                 <CKEditor
//                   editor={Editor}
//                   data={detailsProfile}
//                   onChange={(e, editor) => {
//                     const text = editor.getData();
//                     setDetailsProfile(text);
//                     updateHeight();
//                   }}
//                 />
//               </div>
//             </Grid>
//             <Grid xs={12}>
//               <StyledDropzone
//                 onChange={handleFileChange}
//                 onDelete={handleFileDelete}
//                 maxFiles={10}
//                 showAlerts={false}
//                 inputContent={
//                   <>
//                     <IconButton>
//                       <PhotoCamera />
//                     </IconButton>
//                   </>
//                 }
//                 dropzoneText={
//                   <span
//                     style={{
//                       width: '100%',
//                       display: 'flex',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                       fontSize: '18px',
//                     }}
//                   >
//                     <span style={{ maxWidth: '90%' }}>
//                       Ziehen Sie Ihre Dateien hierher oder klicken Sie, um
//                       Dateien hochzuladen
//                     </span>
//                   </span>
//                 }
//               />
//             </Grid>
//             <Grid xs={12}>
//               <Button
//                 type="submit"
//                 disabled={
//                   title === '' ||
//                   detailsTasks === '' ||
//                   detailsOffer === '' ||
//                   detailsProfile === ''
//                     ? true
//                     : false
//                 }
//               >
//                 Hochladen
//               </Button>
//             </Grid>
//           </Grid>
//         </form>
//       )}
//     </StyledBox>
//   );
// }

// function JobOfferLine(props) {
//   const { setPopupMessage } = useContext(PopupContext);
//   const j = props.job;

//   return (
//     <Fragment>
//       <ListItemAvatar>
//         <Avatar />
//       </ListItemAvatar>
//       <ListItemText primary={j.title} />
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <HighlightOffIcon
//           onClick={() =>
//             setPopupMessage(
//               'Stellenzeige: "' + j.title + '" entfernt.',
//               'success'
//             )
//           }
//           className={`${classes.textIcon} ${classes.cancelIcon}`}
//         />
//       </div>
//     </Fragment>
//   );
// }

function JobWidget(props) {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error, deleteJob } = useDeleteContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);

  // delcare data vars
  const j = props.data;

  const modalBody = (
    <Animation dir="left">
      <div style={{ marginBottom: '20px' }}>
        {j.detailsTasks && (
          <Fragment>
            <h1 className="subheading">Das sind Ihre Aufgaben:</h1>
            <span>{parse(j.detailsTasks)}</span>
          </Fragment>
        )}
        {j.detailsOffer && (
          <Fragment>
            <h1 className="subheading">Das erwartet Sie:</h1>
            <span>{parse(j.detailsOffer)}</span>
          </Fragment>
        )}
        {j.detailsProfile && (
          <Fragment>
            <h1 className="subheading">Das bringen Sie mit:</h1>
            <span>{parse(j.detailsProfile)}</span>
          </Fragment>
        )}
      </div>
      <Grid xs={12} container columnSpacing={2}>
        <Grid xs="auto" sx={{ paddingBottom: '10px' }}>
          <Button
            icon={faEarthAmerica}
            onClick={() =>
              history.push(`/${brandObject.url}/online-bewerbung/`, {
                scroll: true,
                data: {
                  job: j.title,
                },
              })
            }
          >
            Online-Bewerbung
          </Button>
        </Grid>
        {j.pdfPath && (
          <Grid xs="auto">
            <Button
              icon={faPrint}
              onClick={() => {
                const pdfUrl = getApiUrl(j.pdfPath);
                const newTab = window.open(pdfUrl, '_blank');
                newTab.focus();
              }}
            >
              Herunterladen
            </Button>
          </Grid>
        )}
      </Grid>
    </Animation>
  );

  function showModalHandler() {
    showModal(j.title, null, modalBody);
  }

  function deleteJobHandler() {
    showModal(
      j.title,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            deleteJob(j._id, (d) => {
              if (!error) {
                setPopupMessage(
                  'Stellenzeige: "' + j.title + '" entfernt.',
                  'success'
                );
                props.patchJobs(d, 'delete');
                closeModal();
              } else {
                setPopupMessage(
                  'Fehler beim Löschen der Stellenanzeige!',
                  'error'
                );
              }
            });
          }}
          color="red"
        >
          LÖSCHEN
        </Button>
      </div>
    );
  }

  function editJob() {
    showModal(
      'Bearbeiten: ' + parse(j.title),
      null,
      <JobWidgetForm job={j} patchJobs={props.patchJobs} edit />
    );
  }
  return (
    <Animation whileTap>
      {authCtx.isAdmin && (
        <div className={classes.iconWrapper}>
          <div className={classes.editIconCrop}>
            <FontAwesomeIcon
              icon={faPenSquare}
              className={classes.editIcon}
              onClick={editJob}
            />
          </div>
          <FontAwesomeIcon
            icon={faCircleMinus}
            className={classes.deleteIcon}
            onClick={deleteJobHandler}
          />
        </div>
      )}
      <div className={classes.container}>
        <div onClick={showModalHandler}>
          {j.thumbnailImage && (
            <img
              src={j.thumbnailImage ? getApiUrl(j.thumbnailImage.src) : null}
              alt={'Bild für den Job als ' + j.title}
              loading="lazy"
            />
          )}
          <h2 className={classes.jobTitle}>{j.title}</h2>
        </div>
      </div>
    </Animation>
  );
}
function ManageJobWidget(props) {
  const { showModal } = useContext(ModalContext);
  const emptyJob = {
    title: '',
    subtitle: '',
    detailsTasks: '',
    detailsOffer: '',
    detailsProfile: '',
    edit: false,
  };
  function showAddModalHandler() {
    showModal(
      'Neue Stellenanzeige',
      null,
      <JobWidgetForm patchJobs={props.patchJobs} job={emptyJob} />
    );
  }
  // function showManageModalHandler() {
  //   showModal(
  //     'Stellenanzeigen sortieren',
  //     null,
  //     <JobWidgetSortForm jobs={props.jobs} sortJobs={props.sortJobs} />
  //   );
  // }
  return (
    <div className={classes.manageContainer}>
      <div onClick={showAddModalHandler}>
        <FontAwesomeIcon icon={faPlusCircle} className={classes.addIcon} />
        <br />
        <span className={classes.manageText}>Hinzufügen</span>
      </div>
      {/* <div onClick={showManageModalHandler}>
        <FontAwesomeIcon icon={faList} className={classes.manageIcon} />
        <br />
        <span className={classes.manageText}>Verwalten</span>
      </div> */}
    </div>
  );
}

const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const authCtx = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { error, isLoading, getAllJobs } = useFetchContent();
  const { setPopupMessage } = useContext(PopupContext);

  useEffect(() => {
    getAllJobs((d) => {
      if (!error) {
        setJobs(d);
        if (!jobsLoaded) setJobsLoaded(true);
      }
    });
  }, []);

  function patchJobs(j, action) {
    if (action === 'add') {
      setJobs((arr) => [...arr, j]);
      closeModal();
      setPopupMessage(
        'Stellenanzeige: "' + j.title + '" hinzugefügt.',
        'success'
      );
    } else if (action === 'edit') {
      setJobs((arr) => {
        const index = arr.findIndex((item) => item._id === j._id);
        return index === -1
          ? arr
          : [...arr.slice(0, index), j, ...arr.slice(index + 1)];
      });
      closeModal();
      setPopupMessage('Job erfolgreich bearbeitet!', 'success');
    } else if (action === 'delete') {
      setJobs((arr) => {
        const index = arr.findIndex((item) => item._id === j._id);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        return arr;
      });
      closeModal();
    }
  }
  if (!jobsLoaded || isLoading || error) {
    return (
      <div
        // className={classes.wrapper}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'none',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflow: 'hidden',
          width: '100%',
          maxWidth: 'var(--max-content-width)',
          margin: 0,
          padding: 0,
        }}
      >
        {Array.from({ length: 4 }).map((v, i) => {
          return (
            <div
              // className={classes.container}
              style={{
                margin: '0 20px',
                width: '240px',
                maxWidth: '240px',
                height: '300px',
                backgroundColor: 'var(--light-contrast)',
                borderRadius: '8px',
                padding: '5px',
                flexShrink: 0,
              }}
              key={i}
            >
              <Skeleton variant="rounded" height="180px" width="100%" />
              <br />
              <Skeleton variant="rounded" width="100%" height="60px" />
            </div>
          );
        })}
      </div>
    );
  } else
    return (
      <div className={classes.wrapper}>
        <Swiper
          slidesPerView={'auto'}
          // grabCursor={true}
          className={`${classes.swiper} noSelect`}
          modules={authCtx.isAdmin ? [Mousewheel, Scrollbar] : [Mousewheel]}
          mousewheel={mousewheelOptions}
          centerInsufficientSlides={true}
          scrollbar={authCtx.isAdmin ? { draggable: true } : false}
          style={{ paddingBottom: authCtx.isAdmin && '30px' }}
        >
          {authCtx.isAdmin && (
            <SwiperSlide className={classes.swiperSlide}>
              <ManageJobWidget
                jobs={jobs}
                patchJobs={patchJobs}
                sortJobs={setJobs}
              />
            </SwiperSlide>
          )}
          {jobs.map((item, i) => {
            return (
              <SwiperSlide className={classes.swiperSlide} key={i}>
                <JobWidget data={item} patchJobs={patchJobs} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
}

export default Jobs;
