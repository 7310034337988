import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import AuthContext from '../store/auth-context';
import useHttp from './use-http';

export const useThisUser = () => {
  const authCtx = useContext(AuthContext);
  const { sendRequest } = useHttp();

  const query = useQuery({
    queryKey: ['thisUser', authCtx.userId],
    queryFn: () =>
      new Promise((resolve, reject) => {
        sendRequest(
          {
            url: '/api/v1/user/' + authCtx.userId,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            if (data) {
              resolve(data);
            } else {
              reject(new Error('Fehler beim Laden des Benutzers'));
            }
          }
        );
      }),
    initialData: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      department: '',
      position: '',
    },
    enabled: !!authCtx.token && !!authCtx.userId,
  });

  return query;
};

export const useUsers = (setUsers) => {
  const authCtx = useContext(AuthContext);
  const { sendRequest } = useHttp();

  const query = useQuery({
    queryKey: ['users'],
    queryFn: () =>
      new Promise((resolve, reject) => {
        sendRequest(
          {
            url: '/api/v1/user/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            if (data) {
              resolve(data);
            } else {
              reject(new Error('Fehler beim Laden der Benutzer'));
            }
          }
        );
      }),
    enabled: !!authCtx.token,
  });

  useEffect(() => {
    if (query.data) {
      setUsers(query.data);
    }
  }, [query.data]);

  return query;
};
