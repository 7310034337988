import { useState, useContext } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';

// IMPORT HOOKS, COMPONENTS AND OTHER FUNCTIONS
import AuthContext from '../../store/auth-context';
import ModalContext from '../../store/modal-context';
import Animation from '../UI/Animation';
import { useRegister } from '../../hooks/auth';

// IMPORT DATA
import { globals } from '../../data/global-vars';

// IMPORT IMAGES
import logo from '../../assets/company_sources/logo_white.png';

// IMPORT MUI COMPONENTS
import { TextField } from '@mui/material';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import PageLoaderIcon from '../UI/PageLoaderIcon';

const getQueryParams = (t) => {
  if (!t || 'string' != typeof t || t.length < 2) return new Map();
  const r = t
    .substr(1)
    .split('&')
    .map((t) => {
      const r = t.split('=');
      return [r[0], r[1]];
    });
  return new Map(r);
};

function Register() {
  const authCtx = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const token = getQueryParams(useLocation().search).get('token');

  useEffect(() => {
    document.documentElement.style.setProperty('--ccm-bottom', '32px');
  }, []);

  const registerMutation = useRegister((data) => {
    authCtx.login(data.token, data);
  });

  function registerHandler(event) {
    event.preventDefault();
    if (password !== confirmPassword) {
      showModal(
        'Fehler',
        null,
        <p className="mb-4">Die Passwörter stimmen nicht überein.</p>
      );
      return;
    }
    registerMutation.mutate({
      email,
      password,
      token,
    });
  }

  function translateError() {
    let errorMessage = null;
    if (
      registerMutation.error?.message ===
      'Email does not match the registered email.'
    )
      errorMessage =
        'Die eingegebene E-Mail-Adresse stimmt nicht mit der registrierten überein.';
    if (registerMutation.error?.message === 'Invalid or expired token.')
      errorMessage = 'Ungültiger oder abgelaufener Link.';
    return errorMessage;
  }

  if (!authCtx.isLoggedIn) {
    return (
      <div className={`w-screen h-screen flex justify-center items-center`}>
        {registerMutation.isLoading && (
          <div
            style={{
              width: '100%',
              backgroundColor: 'white',
              height: '100%',
              position: 'absolute',
              opacity: 0.9,
              zIndex: 100,
            }}
          >
            <PageLoaderIcon isLoading={registerMutation.isLoading} />
          </div>
        )}
        <form
          onSubmit={registerHandler}
          className="w-[95%] max-w-[600px] grid grid-cols-12 text-left gap-4"
          style={{ width: '100%', position: 'relative' }}
        >
          <Helmet>
            <meta
              name="theme-color"
              content={globals.BRANDS.VW.primeColorHex}
            />
          </Helmet>
          {/* LOGO */}
          <div className="col-span-12 bg-[var(--vw-color)] rounded-lg shadow-lg w-full py-3 flex justify-center items-center mb-4">
            <img
              src={logo}
              alt="Logo Autohaus Rainer Seyfarth"
              className="w-[350px] px-5"
              loading="lazy"
              onClick={() => {
                window.location.href = '/';
              }}
            />
          </div>

          <TextField
            className="col-span-12"
            label="E-Mail-Adresse"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            autoFocus
          />

          <TextField
            className="col-span-12"
            label="Passwort"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
          />
          <TextField
            className="col-span-12"
            label="Passwor wiederholen"
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            required
          />

          <span className="col-span-12 text-sm -mt-2 text-danger">
            {translateError()}
          </span>

          <div className="col-span-12 flex justify-between">
            <Link
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                icon={faArrowLeft}
                maxWidth="200px"
                iconPos="pre"
                type="button"
              >
                Startseite
              </Button>
            </Link>

            <Button
              icon={faArrowRight}
              iconPos="post"
              type="submit"
              color="green"
              onClick={registerHandler}
              disabled={
                password === '' || email === '' || password !== confirmPassword
              }
              maxWidth="200px"
            >
              Einloggen
            </Button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      // redirect if already logged in
      <Redirect
        to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
      ></Redirect>
    );
  }
}

export default Register;
